import React from 'react';
import { translate } from '../../../../services/translate';
import UiButton from '../../../ui/button/UiButton';
import UiBox from '../../../ui/box/UiBox';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import UiHeading from '../../../ui/heading/UiHeading';
import UiSubHeading from '../../../ui/sub-heading/UiSubHeading';
import Wrapper from './styles';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function AccountVerificationReopenedPrompt({ onClose, isOpen }: Props) {
    return (
        <Wrapper open={isOpen} maxWidth={400}>
            <UiBox>
                <UiCloseButton onClick={onClose} className="close-button" />
                <UiHeading title={translate('Account info updated', 'ui.kyc')} />
                <UiSubHeading>
                    {translate('Your ID was successfully verified and your account has been reopened.', 'ui.kyc')}
                </UiSubHeading>
                <UiButton className="login-button" color="primary" onClick={onClose}>
                    {translate('Login to continue', 'ui.common')}
                </UiButton>
            </UiBox>
        </Wrapper>
    );
}
