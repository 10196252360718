import React, { createContext, useContext, useEffect, useState } from 'react';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';

interface UiFormContext {
    validate: number;
    isFormValid: boolean;
    isFormInteractedWith: boolean;
    isFormLoading: boolean;
    inputValueByName: Record<string, string>;
    messageByName: Record<string, { info?: string; warning?: string; error?: string; success?: string }>;
    inputErrorStateByName: Record<string, string>;
    inputIsValidatingByName: Record<string, string>;
    inputsInteractedWithByName: Record<string, string>;
    triggerValidation: () => void;
    announceInput: (name: string, initialValue: string) => void;
    deRegisterInput: (name: string) => void;
    announceInputIsValidatingByName: (name: string, initialValue: boolean) => void;
    announceInputErrorStateByName: (name: string, hasError: boolean) => void;
    announceInputValueByName: (name: string, value: string) => void;
    announceInputInteractedWithByName: (name: string) => void;
    setIsFormLoading: (isLoading: boolean) => void;
    setFormMessagesByNames: (messagesByNames: Record<string, string>) => void;
}

const UiFormContext = createContext({} as UiFormContext);

export function UiFormContextProvider({ children }) {
    const [validate, setValidate] = useState(0);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormInteractedWith, setIsFormInteractedWith] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [inputErrorStateByName, setInputErrorStateByName] = useState({});
    const [inputIsValidatingByName, setInputIsValidatingByName] = useState({});
    const [inputsInteractedWithByName, setInputInteractedWithByName] = useState({});
    const [inputValueByName, setInputValueByName] = useState({});
    const [messageByName, setMessageByName] = useState<Record<string, any>>({});

    useEffect(() => {
        const totalInputsAmount = Object.keys(inputValueByName).length;

        if (totalInputsAmount !== 0) {
            const erroredFormValidatorsAmount = Object.values(messageByName)
                .map((message) => message?.error)
                .filter(Boolean).length;

            const erroneousInputsAmount = Object.values(inputErrorStateByName).filter(Boolean).length;
            const currentlyValidatingInputsAmount = Object.values(inputIsValidatingByName).filter(Boolean).length;
            const interactedWithInputsAmount = Object.values(inputsInteractedWithByName).filter(Boolean).length;

            const allFormValidatorsHavePassed = erroredFormValidatorsAmount === 0;
            const allInputsAreValid = erroneousInputsAmount === 0;
            const allInputsAreValidated = currentlyValidatingInputsAmount === 0;
            const allInputsHaveBeenInteractedWith = interactedWithInputsAmount === totalInputsAmount;

            setIsFormValid(
                allFormValidatorsHavePassed &&
                    allInputsAreValid &&
                    allInputsAreValidated &&
                    allInputsHaveBeenInteractedWith,
            );
        }
    }, [
        validate,
        isFormValid,
        isFormLoading,
        inputErrorStateByName,
        inputIsValidatingByName,
        inputsInteractedWithByName,
        inputValueByName,
        messageByName,
    ]);

    const providedContext = {
        validate,
        isFormValid,
        isFormLoading,
        isFormInteractedWith,
        inputErrorStateByName,
        inputIsValidatingByName,
        inputsInteractedWithByName,
        inputValueByName,
        messageByName,
        triggerValidation: () => setValidate((validate) => validate + 1),
        announceInput: (name, initialValue) => {
            setInputValueByName((inputValueByName) => ({ ...inputValueByName, [name]: initialValue }));
            setInputErrorStateByName((inputErrorStateByName) => ({ ...inputErrorStateByName, [name]: false }));
            setInputInteractedWithByName((inputInteractedWithByName) => ({
                ...inputInteractedWithByName,
                [name]: false,
            }));
        },
        deRegisterInput: (name) => {
            setInputValueByName((inputValueByName) => omit(inputValueByName, name));
            setInputErrorStateByName((inputErrorStateByName) => omit(inputErrorStateByName, name));
            setInputInteractedWithByName((inputInteractedWithByName) => omit(inputInteractedWithByName, name));
        },
        announceInputIsValidatingByName: (name, isValidating) => {
            setInputIsValidatingByName((inputIsValidatingByName) => ({
                ...inputIsValidatingByName,
                [name]: isValidating,
            }));
        },
        announceInputErrorStateByName: (name, hasError) => {
            setInputErrorStateByName((inputErrorStateByName) => ({ ...inputErrorStateByName, [name]: hasError }));
        },
        announceInputValueByName: (name, value) => {
            setInputValueByName((inputValueByName) => ({ ...inputValueByName, [name]: value }));
        },
        announceInputInteractedWithByName: (name) => {
            setIsFormInteractedWith(true);
            setInputInteractedWithByName((inputInteractedWithByName) => ({
                ...inputInteractedWithByName,
                [name]: true,
            }));
        },
        setIsFormLoading: (isLoading) => {
            setIsFormLoading(isLoading);
        },
        setFormMessagesByNames: (messagesByNames) => {
            setMessageByName(messagesByNames);
        },
    };

    return <UiFormContext.Provider value={providedContext}>{children}</UiFormContext.Provider>;
}

export function UiFormContextConsumer({ children }) {
    return (
        <UiFormContext.Consumer>
            {(context) => (isFunction(children) && children(context)) || children}
        </UiFormContext.Consumer>
    );
}

export const useUiFormContext = () => useContext(UiFormContext);
