import styled from 'styled-components';
import UiModal from '../../../ui/modal/UiModal';

export default styled(UiModal)`
    position: relative;
    min-width: 320px;

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
    }
`;
