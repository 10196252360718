import React, { useState } from 'react';
import Wrapper from '../../verification/styles';
import { resendTwoFactorSmsPin } from '../../../../microservices/sms';
import { getRoute, useRouter } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { stores } from '../../../../stores';
import { finishLoginWithToken, getErrorMessage } from '../../../../services/two-factor';
import { sendTwoFactorEmailPin } from '../../../../microservices/auth';
import { verifyTwoFactorPin } from '../../../../microservices/auth';
import { useValidator } from '../../../../services/form';
import { validators } from '../../../../services/validators';
import UiButton from '../../../ui/button/UiButton';
import { isDefaultEmailRegistrationCountry } from '../../../../services/auth';
import UiCountdown from '../../../ui/countdown/UiCountdown';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    onSuccess?: () => void;
}

export default function AuthTwoFactorSms({ onSuccess }: Props) {
    const { navigateTo } = useRouter();
    if (!onSuccess) {
        onSuccess = () => {
            navigateTo(getRoute('sport.recommendations'));
        };
    }

    const [receivedSmsPin, setReceivedSmsPin] = useState('');
    const [error, setError] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [isSmsSent, setIsSmsSent] = useState(true);
    const { validate, runValidation } = useValidator();
    const [isSendCodeToEmailButtonVisible, setIsSendCodeToEmailButtonVisible] = useState(false);

    const [smsPinId] = useStore(stores.twoFactor.smsPinId);
    const [reason] = useStore(stores.twoFactor.reason);
    const [safePhone] = useStore(stores.twoFactor.safePhone);
    const [email] = useStore(stores.twoFactor.email);
    const [country] = useStore(stores.twoFactor.country);
    const [timeoutEndTime, setTimeoutEndTime] = useState<number>();
    const [isSuccess, setIsSuccess] = useState(true);

    async function tryVerifyTwoFactorSmsPin() {
        const isFormValid = await runValidation([validators.smsPin(receivedSmsPin)]);
        setError('');
        setIsSuccess(false);

        if (!isFormValid) {
            return;
        }

        setIsVerifying(true);

        try {
            const { token } = await verifyTwoFactorPin({ pin: receivedSmsPin, pinId: smsPinId });
            finishLoginWithToken(token);
            setIsVerifying(false);
            stores.twoFactor.isTwoFactorSmsActive.set(false);
            stores.modals.isLoginModalOpen.set(false);
            onSuccess && onSuccess();
        } catch (error: any) {
            error.message = error.code;
            setError(getErrorMessage(error));
            setIsVerifying(false);
        }
    }

    async function tryResendTwoFactorSmsPin() {
        setIsResending(true);
        setError('');
        setIsSuccess(false);

        try {
            await resendTwoFactorSmsPin({
                smsTwoFactorPinId: smsPinId,
                isTwoFactorAuthentication: true,
            });

            setIsSmsSent(true);
            setIsSuccess(true);
        } catch (errorResponse: any) {
            const { enableEmailFallback, error } = errorResponse;
            if (enableEmailFallback) {
                stores.twoFactor.smsResendFailedReason.set(error);
                openTwoFactorEmailFallbackModal();
                return;
            }

            setIsSmsSent(false);
            setError(error || 'Unknown error');
        }
        addTimeoutForSubmit();
        isDefaultEmailRegistrationCountry(country)
            ? setIsSendCodeToEmailButtonVisible(false)
            : setIsSendCodeToEmailButtonVisible(true);
        setReceivedSmsPin('');
        setIsResending(false);
    }

    async function startEmailVerification() {
        setIsResending(true);
        setError('');
        setIsSuccess(false);
        try {
            await sendTwoFactorEmailPin(email);
            openTwoFactorEmailFallbackModal();
            setIsResending(false);
            setIsSuccess(true);
        } catch (error: any) {
            error.message = error.code;
            setError(getErrorMessage(error));
            setIsResending(false);
        }
        addTimeoutForSubmit();
    }

    function openTwoFactorEmailFallbackModal() {
        stores.twoFactor.isTwoFactorSmsActive.set(false);
        stores.twoFactor.isTwoFactorEmailActive.set(true);
    }

    function addTimeoutForSubmit() {
        const resendTimeoutInSeconds = 30;
        setTimeoutEndTime(new Date().setSeconds(new Date().getSeconds() + resendTimeoutInSeconds));
        setTimeout(() => {
            setTimeoutEndTime(undefined);
        }, resendTimeoutInSeconds * 1000);
    }

    return (
        <Wrapper>
            <div>
                {isSmsSent && isSuccess && (
                    <Wrapper>
                        <UiAlert success>
                            <strong>{translate('Verification SMS sent!', 'ui.registration')}</strong>{' '}
                            {reason && translate(reason, 'ui.registration')}
                        </UiAlert>
                        <UiAlert info>
                            {translate('Check your phone. We have sent SMS to a number ending with', 'ui.gdpr')}{' '}
                            <em>{safePhone}</em>
                        </UiAlert>
                    </Wrapper>
                )}

                {error && <UiAlert failure>{translate(error, 'ui.common')}</UiAlert>}

                <UiFormInput
                    name="pin"
                    value={receivedSmsPin}
                    onValueChange={setReceivedSmsPin}
                    type="tel"
                    validate={validate}
                    validator={validators.smsPin}
                    placeholder={translate('Enter PIN here', 'ui.gdpr')}
                    maxLength={6}
                />

                <UiButton color="primary" block onClick={tryVerifyTwoFactorSmsPin} isLoading={isVerifying}>
                    {translate('Verify & Login', 'ui.registration')}
                </UiButton>

                {isSmsSent && (
                    <UiButton
                        block
                        onClick={tryResendTwoFactorSmsPin}
                        isLoading={isResending}
                        disabled={Boolean(timeoutEndTime)}
                    >
                        {translate('Resend code', 'ui.gdpr')} {}
                        {timeoutEndTime && (
                            <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                                {({ seconds }) => <>({seconds})</>}
                            </UiCountdown>
                        )}
                    </UiButton>
                )}

                {isSendCodeToEmailButtonVisible && (
                    <UiButton
                        block
                        onClick={startEmailVerification}
                        isLoading={isResending}
                        disabled={Boolean(timeoutEndTime)}
                    >
                        {translate('Send code to Email', 'ui.common')} {}
                        {timeoutEndTime && (
                            <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                                {({ seconds }) => <>({seconds})</>}
                            </UiCountdown>
                        )}
                    </UiButton>
                )}
            </div>
        </Wrapper>
    );
}
