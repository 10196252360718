export interface CasinoGameLabel {
    id: number;
    name: string;
    created: string;
    updated: string;
}

export enum GameLabels {
    HOT = 1,
    NEW = 2,
    EXCLUSIVE = 3,
}
