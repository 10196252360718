import React from 'react';

export const withOpenLink = (Component, url) => (props) => {
    const { onClick = () => {}, name } = props;

    function updatedOnClick(event) {
        onClick(event);
        window.location = url;
    }

    const isLink = name === 'a';

    // TODO: Priit. Make open-casino-*, open-sports-* attributes working and filter out all open-* attributes
    const isOverridden = Boolean(
        Object.keys(props).filter((selector) => selector.startsWith('open-page-') || selector.startsWith('open-bonus-'))
            .length,
    );

    const updatedProps = isOverridden
        ? props
        : {
              ...props,
              onClick: !isLink ? updatedOnClick : onClick,
              href: isLink ? url : undefined,
          };

    return <Component {...updatedProps}>{props.children}</Component>;
};
