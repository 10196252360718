import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import Wrapper from './styles';
import { getActiveCurrency } from '../../../../services/currency';
import { isBetSlipLoadingMarkets } from '../../../../services/sports/betslip';
import {
    getBetslipAmount,
    isCampaignEligibleForBetSlip,
    toggleCampaignSelected,
} from '../../../../services/sports/campaigns';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import Svg from '../../../svg/Svg';
import UiButton from '../../../ui/button/UiButton';
import { useStore } from '../../../../hooks/useStore';

export default function SportCampaignsBetslipCheckboxes() {
    const [campaigns, setCampaigns] = useStore(stores.sports.campaigns);
    const [bonusBetSelection] = useStore(stores.sports.bonusBetsSelection);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const currency = getActiveCurrency();
    useEffect(() => {
        if (some(bonusBetSelection)) {
            setCampaigns((state) => ({ ...state, selected: null }));
        }
    }, [bonusBetSelection]);
    useEffect(() => {
        if (!campaigns || !campaigns.selected) {
            return;
        }

        if (!campaigns?.selected?.bet_amounts[currency]) {
            return;
        }

        toggleCampaignSelected(campaigns.selected, true);
    }, [campaigns.selected]);

    useEffect(() => {
        if (!campaigns.selected || isBetSlipLoadingMarkets() || isEmpty(betSlipMarketIdToOutcomeId)) {
            return;
        }
        if (!isCampaignEligibleForBetSlip(campaigns.selected)) {
            setCampaigns((state) => ({ ...state, selected: null }));
        }
    }, [betSlipMarketIdToOutcomeId, marketInfoById, campaigns.selected]);

    if (!campaigns.list) {
        return null;
    }

    function getLabelTranslation(campaign) {
        if (campaign.is_first_day) {
            const betAmounts = campaign.bet_amounts[currency];
            return translate(
                'Place {{ campaignName }} bet? {{ min }} - {{ max }} {{ currency }}',
                `ui.sports.campaign`,
                {
                    min: betAmounts.min,
                    max: betAmounts.max,
                    currency,
                    campaignName: translate(`${campaign.id}`, 'ui.campaign.name'),
                },
            );
        }

        const betAmount = getBetslipAmount(campaign);
        return translate('Place {{ campaignName }} bet? {{ betAmount }} {{ currency }}', `ui.sports.campaign`, {
            betAmount,
            currency,
            campaignName: translate(`${campaign.id}`, 'ui.campaign.name'),
        });
    }

    return (
        <>
            {campaigns.list.filter(isCampaignEligibleForBetSlip).map((campaign) => (
                <Wrapper key={campaign.id}>
                    {(!campaigns.selected || campaigns.selected.id !== campaign.id) && (
                        <div className="campaign-bet">
                            <div className="campaign-icon">
                                <Svg icon="promotions-ico" alt="promotions-ico" className="promotions-icon" />
                            </div>
                            <div className="campaing-description">{getLabelTranslation(campaign)}</div>
                            <UiButton color="primary" size="small" onClick={() => toggleCampaignSelected(campaign)}>
                                {translate('Apply?', 'ui.bonus')}
                            </UiButton>
                        </div>
                    )}

                    {campaigns.selected && campaigns.selected.id === campaign.id && (
                        <div className="campaign-bet selected">
                            <div className="campaign-icon">
                                <Svg icon="promotions-ico" alt="promotions-ico" className="promotions-icon" />
                            </div>
                            <div className="campaing-description">
                                {translate(`${campaign.id}`, 'ui.campaign.name')}
                                <br />
                                {translate('ui.bonus.is-applied')}
                            </div>
                            <UiButton size="small" onClick={() => toggleCampaignSelected(campaign)}>
                                {translate('Cancel', 'ui.common')}
                            </UiButton>
                        </div>
                    )}
                </Wrapper>
            ))}
        </>
    );
}
