import React, { useState } from 'react';
import Wrapper from './styles';
import moment from 'moment';
import { TICKET_TYPE } from '../../../services/bet-history';
import VirtualSportsTicketDetails from './details/VirtualSportsTicketDetails';
import { formattedAmountWithCurrency } from '../../../services/currency';
import classNames from 'classnames';
import { convertOdds } from '../../../services/odds-format';
import { translate } from '../../../services/translate';
import SportBadge from '../../sport/badge/SportBadge';
import SportBadgeTicketStatus from '../../sport/badge/ticket-status/SportBadgeTicketStatus';
import SportBadgeTicketPlatform from '../../sport/badge/ticket-platform/SportBadgeTicketPlatform';
import { Ticket } from '../../../services/sports/types';
import { filterStyleProps } from '../../../styles/utils';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    ticket: Ticket;
    isCompactView?: boolean;
}

export default function VirtualSportsTicket({ ticket, isCompactView }: Props) {
    const [isTicketDetailsOpen, setIsTicketDetailsOpen] = useState(false);
    const [{ isPhone }] = useStore(media);
    const isCompactViewEnabled = isCompactView || isPhone;

    const sportName = ticket.sportName === 'Soccerbet' ? translate('soccerbet', 'ui.vs') : ticket.sportName;

    if (!ticket) {
        return null;
    }

    return (
        <Wrapper
            {...filterStyleProps({ ticket, isCompactView })}
            $isCompactViewEnabled={isCompactViewEnabled}
            $isTicketDetailsOpen={isTicketDetailsOpen}
        >
            {!isTicketDetailsOpen && (
                <div className="sport-ticket">
                    {!isCompactView && (
                        <div className="bet-sport" onClick={() => setIsTicketDetailsOpen(true)}>
                            <div className="league">
                                <div className="sport-name">{sportName}</div>
                            </div>
                        </div>
                    )}

                    <div className="bet-match" onClick={() => setIsTicketDetailsOpen(true)}>
                        <div className="match-name">
                            <span className="match-title">{ticket.matchName}</span>

                            {!isPhone && !isCompactView && (
                                <div className="ticket-type">
                                    <SportBadge type={ticket.ticket_type.toLowerCase()} />

                                    {ticket.ticket_type === TICKET_TYPE.COMBO && (
                                        <span className="combo">&nbsp;&nbsp;[+{ticket.total_matches - 1}]</span>
                                    )}
                                </div>
                            )}
                            {ticket.num_bets_total > 1 && (
                                <div className="bet-stats">
                                    (&nbsp;{ticket.num_bets_won}&nbsp;/&nbsp;{ticket.num_bets_lost}&nbsp;/&nbsp;
                                    {ticket.num_bets_total}&nbsp;)
                                </div>
                            )}
                        </div>
                        <div className="bet-market">
                            {ticket.marketTypeName || ticket.marketName}
                            <span className="bet-outcome">{ticket.outcomeName}</span>
                        </div>
                    </div>

                    <div className="bet-totals" onClick={() => setIsTicketDetailsOpen(true)}>
                        <div className="total-odds">{convertOdds(ticket.first_bet_odds)}</div>
                        <div className="total-stake">{formattedAmountWithCurrency(ticket.total_stake)}</div>
                        <>&nbsp;&rsaquo;&nbsp;</>

                        <div className={classNames('possible-return', ticket.status)}>
                            {formattedAmountWithCurrency(
                                (+ticket.potential_return || +ticket.total_max_win) +
                                    (+ticket.bonusAmount ? +ticket.bonusAmount : 0),
                            )}
                        </div>
                    </div>

                    {(isCompactView || isPhone) && (
                        <div className="ticket-type" onClick={() => setIsTicketDetailsOpen(true)}>
                            <SportBadge type={ticket.ticket_type.toLowerCase()} />
                            {ticket.ticket_type === TICKET_TYPE.COMBO && (
                                <span className="combo">&nbsp;&nbsp;[+{ticket.total_matches - 1}]</span>
                            )}
                        </div>
                    )}

                    <div className="bet-info" onClick={() => setIsTicketDetailsOpen(true)}>
                        <SportBadgeTicketPlatform ticket={ticket} className="ticket-status" />
                        <SportBadgeTicketStatus ticket={ticket} className="ticket-status" />

                        {!isCompactView && (
                            <div className="bet-dates">
                                <span>{moment(ticket.created_at).format('D MMM YYYY HH:mm')}</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {isTicketDetailsOpen && (
                <VirtualSportsTicketDetails
                    ticket={ticket}
                    onTicketClose={() => setIsTicketDetailsOpen(false)}
                    isCompactView={isCompactView}
                />
            )}
        </Wrapper>
    );
}
