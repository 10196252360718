import { stores } from '../stores';

export function useInputInteractions(
    hideMobileTabOnFocus = false,
    onBlur: (event: Event) => void,
    onFocus: (event: Event) => void,
) {
    return {
        onBlurEvent: (event: Event) => {
            onBlur(event);
            if (hideMobileTabOnFocus) {
                stores.sports.isBottomNavigationVisible.set(true);
            }
        },
        onFocusEvent: (event: Event) => {
            onFocus(event);
            if (hideMobileTabOnFocus) {
                stores.sports.isBottomNavigationVisible.set(false);
            }
        },
    };
}
