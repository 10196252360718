import AccountVerificationProofOfIdSteps0 from './0/AccountVerificationProofOfIdSteps0';
import AccountVerificationProofOfIdSteps1 from './1/AccountVerificationProofOfIdSteps1';
import AccountVerificationProofOfIdSteps2 from './2/AccountVerificationProofOfIdSteps2';
import AccountVerificationProofOfIdSteps3 from './3/AccountVerificationProofOfIdSteps3';
import AccountVerificationProofOfIdSteps4 from './4/AccountVerificationProofOfIdSteps4';

export default [
    AccountVerificationProofOfIdSteps0,
    AccountVerificationProofOfIdSteps1,
    AccountVerificationProofOfIdSteps2,
    AccountVerificationProofOfIdSteps3,
    AccountVerificationProofOfIdSteps4,
];
