import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import moment from 'moment';
import React from 'react';
import { useEffect, useState } from 'react';
import { getBetradarTicketDetails } from '../../../../microservices/betradar-vs';
import { getHighlightGamesTicketDetails } from '../../../../microservices/highlight-games';
import { TICKET_STATUS, TICKET_TYPE } from '../../../../services/bet-history';
import { logger } from '../../../../services/logger';
import { convertOdds } from '../../../../services/odds-format';
import { useOddsFormat } from '../../../../services/sports/user-settings';
import { translate } from '../../../../services/translate';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { VIRTUAL_SPORTS_PROVIDERS } from '../../../../services/virtual-sports/constants';
import SportBadgeTicketPlatform from '../../../sport/badge/ticket-platform/SportBadgeTicketPlatform';
import SportBadgeTicketStatus from '../../../sport/badge/ticket-status/SportBadgeTicketStatus';
import UiAlert from '../../../ui/alert/UiAlert';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import VirtualSportsTicketSelections from '../selections/VirtualSportsTicketSelections';
import Wrapper from './styles';
import { DATE_TIME_FORMAT, getFormattedDate } from '../../../../services/date';
import { filterStyleProps } from '../../../../styles/utils';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    ticket: any;
    isCompactView?: boolean;
    onTicketClose?: () => void;
}

export default function VirtualSportsTicketDetails({ ticket, onTicketClose, isCompactView }: Props) {
    const [{ isPhone }] = useStore(media);
    const isCompactViewEnabled = isCompactView || isPhone;
    const [ticketDetails, setTicketDetails] = useState<any>();
    const [betSelections, setBetSelections] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    useOddsFormat();

    useEffect(() => {
        loadTicketDetails();
    }, []);

    async function loadTicketDetails() {
        setIsLoading(true);
        setIsError(false);
        try {
            let ticketData: any;
            const betSelections: any = [];
            if (ticket.provider === VIRTUAL_SPORTS_PROVIDERS.HIGHLIGHT_GAMES) {
                ticketData = await getHighlightGamesTicketDetails(ticket.id);
            }
            if (ticket.provider === VIRTUAL_SPORTS_PROVIDERS.BETRADAR) {
                ticketData = await getBetradarTicketDetails(ticket.id);
            }
            setTicketDetails(ticketData);

            if (ticketData.ticket_type.toLowerCase() === TICKET_TYPE.SYSTEM) {
                setBetSelections(values(ticketData.selectionsMeta));
            } else {
                ticketData.bets.forEach((bet) => {
                    bet.selections.map((selection) => betSelections.push(selection));
                });
                setBetSelections(uniqBy(betSelections, 'outcome_id'));
            }
        } catch (error) {
            logger.error('VirtualSportsTicketDetails', 'loadTicketDetails', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    if (!ticketDetails) {
        return null;
    }

    function getSportName(selection) {
        switch (selection.sport_name) {
            case 'Soccerbet':
                return translate('soccerbet', 'ui.vs');
            case 'International Ice Hockey Federation':
                return translate('Ice Hockey', 'ui.vs');
            default:
                return selection.sport_name;
        }
    }
    return (
        <Wrapper
            {...filterStyleProps({
                ticket,
                onTicketClosed: onTicketClose,
                isCompactView,
            })}
            $isCompactViewEnabled={isCompactViewEnabled}
        >
            {onTicketClose && <UiCloseButton onClick={onTicketClose} className="close-details" />}
            <div className="totals-label">
                <div className="ticket-id">#{ticketDetails.display_id}</div>
                <span>{translate('Total-odds', 'ui.betslip')} / </span>
                <span>{translate('Stake', 'ui.betslip')} / </span>
                <span>{translate('potential-return', 'ui.betslip')}</span>
            </div>
            {!isLoading && !isError && (
                <div className="ticket-totals">
                    <div className="bet-totals">
                        {ticketDetails.first_bet_odds && (
                            <div className="total-odds active">
                                {convertOdds(ticketDetails.first_bet_odds.toFixed(2))}
                            </div>
                        )}
                        <>&nbsp;</>
                        <div className="total-stake">{formattedAmountWithCurrency(ticketDetails.total_stake)}</div>
                        <>&nbsp;&rsaquo;&nbsp;</>
                        <div className={classNames('possible-return', ticketDetails.status)}>
                            {ticketDetails.total_max_win
                                ? formattedAmountWithCurrency(ticketDetails.total_max_win)
                                : ''}
                            {ticketDetails.bonusAmount
                                ? ` + ${formattedAmountWithCurrency(ticketDetails.bonusAmount)}`
                                : ''}
                        </div>
                    </div>
                </div>
            )}

            {isLoading && !isError && <UiDotsLoader />}

            {isError && <UiAlert failure>{translate('Could not load tickets', 'ui.account')}</UiAlert>}

            {betSelections.map((uniqueSelection) => (
                <div className="ticket-details" key={uniqueSelection.match_name}>
                    <div className="match-info">
                        <span>
                            {getFormattedDate({
                                date: `${ticketDetails.updated_at}`,
                                format: DATE_TIME_FORMAT,
                                useMonthLetters: true,
                            })}
                        </span>
                        <span>/</span>
                        <div className="league">
                            <span>{getSportName(uniqueSelection)}</span>
                        </div>
                        {!isCompactViewEnabled && (
                            <>
                                <span> / </span>
                                <div className="bet-event-name">{uniqueSelection.event_name}</div>
                            </>
                        )}
                    </div>
                    <div className="bet-match">
                        <div
                            className={classNames('match-name', {
                                'match-resulted': uniqueSelection.status !== TICKET_STATUS.PENDING,
                            })}
                        >
                            <div className="match-name-title">
                                <span>{uniqueSelection.match_name}</span>
                                {!isCompactViewEnabled && (
                                    <div className="bet-info">
                                        <SportBadgeTicketPlatform
                                            ticket={ticketDetails.cashout_status ? ticketDetails : uniqueSelection}
                                        />
                                        <SportBadgeTicketStatus
                                            ticket={ticketDetails.cashout_status ? ticketDetails : uniqueSelection}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="bet-market">
                            {uniqueSelection.marketTypeName || uniqueSelection.market_name}
                        </div>
                    </div>

                    {uniqueSelection.event_score && (
                        <div className="match-result">
                            <span className="score">{uniqueSelection.event_score.split('-')[0]}</span>-
                            <span className="score">{uniqueSelection.event_score.split('-')[1]}</span>
                        </div>
                    )}

                    <div className="odds-and-type">
                        <div className="total-odds">{convertOdds(uniqueSelection.odds)}</div>
                        <span>→</span>
                        <span className="bet-outcome">{uniqueSelection.outcome_name}</span>
                    </div>

                    {isCompactViewEnabled && (
                        <div className="bet-info">
                            <SportBadgeTicketPlatform
                                ticket={ticketDetails.cashout_status ? ticketDetails : uniqueSelection}
                            />
                            <SportBadgeTicketStatus
                                ticket={ticketDetails.cashout_status ? ticketDetails : uniqueSelection}
                            />
                            <div className="bet-dates">
                                {uniqueSelection.match_start
                                    ? moment(`${uniqueSelection.match_start}`).format('D MMM HH:mm')
                                    : uniqueSelection.event_name}
                            </div>
                        </div>
                    )}
                </div>
            ))}

            {ticketDetails.ticket_type &&
                ticketDetails.ticket_type.toLowerCase() === TICKET_TYPE.SYSTEM &&
                Object.entries(ticketDetails.systemsMeta).map(([numberOfFolds, systemMeta]) => (
                    <VirtualSportsTicketSelections
                        key={numberOfFolds}
                        numberOfFolds={numberOfFolds}
                        selectionsMeta={ticketDetails.selectionsMeta}
                        systemsMeta={systemMeta as any}
                        currency={ticketDetails.currency}
                    />
                ))}
        </Wrapper>
    );
}
