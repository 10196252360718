import React, { ComponentType, forwardRef, Ref } from 'react';
import AccountVerificationBox from '../../box/AccountVerificationBox';
import AccountVerificationMultiStepProgressIndicator from '../progress-indicator/AccountVerificationMultiStepProgressIndicator';
import Wrapper from './styles';

interface Props {
    activeStep: number;
    setActiveStep: (activeStep: number) => void;
    progressIndicatorSteps: Parameters<typeof AccountVerificationMultiStepProgressIndicator>[0]['steps'];
    Steps: ComponentType[];
    dataTest?: string;
    title?: string;
}

function AccountVerificationMultiStepBox(
    { activeStep, setActiveStep, progressIndicatorSteps, Steps, dataTest, ...boxProps }: Props,
    ref?: Ref<HTMLDivElement>,
) {
    const ActiveStep = Steps[activeStep];
    return (
        <Wrapper ref={ref}>
            {activeStep > 0 && (
                <AccountVerificationMultiStepProgressIndicator
                    steps={progressIndicatorSteps}
                    activeStep={activeStep - 1}
                    onStepClicked={(step) => {
                        if (activeStep >= Steps.length - 1) {
                            return;
                        }
                        setActiveStep(step + 1);
                    }}
                    className="progress-indicator animated fadeInUp"
                />
            )}
            <AccountVerificationBox dataTest={dataTest} {...boxProps}>
                <div className="animated fadeIn" key={activeStep}>
                    <ActiveStep />
                </div>
            </AccountVerificationBox>
        </Wrapper>
    );
}

export default forwardRef(AccountVerificationMultiStepBox);
