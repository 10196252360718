import React, { useState } from 'react';
import UiDotsLoader from '../ui/dots-loader/UiDotsLoader';
import { useTimeout } from '../../services/hooks';

export default function Loader(props) {
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);

    useTimeout(() => {
        setIsLoaderVisible(true);
    }, 200);

    if (!isLoaderVisible) {
        return null;
    }

    return <UiDotsLoader {...props} />;
}
