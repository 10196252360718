import styled, { css } from 'styled-components';

type StyledProps = {
    $shouldTruncate: boolean;
};
export default styled.div<StyledProps>`
    ${({ $shouldTruncate }) =>
        $shouldTruncate &&
        css`
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-bottom: 5px;
        `};
`;
