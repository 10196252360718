import React from 'react';
import { useProofOfIdVerificationContext } from '../../../../../../contexts/proof-of-id-verification/ProofOfIdVerificationContext';
import { translate } from '../../../../../../services/translate';
import UiFormInput from '../../../../../ui/form/input/UiFormInput';
import AccountVerificationDocumentPreview from '../../../document/preview/AccountVerificationDocumentPreview';
import AccountVerificationProofOfIdExpiryDate from '../../expiry-date/AccountVerificationProofOfIdExpiryDate';
import AccountVerificationProofOfIdFooter from '../../footer/AccountVerificationProofOfIdFooter';
import AccountVerificationProofOfIdHeader from '../../header/AccountVerificationProofOfIdHeader';

export default function AccountVerificationProofOfIdSteps3() {
    const { documentUpload, submissionState, updateSubmissionState, isTwoSidedDocument } =
        useProofOfIdVerificationContext();
    const {
        areAllFourCornersVisible,
        areDetailsVisible,
        isDocumentNotScannedOrScreenshot,
        isDocumentReadable,
        isDocumentUnexpired,
        isBothSidesSelected,
    } = submissionState;

    return (
        <>
            <AccountVerificationProofOfIdHeader>
                <span>{translate('Review your document', 'ui.account.kyc')}</span>
            </AccountVerificationProofOfIdHeader>
            <AccountVerificationDocumentPreview isColumnLayout itemHeight={512} documents={documentUpload.documents} />
            <UiFormInput
                checkbox
                label={translate('All four corners of the document are visible', 'ui.account.kyc')}
                value={areAllFourCornersVisible}
                onValueChange={(areAllFourCornersVisible: boolean) =>
                    updateSubmissionState({ areAllFourCornersVisible })
                }
            />
            <UiFormInput
                checkbox
                label={translate('Document is readable', 'ui.account.kyc')}
                value={isDocumentReadable}
                onValueChange={(isDocumentReadable: boolean) => updateSubmissionState({ isDocumentReadable })}
            />
            <UiFormInput
                checkbox
                label={translate('Details are not covered on the document', 'ui.account.kyc')}
                value={areDetailsVisible}
                onValueChange={(areDetailsVisible: boolean) => updateSubmissionState({ areDetailsVisible })}
            />
            <UiFormInput
                checkbox
                label={translate('Document is not a scan or a screenshot', 'ui.account.kyc')}
                value={isDocumentNotScannedOrScreenshot}
                onValueChange={(isDocumentNotScannedOrScreenshot: boolean) =>
                    updateSubmissionState({ isDocumentNotScannedOrScreenshot })
                }
            />
            {isTwoSidedDocument && (
                <UiFormInput
                    checkbox
                    label={translate('Both sides of the document are selected', 'ui.account.kyc')}
                    value={isBothSidesSelected}
                    onValueChange={(isBothSidesSelected: boolean) => updateSubmissionState({ isBothSidesSelected })}
                />
            )}
            <UiFormInput
                checkbox
                label={translate('Document is not expired', 'ui.account.kyc')}
                value={isDocumentUnexpired}
                onValueChange={(isDocumentUnexpired: boolean) => updateSubmissionState({ isDocumentUnexpired })}
            />
            {isDocumentUnexpired && <AccountVerificationProofOfIdExpiryDate />}
            <AccountVerificationProofOfIdFooter nextButtonText={translate('Submit', 'ui.account')} />
        </>
    );
}
