import React from 'react';
import { convertOdds } from '../../../../services/odds-format';
import { useOddsFormat } from '../../../../services/sports/user-settings';
import { stores, useStoreWithSelector } from '../../../../stores';
import { virtualSports } from '../../../../stores/virtual-sports/virtual-sports';
import { getStoreValue } from '../../../../stores/store/utils';
import Wrapper from './styles';
import { filterStyleProps } from '../../../../styles/utils';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    outcomeId?: number;
    marketId?: number;
    isDisabled?: boolean;
    light?: boolean;
    small?: boolean;
    outcome?: any;
    children?: React.ReactNode;
}

function VirtualSportsBetslipOdds({ outcome, isDisabled, children, light, small }: Props) {
    const [isMobileCompactView] = useStore(stores.sports.isMobileCompactView);
    const [{ isPhone }] = useStore(media);
    const isOddsTooBig = outcome.odds_value ? outcome.odds_value.length > 4 : false;
    const isCompactViewEnabled = isMobileCompactView || isPhone;

    const [betslipMarketOutcomeId] = useStoreWithSelector(
        virtualSports.betslipMarketIdToOutcomeId,
        (state) => state[outcome.market_id],
    );

    useOddsFormat();

    function addRemoveBetSlip() {
        const { isLoading, needsConfirm } = getStoreValue(virtualSports.betslipPlacingState);

        if (isLoading || needsConfirm) {
            return;
        }

        if (betslipMarketOutcomeId === outcome.id) {
            virtualSports.betslipMarketIdToOutcomeId.set((state) => {
                delete state[outcome.market_id];
            });

            return;
        }

        virtualSports.betslipMarketIdToOutcomeId.set((state) => {
            state[outcome.market_id] = outcome.id;
        });
    }

    const styleProps = {
        $isCompactViewEnabled: isCompactViewEnabled,
        $isActive: betslipMarketOutcomeId === outcome.id,
        $isDisabled: isDisabled,
        $oddsTooBig: isOddsTooBig,
        light,
        small,
    };

    return (
        <Wrapper {...filterStyleProps(styleProps)}>
            <button
                className="odds-button"
                data-test={isDisabled ? 'button-odds-disabled' : 'button-odds'}
                onClick={addRemoveBetSlip}
            >
                <span className="value">
                    {children}
                    {convertOdds(outcome.odds || outcome.odds_value)}
                </span>
            </button>
        </Wrapper>
    );
}

export default React.memo(VirtualSportsBetslipOdds);
