import React, { useState } from 'react';
import { useDocumentUpload } from '../../../../../../services/kyc';
import { translate } from '../../../../../../services/translate';
import UiAlert from '../../../../../ui/alert/UiAlert';
import AccountVerificationDocumentInput from '../../input/AccountVerificationDocumentInput';
import AccountVerificationDocumentPreview from '../../preview/AccountVerificationDocumentPreview';

interface Props {
    documentUpload: ReturnType<typeof useDocumentUpload>;
    onlyForSide?: 'front' | 'back';
}

export default function AccountVerificationDocumentSelectionTwoSided({ documentUpload, onlyForSide }: Props) {
    const orderBySide = { front: 1, back: 2, undefined: 3 };
    const textBySide = {
        front: 'Select an image of the <strong>front</strong> side',
        back: 'Select an image of the <strong>back</strong> side',
    };
    const side =
        onlyForSide || (documentUpload.documents.some((document) => document.side === 'front') ? 'back' : 'front');
    const [isTryingToUploadSameDocuments, setIsTryingToUploadSameDocuments] = useState(false);

    function handleOnDrop([file]: File[]) {
        setIsTryingToUploadSameDocuments(false);
        if (!file) {
            return;
        }
        if (documentUpload.documents.some((document) => document.name === file.name)) {
            return setIsTryingToUploadSameDocuments(true);
        }
        Object.assign(file, { side });
        documentUpload.addDocuments([file]);
    }

    return (
        <>
            <AccountVerificationDocumentInput
                isDisabled={documentUpload.isSelectingDocumentsDisabled}
                multiple={false}
                onDrop={handleOnDrop}
                selectButtonText={translate(textBySide[side], 'ui.account.kyc')}
            />
            {isTryingToUploadSameDocuments && (
                <UiAlert failure>{translate("You're not allowed to upload same photos", 'ui.account.kyc')}</UiAlert>
            )}
            <AccountVerificationDocumentPreview
                itemHeight={128}
                itemWidth={128}
                documents={documentUpload.documents.sort(
                    (doc1, doc2) => orderBySide[String(doc1.side)] - orderBySide[String(doc2.side)],
                )}
                onRemoveDocument={documentUpload.removeDocument}
            />
        </>
    );
}
