import React, { Children, ReactNode, isValidElement } from 'react';
import Wrapper from './styles';

interface Props {
    children?: ReactNode;
}

export default function AccountVerificationMultiStepHeader({ children }: Props) {
    if (!Children.toArray(children).filter(isValidElement).length) {
        return null;
    }
    return <Wrapper>{children}</Wrapper>;
}
