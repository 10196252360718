import styled from 'styled-components';

export default styled.div`
    font-size: 0.8rem;

    .service-error {
        color: ${({ theme }) => theme.colors.error};
        font-size: 1rem;
    }

    .troubleshooter-hints {
        .hint {
            display: flex;
            flex-direction: row;

            &:not(:last-child) {
                margin-bottom: 0.7rem;
            }
        }

        .hint-icon {
            flex-grow: 0;
            padding-right: 0.5rem;
        }

        .hint-content {
            flex-grow: 1;
        }

        .hint-help,
        .hint-optin {
            margin-left: 0.3rem;
        }
    }
`;
