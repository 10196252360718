import styled from 'styled-components';

import { darken } from 'polished';

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background: ${({ theme }) => darken(0.05, theme.box.background)};

    margin: 0 0 0.5rem 0;
    .balance-amount {
        color: white;
        font-size: 12px;
        text-align: right;
    }
    .statistic {
        padding-bottom: 10px;
        display: flex;
        .stats-box {
            padding: 0.5rem;
            text-align: right;
            p {
                font-size: 0.7rem;
            }
        }
    }
    h3 {
        text-align: left;
        font-size: 0.75rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: normal;
    }
    em {
        color: var(--color-highlight);
        font-size: 1rem;
        font-weight: bold;
        font-style: normal;
    }
`;
