import styled from 'styled-components';

export default styled.div`
    .selection-market {
        font-size: var(--font-14);
        margin-top: var(--spacing-16);

        .market-name {
            margin-bottom: var(--spacing-8);
        }

        .selections-range {
            display: flex;
            align-items: center;
            gap: var(--spacing-4);
        }

        .multiple-selection-row {
            display: flex;
            align-items: center;
            padding-bottom: var(--spacing-8);
            margin-bottom: var(--spacing-8);
            gap: var(--spacing-8);
            & > span {
                min-width: 2rem;
            }
        }

        .bet-type {
            display: inline-block;

            color: var(--color-odds);
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};

            margin-bottom: var(--spacing-12);
        }
    }
`;
