import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isCompactViewEnabled: boolean;
    $isCompactView: boolean;
    $extraInfo: boolean;
    $oddsTooBig: boolean;
    $isScreenShotPage: boolean;
    $isLeaderboard: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '')};
    padding: var(--spacing-12);
    border: var(--ticket-border);
    border-radius: var(--ui-radius);
    background: var(--ticket-totals-bg);
    .totals-label {
        font-size: 0.5rem;
        color: var(--ticket-text-color-secondary);
        font-weight: var(--font-weight-medium);

        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        ${({ $isScreenShotPage }) =>
            $isScreenShotPage &&
            css`
                font-size: 0.65rem;
            `};

        padding: var(--spacing-8) 0;
    }

    .ticket-status-container {
        display: flex;
        justify-content: center;
        width: 100%;
        > :not(:first-child) {
            margin-left: var(--spacing-8);
        }
    }

    .cashout-buttons {
        display: flex;
        justify-content: center;
        margin-top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'var(--spacing-8)' : '')};
        > div {
            flex: 1;
        }
    }

    .ticket-totals {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        > *:not(:last-child) {
            margin-right: 0.8rem;
        }
        .bet-totals {
            position: relative;

            display: flex;
            align-items: center;

            font-size: ${({ theme, $isCompactView }) => ($isCompactView ? '0.8rem' : theme.tickets.totalsFontSize)};

            ${({ $isScreenShotPage }) =>
                $isScreenShotPage &&
                css`
                    font-size: 1.1rem;
                `};
            color: var(--font-color-subtle);
            font-weight: var(--font-weight-medium);
            ${({ theme }) => theme.optimizeFontRendering};

            min-width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : '325px')};

            @media ${mediaQuery.isLaptop} {
                min-width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : '225px')};
            }

            @media ${mediaQuery.isTablet} {
                min-width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : '260px')};
            }

            @media ${mediaQuery.isPhone} {
                font-size: 0.875rem;
            }
            ${({ $isLeaderboard }) =>
                $isLeaderboard &&
                css`
                    min-width: initial !important;
                `};

            > *:not(:last-child) {
                margin-right: 0.4rem;
            }
            .ticket-total-stake {
                color: var(--ticket-stake-text);
                text-align: center;
                padding: 0.4rem;
                flex: 1;
            }
            .ticket-possible-return {
                color: var(--ticket-return-text);
                text-align: center;
                padding: 0.4rem;
                flex: 1;
                &.ma {
                    color: var(--color-alert);
                }
                &.LOST,
                &.PUSHED,
                &.CANCELLED {
                    color: var(--font-color-subtle);
                }
            }
            .cashout-amount {
                color: var(--ticket-return-text);
                border: none;
                text-align: center;
                padding: 0.4rem;
                flex: 1;
            }
            .currency {
                font-size: 50%;
            }

            &.denied {
                .possible-return,
                .total-stake,
                .ma-label {
                    color: var(--font-color-faded);
                }
            }
        }
        .ticket-total-win,
        .ticket-total-odds {
            flex: 1;
            > * {
                width: 100%;
            }
            &.LOST {
                opacity: 0.4;
            }
        }
    }
`;
