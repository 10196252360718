import styled from 'styled-components';

export default styled.div`
    display: inline-block;

    background: var(--chips-bg);
    border: var(--chips-border);
    border-radius: var(--ui-radius);
    padding: var(--spacing-4) var(--spacing-8);

    font-size: var(--font-12);
    color: var(--chips-text-color);
    text-transform: uppercase;
    white-space: nowrap;
    line-height: 1;
    font-weight: var(--font-weight-bold);
    ${({ theme }) => theme.optimizeFontRendering};
`;
