import React, { useState } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';

interface Props<T> {
    options: { text: string; value: T }[];
    selected: T;
    onChange: (newValue: T) => void;
    size?: 'small' | 'large' | 'default';
}

export default function UiMultiToggle<T>({
    options: [{ text: firstText, value: firstValue }, { text: secondText, value: secondValue }],
    selected,
    onChange,
    size,
}: Props<T>) {
    const [selectedValue, setSelectedValue] = useState(selected);

    function onSelected(value: T) {
        setSelectedValue(value);
        onChange(value);
    }

    function isSelected(value: T) {
        return selectedValue === value;
    }

    return (
        <Wrapper className="multi-toggle" $size={size}>
            <div
                className={classNames('toggle-option', {
                    selected: isSelected(firstValue),
                })}
                onClick={() => onSelected(firstValue)}
            >
                {firstText}
            </div>
            <div
                className={classNames('toggle-option', {
                    selected: isSelected(secondValue),
                })}
                onClick={() => onSelected(secondValue)}
            >
                {secondText}
            </div>
        </Wrapper>
    );
}
