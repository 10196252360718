import styled from 'styled-components';

type StyledProps = {
    center?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--spacing-12);
    width: 100%;
    border-bottom: 1px solid var(--surface-level-2-line-color);

    color: var(--font-color-primary);
    font-size: 1rem;
    font-weight: var(--font-weight-bold);
    ${({ theme }) => theme.optimizeFontRendering}

    .title {
        width: ${(props) => (props.center ? '100%' : '')};
        text-align: ${(props) => (props.center ? 'center' : '')};
        line-height: 20px;
    }

    .line-container {
        position: relative;
        height: 2px;
        max-width: 45%;
        margin-top: var(--spacing-8);
    }
`;
