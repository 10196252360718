import styled from 'styled-components';

export default styled.div`
    position: relative;
    min-height: 120px;
    border-radius: var(--ui-radius);
    margin: 1.5rem 1rem;
    transition: box-shadow 200ms linear;

    background-color: var(--surface-level-2-bg);
    border: var(--surface-level-2-border);
    .show-loader {
        min-height: 465px;
    }
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .turnover-market-name {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: var(--spacing-12);
        border: var(--ticket-border);
        border-bottom: 1px dashed var(--ticket-selection-separator);
        border-radius: var(--ui-radius);
        background: var(--ticket-totals-bg);

        font-size: 0.6rem;
        color: var(--font-color-primary);
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .total-sum {
        color: var(--color-turnover);
        font-size: 2rem;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        margin-left: 0.4rem;
        padding: 0 0.4rem;
    }
    .currency {
        font-size: 80%;
        margin-left: 0.4rem;
        color: var(--color-turnover);
        margin-right: 0.4rem;
    }

    .turnover-outcomes {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 0.8rem 0;
        > *:not(:last-child) {
            border-right: 1px solid var(--surface-level-2-line-color);
        }
    }
    .turnover-footer {
        padding: var(--spacing-12);
        display: flex;
        flex-direction: column;
        align-items: center;
        > *:first-child {
            margin-bottom: var(--spacing-12);
        }
    }

    .share-bet {
        padding: 1rem 1rem;
        display: flex;
        justify-content: center;
        .alert {
            margin-bottom: 0;
        }
    }
    .footer-buttons {
        width: 100%;
    }
`;
