import React, { useEffect } from 'react';
import { useDocumentUpload } from '../../../../../services/kyc';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';

interface Props {
    documentUpload: ReturnType<typeof useDocumentUpload>;
    extraData?: Record<string, string>;
    onResetButtonClick: () => void;
}

export default function AccountVerificationDocumentUpload({ documentUpload, extraData, onResetButtonClick }: Props) {
    useEffect(() => {
        documentUpload.uploadDocuments(extraData);
    }, []);

    if (documentUpload.isUploading) {
        return <UiDotsLoader />;
    }

    if (documentUpload.uploadResultAlert) {
        return (
            <>
                {documentUpload.uploadResultAlert}
                <UiButton onClick={onResetButtonClick} block>
                    {translate('OK', 'ui.common')}
                </UiButton>
            </>
        );
    }

    return null;
}
