import React, { useState } from 'react';
import { useProofOfPaymentVerificationContext } from '../../../../../../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import UiDotsLoader from '../../../../../ui/dots-loader/UiDotsLoader';
import AccountVerificationDocumentSelectionOneSided from '../../../document/selection/one-sided/AccountVerificationDocumentSelectionOneSided';
import AccountVerificationDocumentUploadButton from '../../../document/upload-button/AccountVerificationDocumentUploadButton';
import AccountVerificationDocumentUpload from '../../../document/upload/AccountVerificationDocumentUpload';
import AccountVerificationProofOfPaymentDocumentList from '../../document/list/AccountVerificationProofOfPaymentDocumentList';
import AccountVerificationProofOfPaymentHeader from '../../header/AccountVerificationProofOfPaymentHeader';

export default function AccountVerificationProofOfPaymentSteps0() {
    const { isLoading, requestedDocuments, reset, documentUpload } = useProofOfPaymentVerificationContext();
    const [isUploading, setIsUploading] = useState(false);
    if (isLoading) {
        return <UiDotsLoader />;
    }
    if (requestedDocuments.length) {
        return <AccountVerificationProofOfPaymentDocumentList />;
    }
    if (isUploading) {
        return (
            <AccountVerificationDocumentUpload
                documentUpload={documentUpload}
                onResetButtonClick={() => {
                    setIsUploading(false);
                    reset();
                }}
            />
        );
    }
    return (
        <>
            <AccountVerificationProofOfPaymentHeader>
                {documentUpload.statusAlert}
            </AccountVerificationProofOfPaymentHeader>
            {documentUpload.isEnabled && (
                <AccountVerificationDocumentSelectionOneSided documentUpload={documentUpload} />
            )}
            {documentUpload.isDocumentSelected && (
                <AccountVerificationDocumentUploadButton onClick={() => setIsUploading(true)} />
            )}
        </>
    );
}
