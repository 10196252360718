import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    .bonus-entry {
        .bonus-code-entry {
            max-width: 200px;
            width: 100%;
            @media ${mediaQuery.isPhone} {
                max-width: inherit;
            }
            form {
                display: flex;
                align-items: flex-start;
                > button {
                    margin-left: var(--spacing-8);
                }
            }
        }
        .bonus-code-input {
            width: 100%;
            input {
                margin-bottom: var(--spacing-8);
            }
        }
        .bonus-code-link {
            padding: var(--spacing-8) 0;
            color: var(--font-link-color);
            cursor: pointer;
            @media ${mediaQuery.isPhone} {
                text-align: center;
            }
        }
    }
`;
