import React, { ReactNode, useEffect, useState } from 'react';
import { loadKycUserSettings } from '../../microservices/kyc';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

interface DocumentCapture {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    isScriptLoaded: boolean;
    setIsScriptLoaded: (isScriptLoaded: boolean) => void;
    isQrOpen: boolean;
    setIsQrOpen: (isQrOpen: boolean) => void;
    isPreScreenOpen: boolean;
    setIsPreScreenOpen: (isPreScreenOpen: boolean) => void;
    isDesktop: boolean;
    isModalVisible: boolean;
    setIsModalVisible: (isModalVisible: boolean) => void;
    onClose: () => void;
}

export const DocumentCaptureContext = React.createContext({} as DocumentCapture);

const DocumentCaptureProvider = ({ children }: { children: ReactNode }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isQrOpen, setIsQrOpen] = useState(false);
    const [isPreScreenOpen, setIsPreScreenOpen] = useState(true);
    const [{ isDesktop }] = useStore(media);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (!isModalVisible) {
            loadSettings();
        }
    }, [isModalVisible]);

    function onClose() {
        setIsModalVisible(false);
        setIsLoading(true);
        setIsPreScreenOpen(true);
    }

    async function loadSettings() {
        await loadKycUserSettings();
    }

    const state = {
        isLoading,
        setIsLoading,
        isScriptLoaded,
        setIsScriptLoaded,
        isQrOpen,
        setIsQrOpen,
        isPreScreenOpen,
        setIsPreScreenOpen,
        isDesktop,
        isModalVisible,
        setIsModalVisible,
        onClose,
    };

    return <DocumentCaptureContext.Provider value={state}>{children}</DocumentCaptureContext.Provider>;
};

export default DocumentCaptureProvider;
