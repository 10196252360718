import styled from 'styled-components';

export default styled.div`
    .betslip-box {
        position: relative;

        background: var(--betslip-bg);
        border: var(--betslip-border);
        border-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow);
        padding: var(--spacing-12);

        color: var(--fomt-color-primary);
    }
`;
