import React from 'react';
import { translate } from '../../../../../services/translate';
import Wrapper from './styles';

interface Props {
    onClick: () => void;
    isLoading?: boolean;
}

export default function AccountVerificationDocumentUploadButton({ onClick, isLoading }: Props) {
    return (
        <Wrapper color="primary" block onClick={onClick} isLoading={isLoading}>
            {translate('select-and-upload', 'ui.account')}
        </Wrapper>
    );
}
