import styled from 'styled-components';

export default styled.div`
    small {
        font-size: 0.65rem;
        white-space: break-spaces;
    }

    .or {
        text-align: center;
        color: var(--font-color-subtle);
        font-size: 0.8rem;
        padding: 1rem;
    }
`;
