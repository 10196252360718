import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    overflow: auto;
    padding: var(--spacing-8) 0;
    > *:not(:last-child) {
        margin-right: 0.4rem;
    }
    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        justify-content: flex-start;
        padding: 0.8rem 0;
    }
    .extra-links {
        display: flex;
        align-items: center;
        margin-left: auto;
        > *:not(:last-child) {
            margin-right: 0.4rem;
        }
    }
    a {
        text-decoration: none !important;
    }
`;
