import React from 'react';
import moment from 'moment';

export const withShowFromDate = (Component) => (props) => {
    const dateFormat = 'DDMMYY';

    const showFromDate = props['show-from-date'] && moment(props['show-from-date'], dateFormat);
    if (showFromDate && !showFromDate.isBefore()) {
        return null;
    }
    return <Component {...props}>{props.children}</Component>;
};
