import styled, { css } from 'styled-components';

export default styled.div`
    padding-bottom: env(safe-area-inset-bottom);
    position: fixed;
    bottom: 5rem;
    right: 0.6rem;
    z-index: 10;
    animation-duration: 200ms;
    > *:not(:last-child) {
        margin-bottom: 1rem;
    }
    .safe-area {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bet-history-link {
        height: 45px;
        width: 45px;
        background: var(--surface-level-2-bg);
        border: var(--surface-level-2-border);
        box-shadow: 0 12px 30px -11px #000;
        border-radius: 100px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;

        color: var(--font-color-primary);
        font-size: 7px;
        text-transform: uppercase;
        font-weight: 700;
    }
    .betslip-link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        background: var(--odds-bg-active);
        border: var(--odds-border-active);
        box-shadow: var(--ui-shadow);
        border-radius: 100px;
        width: 45px;
        height: 45px;

        ${({ hidden }) =>
            hidden &&
            css`
                display: none !important;
            `};

        &::after {
            opacity: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            height: 100%;
            width: 100%;
            border: 12px solid var(--odds-bg-active);
            border-radius: 100%;
            animation-name: ripple;
            animation-duration: 3s;
            animation-delay: 0s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
            z-index: -1;
        }

        &::before {
            opacity: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            height: 100%;
            width: 100%;
            border: 8px solid var(--odds-bg-active);
            border-radius: 100%;
            animation-name: ripple;
            animation-duration: 3s;
            animation-delay: 0.5s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
            z-index: -1;
        }

        .bets-count {
            color: var(--odds-text-active);
            font-weight: var(--font-weight-bold);
            font-size: 1.25rem;
        }
    }
    @keyframes ripple {
        from {
            opacity: 1;
            transform: scale3d(0.75, 0.75, 1);
        }

        to {
            opacity: 0;
            transform: scale3d(1.5, 1.5, 1);
        }
    }
`;
