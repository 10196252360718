import React, { useEffect } from 'react';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export const withMenuPage = (Component, reference) => (props) => {
    const { children } = props;
    const [menuPage, setMenuPage] = useStore(stores.cms.menuPage);

    useEffect(() => {
        setMenuPage((menuPage) => ({
            ...menuPage,
            [reference]: Object.keys(menuPage).length === 0,
        }));
    }, []);

    if (menuPage[reference]) {
        return <Component {...props}>{children}</Component>;
    }
    return null;
};
