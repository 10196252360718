import React, { useMemo } from 'react';
import Wrapper from './styles';
import chunk from 'lodash/chunk';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    columnsAmount?: number;
    columnsAmountsByDevice?: { [key: string]: number };
    WrappingComponent?: ({ children }: { children: React.ReactNode }) => JSX.Element;
    layoutInRows?: boolean;
    keepExcessToRight?: boolean;
    itemsAmountInChunkByColumns?: { [key: string]: number };
    children: React.ReactNode | React.ReactNode[];
}
export default function UiColumns({
    columnsAmount = 1,
    columnsAmountsByDevice,
    WrappingComponent,
    layoutInRows = false,
    keepExcessToRight = false,
    itemsAmountInChunkByColumns,
    children,
}: Props) {
    const [{ deviceType, isPhone, isDesktop, isTablet, isLaptop }] = useStore(media);

    function getColumnsAmount() {
        if (columnsAmountsByDevice) {
            return columnsAmountsByDevice[deviceType] || 1;
        }

        return columnsAmount;
    }

    function getComponentMatrixByColumns(componentsList: React.ReactNode[]) {
        if (itemsAmountInChunkByColumns) {
            return Array.from({ length: itemsAmountInChunkByColumns.length })
                .map((columnSet, columnSetIndex) => Array.from({ length: itemsAmountInChunkByColumns[columnSetIndex] }))
                .map((columnSet, columnSetIndex, columnSets) =>
                    columnSet.map((value, index) => {
                        const currentComponentIndex =
                            (columnSetIndex === 0
                                ? columnSetIndex
                                : columnSets.slice(0, columnSetIndex).flat().length) + index;

                        return componentsList[currentComponentIndex];
                    }),
                );
        }

        componentsList = keepExcessToRight ? componentsList.slice().reverse() : componentsList;
        const maxItemsInChunk = Math.ceil(componentsList.length / getColumnsAmount());

        const componentsColumnSets: React.ReactNode[][] = chunk(componentsList, maxItemsInChunk).map((columnSet) =>
            keepExcessToRight ? columnSet.reverse() : columnSet,
        );

        return keepExcessToRight ? componentsColumnSets.reverse() : componentsColumnSets;
    }

    function getComponentMatrixByRows(componentsList) {
        componentsList = keepExcessToRight ? componentsList.slice().reverse() : componentsList;
        let componentsColumnSets: React.ReactNode[][] = [];

        componentsList.forEach((component, index) => {
            const activeColumnSetIndex: number = index % getColumnsAmount();
            componentsColumnSets[activeColumnSetIndex] = componentsColumnSets[activeColumnSetIndex] || [];
            componentsColumnSets[activeColumnSetIndex].push(component);
        });

        componentsColumnSets = componentsColumnSets.map((columnSet) =>
            keepExcessToRight ? columnSet.reverse() : columnSet,
        );

        return keepExcessToRight ? componentsColumnSets.reverse() : componentsColumnSets;
    }

    const columnSets = useMemo(
        () =>
            layoutInRows
                ? getComponentMatrixByRows(children)
                : getComponentMatrixByColumns(children as React.ReactNode[]),
        [layoutInRows, children],
    );

    const columnSetsMarkup = useMemo(
        () =>
            columnSets.map((componentsColumnSet, index) => (
                <div key={index} className="column">
                    {componentsColumnSet?.map((Component, index) => (
                        <div key={index}>
                            <>
                                {!WrappingComponent && Component}
                                {WrappingComponent && <WrappingComponent>{Component}</WrappingComponent>}
                            </>
                        </div>
                    ))}
                </div>
            )),
        [columnSets],
    );

    return (
        <Wrapper
            isPhone={isPhone}
            isDesktop={isDesktop}
            isTablet={isTablet}
            isLaptop={isLaptop}
            columnsAmount={getColumnsAmount()}
        >
            {columnSetsMarkup}
        </Wrapper>
    );
}
