import styled from 'styled-components';

export default styled.div`
    .link-button {
        color: var(--font-color-subtle);
        cursor: pointer;
        display: table;
        font-size: 0.8rem;
        margin: 0 auto;
        text-align: center;
        &:hover {
            text-decoration: underline;
        }
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
