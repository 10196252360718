import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import { getTicketStatus } from '../../../../services/bet-history';
import { Ticket, UniqueSelection } from '../../../../services/sports/types';

interface Props {
    ticket: Ticket | UniqueSelection;
    className?: string;
    block?: boolean;
}

export default function SportBadgeTicketStatus({ ticket, className, block }: Props) {
    const status = getTicketStatus(ticket);
    return (
        <Wrapper $status={status} className={className} $block={block}>
            {translate((ticket.cashout_status && 'Cashed out') || status, 'ui.sportsbook')}
        </Wrapper>
    );
}
