import classNames from 'classnames';
import React, { Fragment } from 'react';
import Wrapper from './styles';

interface Props {
    steps: { description?: string; isCompleted?: boolean; isDisabled?: boolean }[];
    activeStep: number;
    onStepClicked: (index: number) => void;
    className?: string;
}

export default function AccountVerificationMultiStepProgressIndicator({
    steps,
    activeStep,
    onStepClicked,
    className,
}: Props) {
    return (
        <Wrapper className={className}>
            {steps.map((step, index) => {
                if (step.isDisabled) {
                    return null;
                }
                const isClickable = step.isCompleted || steps[index - 1]?.isCompleted;
                return (
                    <Fragment key={index}>
                        {index > 0 && !steps[index - 1]?.isDisabled && (
                            <span className={classNames('divider', isClickable && 'active')} />
                        )}
                        <div
                            className={classNames(
                                'step',
                                (index === activeStep && 'active') || (step.isCompleted && 'completed'),
                                isClickable && 'clickable',
                            )}
                            onClick={isClickable ? () => onStepClicked(index) : undefined}
                            role="navigation"
                        >
                            <span className="indicator" />
                            <span className="description">{step.description}</span>
                        </div>
                    </Fragment>
                );
            })}
        </Wrapper>
    );
}
