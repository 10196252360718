import React, { useEffect, useState } from 'react';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { translate } from '../../../../services/translate';
import { validators } from '../../../../services/validators';
import { getSignupAgeLimit } from '../../../../services/user';
import { useForm } from '../../../../services/form';
import { stores } from '../../../../stores';

export default function RegistrationFieldsHasAcceptedAgeAndPlayingOwnBehalf() {
    const form = useForm(stores.registration.form);
    const { country } = form.getValues();
    const [registrationCountry, setRegistrationCountry] = useState(country);

    useEffect(() => {
        setRegistrationCountry(country);
    }, [country]);

    return (
        <UiFormInput
            {...form.hasAcceptedAgeAndPlayingOwnBehalf}
            checkbox
            validator={validators.required}
            label={translate(
                'I confirm that I am at least {{ age }} years old and that I am playing on my own behalf.',
                'ui.registration',
                { age: getSignupAgeLimit(registrationCountry) },
            )}
            data-test="hasAcceptedAgeAndPlayingOwnBehalf"
        />
    );
}
