import { PromotionTypes } from '../services/cms-promotion';
import { createStores } from './store/utils';

export const marketing = createStores({
    upsellRecommendation: {
        matchId: undefined,
        marketId: undefined,
    },
    matchPromotionsByMatchIds: {} as { [matchId: number]: PromotionTypes },
});
