import React, { forwardRef } from 'react';
import { UiFormContextConsumer, UiFormContextProvider } from '../../contexts/ui-form/UiFormContext';

export const withUiFormContext = <T extends Record<string, any> & { children?: React.ReactNode }>(
    Component: React.ComponentType<T>,
) =>
    forwardRef<HTMLFormElement, T>((props: T, ref) => {
        return (
            <UiFormContextProvider>
                <Component {...props} forwardedRef={ref}>
                    <UiFormContextConsumer>{props.children}</UiFormContextConsumer>
                </Component>
            </UiFormContextProvider>
        );
    });
