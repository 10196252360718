import styled from 'styled-components';

export default styled.div`
    .bonus-bet-description {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--spacing-4);

        border-radius: var(--ui-radius);
        background: var(--button-primary-bg);
        padding: var(--spacing-12);
        margin-top: var(--spacing-8);

        &:before {
            content: '';
            position: absolute;
            margin-top: 0;
            top: -14px;
            border: solid 7px transparent;
            border-bottom-color: var(--button-primary-bg);
            z-index: 1;
            left: 50%;
            transform: translate(-50%, 0);
        }
        .description-row {
            font-size: var(--font-12);
            color: var(--button-primary-text-color);
            text-align: left;
            padding: var(--spacing-4);

            .row-value {
                font-weight: var(--font-weight-bold);
                ${({ theme }) => theme.optimizeFontRendering};
            }
        }
    }
`;
