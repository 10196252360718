import { useEffect } from 'react';

interface Props {
    url: string;
    onLoad?: () => void;
    onError?: (error: Event | string) => void;
    attributes?: Record<string, string>;
}
export default function ScriptLoader({ onError = () => {}, onLoad = () => {}, url, attributes = {} }: Props) {
    function isScriptLoaded() {
        const scripts = document.getElementsByTagName('script');
        return Array.from(scripts).some((script) => {
            return script.src.includes(url);
        });
    }

    function load() {
        const script = document.createElement('script');
        script.async = true;
        script.onload = onLoad;
        script.onerror = onError;
        script.src = url;
        Object.entries(attributes).forEach(([key, value]) => script.setAttribute(key, value));
        document.body.append(script);
    }

    useEffect(() => {
        if (isScriptLoaded()) {
            onLoad();
        } else {
            load();
        }
    }, []);

    return null;
}
