import styled from 'styled-components';

export default styled.div`
    position: relative;
    .balance-status {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        font-size: 10px;
        letter-spacing: 1px;
        text-align: left;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
        &-amount {
            font-size: 18px;
        }
        &-container {
            display: flex;
            justify-content: space-around;
            @media screen and (max-width: 768px) {
                flex-flow: column nowrap;
            }
        }
    }
    .blue {
        margin-right: 10px;
        width: 30px;
        svg {
            fill: #00bfff;
            height: 21px;
        }
    }
`;
