import styled from 'styled-components';

export default styled.div`
    .status-indicators {
        display: flex;
        font-weight: bold;
        text-transform: uppercase;
        user-select: none;

        .status-indicator {
            .heading {
                color: var(--font-color-faded);
            }

            .status {
                color: ${({ theme }) => theme.colors.success};
                margin-left: ${({ theme }) => theme.layout.gutterHalf};
                font-style: italic;
            }

            &.declined .status {
                color: ${({ theme }) => theme.colors.error};
            }
        }

        .status-indicator:last-of-type {
            margin-left: ${({ theme }) => theme.layout.gutterHalf};
        }
    }

    .decline-reason-title {
        color: var(--font-color-subtle);
        margin-bottom: ${({ theme }) => theme.layout.gutterHalf};
    }

    &.compact {
        color: ${({ theme }) => theme.colors.error};
        display: inline;
        font-size: 0.7rem;
        text-align: left;
    }

    &:not(.compact) {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
