import Cookies from 'browser-cookies';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { storageGet, storageSet } from './storage';

export function getDeviceUuid() {
    return (getStoreValue(stores.uuid) || storageGet('uuid') || Cookies.get('uuid')) as string;
}

export function setDeviceUuid(deviceUuid: string) {
    stores.uuid.set(deviceUuid);
    storageSet('uuid', deviceUuid);
    setDeviceUuidCookie(deviceUuid);
}

export function setDeviceUuidCookie(uuid: string) {
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 10);
    Cookies.set('uuid', uuid, { expires: expiryDate, path: '/' });
}
