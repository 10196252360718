import React from 'react';
import { getClient } from '../../../../services/environment';
import { ClientName } from '../../../../services/utils/types';
import CoolbetHeaderMobileLayoutVariant1 from './layout/variant-1/CoolbetHeaderMobileLayoutVariant1';
import CoolbetHeaderMobileLayoutDefault from './layout/default/CoolbetHeaderMobileLayoutDefault';

export default function CoolbetHeaderMobile() {
    const client = getClient();

    const clientLayoutDict = {
        [ClientName.STATION]: <CoolbetHeaderMobileLayoutVariant1 />,
    };

    function getHeaderLayout(clientName) {
        if (clientName in clientLayoutDict) {
            return clientLayoutDict[clientName];
        } else {
            return <CoolbetHeaderMobileLayoutDefault />;
        }
    }
    return getHeaderLayout(client);
}
