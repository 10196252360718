import React from 'react';
import Wrapper from './styles';
import { useStoreWithSelector } from '../../../../stores';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import UiButton from '../../../ui/button/UiButton';
import { INITIAL_BETSLIP_PLACING_STATE } from '../../../../services/virtual-sports/constants';
import VirtualSportsTicketDetails from '../../ticket/details/VirtualSportsTicketDetails';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { virtualSports } from '../../../../stores/virtual-sports/virtual-sports';

export default function VirtualSportsBetslipReceipt() {
    const [receipt] = useStoreWithSelector(virtualSports.betslipPlacingState, (state) => state.receipt);
    if (!receipt) {
        return null;
    }

    return (
        <Wrapper>
            <p>
                {translate('Bet ID:', 'ui.betslip')} {receipt.display_id} /{' '}
                {moment(receipt.created_at).format('dd.MM.YYYY HH:mm')}
            </p>
            <UiAlert center success>
                {translate('Your bet is placed. Good luck!', 'ui.betslip')}
            </UiAlert>

            <VirtualSportsTicketDetails ticket={receipt} isCompactView />

            <UiButton
                block
                onClick={() => virtualSports.betslipPlacingState.set(cloneDeep(INITIAL_BETSLIP_PLACING_STATE))}
            >
                {translate('Close', 'ui.common')}
            </UiButton>
        </Wrapper>
    );
}
