import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { getIsFirstBet } from '../../microservices/sbgate';
import { stores } from '../../stores';
import { useStore } from '../../hooks/useStore';

export default function WagerLimitLink() {
    const [isFirstBet, setIsFirstBet] = useState(false);
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            getIsFirstBet().then((hasBets) => {
                setIsFirstBet(Boolean(hasBets));
            });
        }
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        return null;
    }

    return (
        <Wrapper>
            {isFirstBet && (
                <>
                    Do you want to set{' '}
                    <a target="_blank" href="/account/wager-limit" rel="noreferrer">
                        wager limits?
                    </a>
                </>
            )}
        </Wrapper>
    );
}
