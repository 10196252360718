import React from 'react';
import { useProofOfAddressVerificationContext } from '../../../../../../contexts/proof-of-address-verification/ProofOfAddressVerificationContext';
import { translate } from '../../../../../../services/translate';
import AccountVerificationOptionsList from '../../../options-list/AccountVerificationOptionsList';
import AccountVerificationProofOfAddressFooter from '../../footer/AccountVerificationProofOfAddressFooter';
import AccountVerificationProofOfAddressHeader from '../../header/AccountVerificationProofOfAddressHeader';

export default function AccountVerificationProofOfAddressSteps1() {
    const { submissionState, updateSubmissionState } = useProofOfAddressVerificationContext();
    const { documentType: selectedDocumentType } = submissionState;
    const documentTypeOptions = ['Utility bill', 'Government letter', 'Bank statement', 'Other'].map(
        (documentType) => ({
            value: documentType,
            label: translate(documentType, 'ui.account.kyc.poa'),
        }),
    );

    return (
        <>
            <AccountVerificationProofOfAddressHeader>
                <span>{translate('Choose the document type you would like to upload', 'ui.account.kyc.poa')}</span>
            </AccountVerificationProofOfAddressHeader>
            <AccountVerificationOptionsList
                options={documentTypeOptions}
                selectedOption={selectedDocumentType}
                onOptionSelected={(documentType) => updateSubmissionState({ documentType })}
            />
            <AccountVerificationProofOfAddressFooter />
        </>
    );
}
