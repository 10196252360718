import { TerminalStatus } from '@staycool/retail-types/terminal';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { logout } from './auth';
import { getRoute } from './router';
import { BetslipMode, clearBetslipSelection } from './sports/betslip';
import { initialBetSlipPlacingState } from './sports/constants';
import { BetSlipReplayState } from './sports/types';
import { isLocalDevelopment, isRetail } from './environment';
import { clearMiddlewareLoyalty } from '../microservices/retail-middleware';
import { retail } from '../stores/retail/retail';
import { LoyaltyResponseKiosk } from '../stores/retail/types';

export const resetSession = () => {
    retail.retailModals.retailPrintingModal.set({ isPrinting: false });
    retail.retailKeyboard.isVisible.set(false);
    retail.retailModals.isRetailModalLoyalty.set(false);
    retail.retailModals.retailModalBringYourDevice.set(undefined);
    stores.sports.ticketsToPrint.set([]);
    stores.sports.betSlipReplayState.set({} as BetSlipReplayState);
    stores.sports.betSlipPlacingState.set(initialBetSlipPlacingState);
    stores.sports.parlayCard.betSlipPlacingState.set(initialBetSlipPlacingState);
    retail.isRetailBalanceVisible.set(true);
    clearBetslipSelection(BetslipMode.Betslip, true, true);
    clearBetslipSelection(BetslipMode.ParlayCard, true, true);
    clearLoyalty();
    logout();
    if (getStoreValue(retail.retailTerminal)?.status !== TerminalStatus.Blocked) {
        retail.retailSessionClosed.set(true);
    }
};

export async function clearLoyalty() {
    if (isRetail() && !isLocalDevelopment()) {
        await clearMiddlewareLoyalty();
    }
    const loyaltyClearanceTimeout = getStoreValue(retail.loyaltyClearanceTimeout);
    if (loyaltyClearanceTimeout) {
        clearTimeout(loyaltyClearanceTimeout);
    }
    retail.loyalty.set({} as LoyaltyResponseKiosk);
}

const clearExpirationTimeout = () => {
    const retailExpirationTimeout = getStoreValue(retail.retailExpirationTimeout);
    if (retailExpirationTimeout) {
        clearTimeout(retailExpirationTimeout);
    }
};

export function proceedExpiration() {
    const { pathname } = window.location;
    const page = (window as any).coolb2b?.page;
    const isRetailMaintenance = pathname.includes(getRoute('retail.maintenance'));
    const settings = getStoreValue(retail.retailSettings);
    const hasRetailSession = getStoreValue(retail.hasRetailSession);
    retail.retailModals.isRetailModalExpires.set(false);
    if (page || isRetailMaintenance) {
        return clearExpirationTimeout();
    }

    if (hasRetailSession) {
        resetExpiration(resetExpirationPopup, settings.retailExpirationTime - 30_000);
    } else {
        resetExpiration(resetSession, settings.retailKioskIdleTime || settings.retailExpirationTime);
    }
}

const resetExpiration = (toExecute: () => void, timeToExecute: number) => {
    clearExpirationTimeout();
    retail.retailExpirationTimeout.set(setTimeout(toExecute, timeToExecute));
};

export const resetExpirationPopup = () => {
    retail.retailKeyboard.isVisible.set(false);
    retail.retailModals.isRetailModalExpires.set(true);
};
