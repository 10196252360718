import React from 'react';
import {
    ProofOfPaymentVerificationContextConsumer,
    ProofOfPaymentVerificationContextProvider,
} from '../../../../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import { getBadgeProps } from '../../../../services/kyc';
import { translate } from '../../../../services/translate';
import AccountVerificationMultiStepBox from '../multi-step/box/AccountVerificationMultiStepBox';
import AccountVerificationProofOfPaymentSteps from './steps/AccountVerificationProofOfPaymentSteps';

interface Props {
    isCompactView?: boolean;
}

export default function AccountVerificationProofOfPayment(props: Props) {
    return (
        <ProofOfPaymentVerificationContextProvider>
            <ProofOfPaymentVerificationContextConsumer>
                {({
                    activeStep,
                    documentUpload,
                    formContainerRef,
                    isLoading,
                    isSecondStepSkipped,
                    requestedDocuments,
                    setActiveStep,
                    stepCompletionState,
                }) => {
                    const badgeProps =
                        activeStep > 0
                            ? { badgeText: translate('In progress', 'ui.common'), badgeColor: 'info' }
                            : getBadgeProps(documentUpload.status);
                    const boxProps =
                        !isLoading && !requestedDocuments.length
                            ? {
                                  title: translate('proof-of-payment-method', 'ui.account'),
                                  description: translate(
                                      'Document confirming ownership of payment method.',
                                      'ui.account',
                                  ),
                                  icon: 'credit-cards',
                              }
                            : {};
                    const progressIndicatorSteps = [
                        {
                            description: translate('options', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[1],
                            isDisabled: isSecondStepSkipped,
                        },
                        {
                            description: translate('files', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[2],
                        },
                        {
                            description: translate('review', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[3],
                        },
                        {
                            description: translate('submit', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[4],
                        },
                    ];
                    return (
                        <AccountVerificationMultiStepBox
                            Steps={AccountVerificationProofOfPaymentSteps}
                            activeStep={activeStep}
                            progressIndicatorSteps={progressIndicatorSteps}
                            ref={formContainerRef}
                            setActiveStep={setActiveStep}
                            dataTest="proof-of-payment"
                            {...badgeProps}
                            {...boxProps}
                            {...props}
                        />
                    );
                }}
            </ProofOfPaymentVerificationContextConsumer>
        </ProofOfPaymentVerificationContextProvider>
    );
}
