import React, { useEffect, useState } from 'react';
import Cms from '../../components/cms/Cms';
import Seo from '../../components/seo/Seo';
import { stores } from '../../stores';
import { useRouter } from '../../services/router';
import { useStore } from '../../hooks/useStore';

interface Props {
    isPreviewRequest?: boolean;
}

export default function CmsPage({ isPreviewRequest }: Props) {
    const { pathname, params } = useRouter();
    const [language] = useStore(stores.language);

    const [page, setPage] = useState<{ seo_title: string; seo_desc: string; slug: string; seo_img: string }>();

    useEffect(() => {
        if (page) {
            stores.cms.currentArticleSlugByLanguage.set(page.slug);
            return () => stores.cms.currentArticleSlugByLanguage.set('');
        }
    }, [page]);

    const pageTitle = page && page.seo_title[language];
    const pageDescription = page && page.seo_desc[language];
    const pageImage = (page && page.seo_img && page.seo_img[language]) || '';

    const alternateLinks =
        page &&
        Object.keys(page.slug).map((language) => ({
            hrefLang: language,
            rel: 'alternate',
            href: `/${language}/${page.slug[language]}`,
        }));

    const articleSlugInActiveLanguage = params.articleSlug || pathname.split('/').reverse()[0];

    return (
        <>
            {pageTitle && pageDescription && (
                <Seo
                    title={pageTitle}
                    description={pageDescription}
                    alternateLinks={alternateLinks}
                    image={pageImage}
                />
            )}

            <Cms
                pageSlug={articleSlugInActiveLanguage}
                onPageLoaded={(page) => setPage(page)}
                isPreviewRequest={isPreviewRequest}
            />
        </>
    );
}
