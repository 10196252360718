import styled from 'styled-components';

export default styled.ul`
    list-style-type: none;
    padding: 0;

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 ${({ theme }) => theme.layout.gutter} !important;
        height: 3rem;
        border-bottom: 1px solid var(--font-color-subtle);
        transition: filter 0.4s ease-in-out;

        :hover {
            cursor: pointer;
            filter: brightness(1.2);
        }

        svg {
            color: ${({ theme }) => theme.colors.success};
        }

        &.selected {
            font-weight: bold;
        }
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>>;
