import React from 'react';
import Snippet from '../../snippet/Snippet';

interface Props {
    'snippet-key': string;
}

export default function CmsSnippet({ 'snippet-key': snippetKey }: Props) {
    if (!snippetKey) {
        return null;
    }
    return <Snippet snippetKey={snippetKey} />;
}
