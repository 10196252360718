import React from 'react';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import Loader from '../../loader/Loader';
import UiBox from '../../ui/box/UiBox';
import UiGroup from '../../ui/group/UiGroup';
import UiSubHeading from '../../ui/sub-heading/UiSubHeading';
import AuthLoginButton from '../login/button/AuthLoginButton';
import AuthRegisterButton from '../register/button/AuthRegisterButton';
import Wrapper from './styles';
import { useStore } from '../../../hooks/useStore';

export default function AuthLoader() {
    const [isLoginModalOpen] = useStore(stores.modals.isLoginModalOpen);

    return (
        <Wrapper>
            <UiBox className="please-login">
                {isLoginModalOpen && <Loader />}
                <UiSubHeading>{translate('You need to be logged in to view this page', 'ui.account')}</UiSubHeading>

                <UiGroup horizontallyCentered className="login-box">
                    <AuthRegisterButton />
                    <AuthLoginButton />
                </UiGroup>
            </UiBox>
        </Wrapper>
    );
}
