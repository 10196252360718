import last from 'lodash/last';
import React, { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../../higher-order-components/error-boundary';
import { fetchCashouts } from '../../../../microservices/bets';
import { errorCodes, useCashoutListeners } from '../../../../services/sports/cashout';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import SportTicket from '../../ticket/SportTicket';
import UiButton from '../../../ui/button/UiButton';
import { Ticket } from '../../../../services/sports/types';

function SportCashoutList() {
    const [cashoutResponse, setCashoutResponse] = useState({} as { hasMore: boolean; tickets: Ticket[] });
    const [error, setError] = useState<{ code: number }>();
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState();

    useCashoutListeners({
        cashoutTickets: cashoutResponse?.tickets,
        setTickets: (tickets: Ticket[]) => {
            setCashoutResponse({ ...cashoutResponse, tickets });
        },
    });

    useEffect(() => {
        setIsLoading(true);
        fetchCashouts(offset).then(setResponse).catch(setError);
    }, [offset]);

    function setResponse(response) {
        setIsLoading(false);
        if (offset) {
            const newResponse = { ...cashoutResponse };
            newResponse.tickets = [...cashoutResponse.tickets, ...response.tickets];
            newResponse.hasMore = response.hasMore;
            setCashoutResponse(newResponse);
        } else {
            setCashoutResponse({ ...response });
        }
    }

    if (error) {
        return (
            <UiAlert failure>
                {errorCodes[error.code]
                    ? translate(errorCodes[error.code], 'ui.cashout.button')
                    : translate('Technical Error', 'ui.common')}
            </UiAlert>
        );
    }

    if (!cashoutResponse?.tickets) {
        return <UiDotsLoader />;
    }

    if (cashoutResponse?.tickets?.length === 0) {
        return <UiAlert info>{translate('No tickets eligible for cashout', 'ui.cashout.tab')}</UiAlert>;
    }

    return (
        <>
            {cashoutResponse?.tickets?.map((ticket) => (
                <SportTicket key={ticket.id} isCompactView={true} ticket={ticket} />
            ))}

            {cashoutResponse?.hasMore && (
                <UiButton
                    color="primary"
                    onClick={() => setOffset((last(cashoutResponse.tickets) as any).created_at)}
                    isLoading={isLoading}
                    block
                >
                    {translate('Load more', 'ui.common')}
                </UiButton>
            )}
        </>
    );
}

export default withErrorBoundary(SportCashoutList);
