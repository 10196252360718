import { getStoreValue } from '../stores/store/utils';
import { stringify } from 'query-string';
import { environment } from '../stores/environment/environment';

export function getCmsImageUrl(fileName, params = {}) {
    const imageHost = getStoreValue(environment).CMS_IMAGES;
    return getImageUrl(`${imageHost}${fileName}`, params);
}

function getImageUrl(rootPath, params = {}) {
    let dpr = window.devicePixelRatio;
    if (dpr > 2) {
        dpr = 2;
    }
    const queryParams = stringify({ ...params, dpr });
    return `${rootPath}?${queryParams}`;
}
