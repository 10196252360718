import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    header?: string;
    light?: boolean;
    isCompactView?: boolean;
    wide?: boolean;
    shouldWrap?: boolean;
    className?: any;
    children: any;
    style?: any;
    id?: string;
}
export default function UiBox({ children, header, ...rest }: Props) {
    return (
        <Wrapper {...filterStyleProps(rest)}>
            {header && <div className="card-header">{header}</div>}
            <div className="card-content">{children}</div>
        </Wrapper>
    );
}
