import React from 'react';
import Wrapper from './styles';
import UiAlert from '../../../../ui/alert/UiAlert';
import { TicketMaInfoTranslationKey, TICKET_MA_STATUS, ValuesOfMAStatus } from '../../../../../services/bet-history';
import { translate } from '../../../../../services/translate';
import { formattedAmountWithCurrency } from '../../../../../services/currency';

interface Props {
    ma_status: ValuesOfMAStatus;
    ma_stake: number;
    stake_requested: number;
    total_stake: number;
}
export default function SportTicketTotalsMaInfo({ ma_status, ma_stake, stake_requested, total_stake }: Props) {
    const manualAcceptanceStatus = {
        pending: ma_status === TICKET_MA_STATUS.PENDING,
        accepted: ma_status === TICKET_MA_STATUS.ACCEPTED && ma_stake === stake_requested,
        partiallyAccepted: ma_status === TICKET_MA_STATUS.ACCEPTED && ma_stake !== stake_requested,
        declined: ma_status === TICKET_MA_STATUS.DECLINED,
    };

    const translateWrapper = (key: TicketMaInfoTranslationKey) => {
        const initialStake = manualAcceptanceStatus.accepted
            ? total_stake
            : manualAcceptanceStatus.partiallyAccepted
            ? total_stake + (stake_requested - ma_stake)
            : total_stake + stake_requested;

        const translationData = {
            initialStake: formattedAmountWithCurrency(initialStake),
            acceptedStake: formattedAmountWithCurrency(total_stake),
        };

        return translate(key, 'ui.sportsbook.bet-history', translationData);
    };
    return !ma_status ? null : (
        <Wrapper>
            <UiAlert className="manual-acceptance-info" info center>
                <div>
                    {manualAcceptanceStatus[TICKET_MA_STATUS.PENDING]
                        ? translateWrapper(TicketMaInfoTranslationKey.MA_PENDING_INFO)
                        : translateWrapper(TicketMaInfoTranslationKey.MA_NON_PENDING_INFO)}
                </div>
            </UiAlert>
        </Wrapper>
    );
}
