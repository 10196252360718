import React from 'react';
import moment from 'moment';

export const withShowOnWeek = (Component) => (props) => {
    const dateFormat = 'DDMMYY';
    const showOnWeek = props['show-on-week'] && moment(props['show-on-week'], dateFormat);

    function shouldShow(date) {
        if (!date) {
            return false;
        }

        const now = moment();
        const isCurrentYear = now.year() === date.year();
        const isCurrentWeek = now.isoWeek() === date.isoWeek();

        return isCurrentYear && isCurrentWeek;
    }

    if (!shouldShow(showOnWeek)) {
        return null;
    }
    return <Component {...props}>{props.children}</Component>;
};
