import styled from 'styled-components';

type StyledProps = {
    $isListViewType: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    background-color: var(--surface-level-1-bg);
    ${({ $isListViewType }) => ($isListViewType ? 'flex-direction: column' : '')};

    border-bottom-left-radius: var(--ui-radius);
    border-bottom-right-radius: var(--ui-radius);
    .outcome {
        flex: 1;
        display: flex;
        flex-direction: ${({ $isListViewType }) => ($isListViewType ? 'row' : 'column')};
        text-align: center;
        div {
            width: 100%;
        }
        .outcome-name {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 0.7rem;
            margin: 0.4rem 0;
            padding: 0 0.2rem;
            span {
                width: 100%;
            }
        }
        .odds {
            align-self: flex-end;
            ${({ $isListViewType }) => ($isListViewType ? 'flex: 1;' : '')}
        }
    }
`;
