import React from 'react';
import { stores } from '../../../../stores';
import HorseRacingRaceStakeBetslip from './betslip/HorseRacingRaceStakeBetslip';
import HorseRacingRaceStakeNormal from './normal/HorseRacingRaceStakeNormal';
import { getFixedStake } from '../../../../services/sports/betslip-formatting';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isInBetslip?: boolean;
}

export default function HorseRacingRaceStake({ isInBetslip = false }: Props) {
    const [betslip, setBetslip] = useStore(stores.horseRacing.betslip);
    const { betType } = betslip;

    function handleStakeChange(value?: string) {
        if (!betType) {
            return;
        }
        const stakeValue = value && getFixedStake(value);
        setBetslip((prevBetslip) => {
            if (stakeValue === prevBetslip.betStake.toString()) {
                return prevBetslip;
            }

            stores.horseRacing.betslipStakeErrors.set([]);
            return { ...prevBetslip, betStake: getFixedStake(stakeValue || '') };
        });
    }

    return isInBetslip ? (
        <HorseRacingRaceStakeBetslip betType={betType} handleChange={handleStakeChange} />
    ) : (
        <HorseRacingRaceStakeNormal handleStakeChange={handleStakeChange} />
    );
}
