import React from 'react';
import { useProofOfIdVerificationContext } from '../../../../../../contexts/proof-of-id-verification/ProofOfIdVerificationContext';
import { translate } from '../../../../../../services/translate';
import AccountVerificationDocumentSelectionOneSided from '../../../document/selection/one-sided/AccountVerificationDocumentSelectionOneSided';
import AccountVerificationDocumentSelectionTwoSided from '../../../document/selection/two-sided/AccountVerificationDocumentSelectionTwoSided';
import AccountVerificationProofOfIdFooter from '../../footer/AccountVerificationProofOfIdFooter';
import AccountVerificationProofOfIdHeader from '../../header/AccountVerificationProofOfIdHeader';

export default function AccountVerificationProofOfIdSteps2() {
    const { isTwoSidedDocument, documentUpload } = useProofOfIdVerificationContext();

    return (
        <>
            <AccountVerificationProofOfIdHeader>
                <span>{translate('Choose documents to upload', 'ui.account.kyc')}</span>
            </AccountVerificationProofOfIdHeader>
            {isTwoSidedDocument ? (
                <AccountVerificationDocumentSelectionTwoSided documentUpload={documentUpload} />
            ) : (
                <AccountVerificationDocumentSelectionOneSided documentUpload={documentUpload} />
            )}
            <AccountVerificationProofOfIdFooter />
        </>
    );
}
