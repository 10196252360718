import React, { useEffect } from 'react';
import { translate } from '../../../../services/translate';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { stores } from '../../../../stores';
import { getStoreValue } from '../../../../stores/store/utils';
import isEmpty from 'lodash/isEmpty';
import { useStore } from '../../../../hooks/useStore';

export default function SportBetslipKeepSelections() {
    const [keptBetslipSelections, setKeptBetslipSelections] = useStore(stores.sports.keptBetslipSelections);
    const [betSlipMarketIdToOutcomeId, setBetSlipMarketIdToOutcomeId] = useStore(
        stores.sports.betSlipMarketIdToOutcomeId,
    );
    const [{ isLoading, receiptById }] = useStore(stores.sports.betSlipPlacingState);

    useEffect(() => {
        return () => setKeptSelections();
    }, []);

    function onTick(value) {
        setKeptBetslipSelections(value ? betSlipMarketIdToOutcomeId : null);
    }

    useEffect(() => {
        if (isEmpty(betSlipMarketIdToOutcomeId) || isEmpty(keptBetslipSelections)) {
            return;
        }
        setKeptBetslipSelections(betSlipMarketIdToOutcomeId);
    }, [betSlipMarketIdToOutcomeId]);

    useEffect(() => {
        setKeptSelections();
    }, [receiptById]);

    function setKeptSelections() {
        const keptSelections = getStoreValue(stores.sports.keptBetslipSelections);
        if (!isEmpty(receiptById) || !keptSelections) {
            return;
        }
        setBetSlipMarketIdToOutcomeId(keptSelections);
    }

    if (isEmpty(betSlipMarketIdToOutcomeId)) {
        return null;
    }

    return (
        <UiFormInput
            name="keepMySelections"
            className="keep-my-selections"
            checkbox
            disabled={isLoading}
            label={translate('Keep my selections', 'ui.sportsbook')}
            onValueChange={onTick}
            value={Boolean(keptBetslipSelections)}
        />
    );
}
