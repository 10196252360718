import { hasCamera } from './camera';
import { CamsStep, UserStatus } from './context';
import { getStoreValue } from '../../stores/store/utils';
import { cams } from '../../stores/cams';
import { storageGet, storageRemove, storageSet } from '../storage';
import { camsEnrollConsentKey, camsSessionTokenSorageKey, camsUserIdStorageKey } from './constants';
import { isMobile } from '../browser';

async function getNextStep(currentsStep: CamsStep): Promise<CamsStep> {
    const { portrait, skippedStep, enrollConsent } = getStoreValue(cams);

    return {
        [CamsStep.details]: (await hasCamera()) && isMobile() ? CamsStep.enroll : CamsStep.switchDevice,
        [CamsStep.enroll]: (await hasCamera())
            ? portrait || skippedStep === CamsStep.enroll || enrollConsent === false // if no portrait we need to enroll again as we later need to use the value
                ? CamsStep.documentFront
                : CamsStep.enroll
            : CamsStep.switchDevice,
        [CamsStep.documentFront]: CamsStep.documentBack,
        [CamsStep.documentBack]: isNeedsToReturnToDesktop() ? CamsStep.switchDevice : CamsStep.missingData,
    }[currentsStep];
}

export const goToNextStep = async (currentStepOverride?: CamsStep) => {
    const { currentStep } = getStoreValue(cams);
    const nextStep = await getNextStep(currentStepOverride || currentStep);
    return cams.set(({ currentStep, ...rest }) => ({
        ...rest,
        currentStep: nextStep,
    }));
};

export async function getStepFromUserStatus(userStatus: UserStatus) {
    const flowFromStatus: {
        field: keyof UserStatus;
        fn?: (x: UserStatus) => boolean;
        nextStep: CamsStep;
    }[] = [
        {
            field: 'orchestrationStatus',
            fn: (x) => x.userApproved,
            nextStep: CamsStep.missingData,
        },
        {
            field: 'userScanId',
            nextStep: isNeedsToReturnToDesktop() ? CamsStep.switchDevice : await getNextStep(CamsStep.documentBack),
        },
        { field: 'userBiometric', fn: (x) => x.userBiometric === 'face', nextStep: await getNextStep(CamsStep.enroll) },
        { field: 'userRegistered', nextStep: await getNextStep(CamsStep.details) },
    ];
    return flowFromStatus.find?.(({ field, fn }) => userStatus[field] && (!fn || fn?.(userStatus)))?.nextStep;
}

export const goToCamsStep = (step: CamsStep) => cams.set((state) => ({ ...state, currentStep: step }));

export const isNeedsToReturnToDesktop = () => !storageGet(camsSessionTokenSorageKey);

export const setCamsIds = (
    newToken: string,
    userId: string,
    isInactivityCheck: boolean = false,
    inPerson?: boolean,
    socialSecurityNumber?: string,
) => {
    cams.set((s) => ({ ...s, transferToken: newToken, userId, isInactivityCheck, inPerson, socialSecurityNumber }));
    storageSet(camsUserIdStorageKey, userId);
    storageSet(camsSessionTokenSorageKey, newToken);
};

export const cleanUpCamsStorage = () => {
    storageRemove(camsUserIdStorageKey);
    storageRemove(camsSessionTokenSorageKey);
    storageRemove(camsEnrollConsentKey);
};
