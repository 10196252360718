import { isDevelopment, isLocalDevelopment } from './environment';

enum LoggerError {
    DEV = 'DEV',
    ERROR = 'ERROR',
    INFO = 'INFO',
    LOG = 'LOG',
}

const css = {
    [LoggerError.DEV]: `background-color: #ccc; color: black;`,
    [LoggerError.ERROR]: `background-color: red; color: white;`,
    [LoggerError.INFO]: `background-color: lightblue; color: white;`,
};

function reportError() {
    // TODO: Set up custom error reporting
    // const stringifiedData = (data || []).map(segment => JSON.stringify(segment, null, 2));
    // Raven.captureMessage(`${className} - ${methodName} - ${stringifiedData.join(' - ')}`);
}

function log(type, className, methodName, ...rest) {
    if (type === LoggerError.ERROR) {
        reportError();
    }

    if (!isDevelopment() && !isLocalDevelopment() && !window.loggerEnable) {
        return;
    }

    console.log(`%c ${type} `, css[type], className, methodName, ...rest);
}

function getRest(rest) {
    return rest.length ? [...rest] : [];
}

export const logger = {
    dev: (className: string, methodName: string, error?) => {
        log(LoggerError.DEV, className, methodName, error);
    },
    info: (className: string, methodName: string, error) => {
        log(LoggerError.INFO, className, methodName, error);
    },
    error: (className: string, methodName: string, error) => {
        log(LoggerError.ERROR, className, methodName, error);
    },
    log: (...rest) => {
        log(LoggerError.LOG, null, null, ...getRest(rest));
    },
};
