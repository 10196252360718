import styled from 'styled-components';

export default styled.div`
    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 ${({ theme }) => theme.layout.gutter};
        height: ${({ theme }) => theme.header.height};

        > * {
            flex: 1;
        }
    }

    .warning {
        text-align: center;
        background-color: ${({ theme }) => theme.colors.warning};
        color: ${({ theme }) => theme.colors.white};
        height: 30px;

        h3 {
            flex-grow: 1;
        }

        button {
            flex-grow: 0;
            background-color: ${({ theme }) => theme.colors.warning};
            color: ${({ theme }) => theme.colors.white};
            border: 0px;
            cursor: pointer;
        }
    }
`;
