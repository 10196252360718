import styled from 'styled-components';

export default styled.div`
    form div p {
        color: var(--font-color-secondary);
        font-size: 0.875rem;
    }
    .pseudo-label {
        text-transform: none;
        letter-spacing: 0;
        font-size: 0.875rem;
    }
    .radio-block {
        display: block;
        margin-bottom: 1rem;
    }
    .third-party-determination {
        display: flex;
        flex-direction: column;
        gap: ${({ theme }) => theme.layout.gutter};
    }
    .pep-relationship-label {
        margin-top: 0.3rem;
    }
`;
