import styled, { css } from 'styled-components';

type StyledProps = {
    active?: boolean;
    oddsTooBig?: boolean;
    compact?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 65px;

    color: var(--odds-text-color);
    font-weight: var(--odds-text-weight);
    ${({ theme }) => theme.optimizeFontRendering};
    letter-spacing: 1px;

    background-color: var(--odds-bg);
    border: var(--odds-border);
    border-radius: var(--odds-radius);

    padding: 0.4rem;

    ${({ active }) =>
        active &&
        css`
            color: var(--odds-text-active);
            border: var(--odds-border-active);
            background-color: var(--odds-bg-active);
        `}
    ${({ oddsTooBig }) =>
        oddsTooBig &&
        css`
            font-size: 0.875rem;
        `}
    ${({ compact }) =>
        compact &&
        css`
            min-width: auto;
            height: auto;
            font-size: 0.8rem;
            padding: 0.2rem 0.4rem;

            color: var(--odds-text-color);
            border: var(--odds-border);
            background-color: var(--odds-bg);
        `}
`;
