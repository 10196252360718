import React, { useEffect, useState } from 'react';
import { translate } from '../../../../../../services/translate';

interface Props {
    document: File;
}

export default function AccountVerificationDocumentPreviewItem({ document }: Props) {
    const [data, setData] = useState('');

    useEffect(() => {
        setData(URL.createObjectURL(document));
        return () => URL.revokeObjectURL(data);
    }, []);

    return (
        <object width="100%" height="100%" style={{ objectFit: 'contain' }} data={data} type={document.type}>
            {document.type === 'application/pdf' && (
                <p>
                    {translate('Seems like your browser does not support displaying pdf files', 'ui.common')}
                    <br />
                    <br />
                    <a href={data} target="_blank" rel="noopener noreferrer">
                        {translate('View the document', 'ui.common')}
                    </a>
                </p>
            )}
        </object>
    );
}
