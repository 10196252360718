import AccountVerificationProofOfPaymentSteps0 from './0/AccountVerificationProofOfPaymentSteps0';
import AccountVerificationProofOfPaymentSteps1 from './1/AccountVerificationProofOfPaymentSteps1';
import AccountVerificationProofOfPaymentSteps2 from './2/AccountVerificationProofOfPaymentSteps2';
import AccountVerificationProofOfPaymentSteps3 from './3/AccountVerificationProofOfPaymentSteps3';
import AccountVerificationProofOfPaymentSteps4 from './4/AccountVerificationProofOfPaymentSteps4';

export default [
    AccountVerificationProofOfPaymentSteps0,
    AccountVerificationProofOfPaymentSteps1,
    AccountVerificationProofOfPaymentSteps2,
    AccountVerificationProofOfPaymentSteps3,
    AccountVerificationProofOfPaymentSteps4,
];
