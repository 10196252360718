// used in skeleton loader, need to pass in id for react key uniqueness
import { LiveTreeCategoryFo } from '@staycool/sbgate-types/dist/categoryTree/types';
import { MATCH_STATUS } from './constants';
import { FakeMarket, MarketTypeCategory, SidebetMarketsResponse } from './types';

function fakeMatch(id) {
    return {
        sport: 0,
        isFake: true,
        id,
        status: MATCH_STATUS.OPEN,
    } as any;
}

export function makeFakeMatches(howMany) {
    return [...Array(howMany)].map((_, index) => fakeMatch(index));
}

export const fakeMatchesCollection = makeFakeMatches(7);

const fakeMarketType = (id) => ({ id } as MarketTypeCategory);

export const fakeTopMarketTypesWithMarket = [...Array(3)].map((_, index) => fakeMarketType(-1 * index));
const fakeSection = (id) =>
    ({
        matches: makeFakeMatches(2),
        league_category_id: id,
        sectionName: '',
    } as unknown as LiveTreeCategoryFo['sections'][0]);
export const fakeLiveTree: LiveTreeCategoryFo[] = [
    {
        sport_icon: null,
        sport_category_id: 0,
        sportName: '',
        sport_id: -1,
        sections: [fakeSection(-1), fakeSection(-2), fakeSection(-3)],
    },
];
export const fakeMarket = { outcomes: [0, -1, -2], id: -1 };
const fakeMarketAmerican = { outcomes: [0, -1], id: -1 };
export const getFakeMarket = (isAmericanLayout): FakeMarket => (isAmericanLayout ? fakeMarketAmerican : fakeMarket);

export const fakeSidebets = {
    match: {},
    markets: [...Array(3)].map((_, index) => ({
        market_type_id: -1 * index,
        result_keys: ['[Home]', '[Away]'],
        markets: [{ id: -1, outcomes: [{ id: -1 }, { id: -1 }] }],
    })),
} as unknown as SidebetMarketsResponse;
