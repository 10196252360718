import React, { ReactNode, useEffect } from 'react';
import { getRoute, isActiveRoute } from '../../services/router';

interface Props {
    children: ReactNode;
}

export default function ScrollToTop({ children }: Props) {
    useEffect(() => {
        if (isActiveRoute(getRoute('sport'), false)) {
            const bodyRect = document.body.getBoundingClientRect();
            const elementForScrollRect = document.querySelector('.main-content')?.getBoundingClientRect();

            if (elementForScrollRect && elementForScrollRect.top < 0) {
                const offset = elementForScrollRect.top - bodyRect.top;

                window.scroll({
                    top: offset,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        } else {
            try {
                //avoid mozilla scroll bug
                setTimeout(() => {
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    });
                }, 5);
            } catch (e) {
                document.documentElement.scrollTop = 0;
                document.body.scrollTop = 0;
            }
        }
    }, [window.location.pathname]);

    return <>{children}</>;
}
