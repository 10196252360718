import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { DocumentCaptureContext } from '../../../../../contexts/document-capture/DocumentCaptureContext';
import AccountVerificationDocumentCaptureQrCode from '../qr-code/AccountVerificationDocumentCaptureQrCode';
import AccountVerificationDocumentCapturePreScreenDesktop from './desktop/AccountVerificationDocumentCapturePreScreenDesktop';
import AccountVerificationDocumentCapturePreScreenMobile from './mobile/AccountVerificationDocumentCapturePreScreenMobile';
import Wrapper from './styles';

export default function AccountVerificationDocumentCapturePreScreen() {
    const isMobileFlow = new URLSearchParams(useLocation().search).get('isMobileFlow') === 'true';
    const { isQrOpen, isDesktop, setIsQrOpen, setIsLoading, setIsPreScreenOpen } = useContext(DocumentCaptureContext);

    function onBackToGuide() {
        setIsQrOpen(false);
        setIsLoading(true);
    }

    if (!isDesktop && isMobileFlow) {
        setIsPreScreenOpen(false);
        return <></>;
    }

    return (
        <Wrapper vertical>
            {!isQrOpen &&
                (isDesktop ? (
                    <AccountVerificationDocumentCapturePreScreenDesktop />
                ) : (
                    <AccountVerificationDocumentCapturePreScreenMobile />
                ))}
            {isQrOpen && isDesktop && <AccountVerificationDocumentCaptureQrCode onBackToGuide={onBackToGuide} />}
        </Wrapper>
    );
}
