import React from 'react';
import Wrapper from './styles';
import { getClient, isRetail } from '../../services/environment';
import { handleImageError } from '../../services/retail';

interface Props {
    size?: number;
    compact?: boolean;
    isDigitalBoard?: boolean;
}

export default function Logo({ size, compact, isDigitalBoard }: Props) {
    // TODO: use theme to pick the logo?
    const client = getClient();

    return (
        <Wrapper size={size} isDigitalBoard={isDigitalBoard} className="brand-logotype">
            {compact ? (
                <img
                    src={`${window.coolb2b?.baseUrl || ''}/assets/images/logo/${client}-compact.svg`}
                    alt={isRetail() ? undefined : client}
                    onError={handleImageError}
                />
            ) : (
                <img
                    src={`${window.coolb2b?.baseUrl || ''}/assets/images/logo/${client}.svg`}
                    alt={isRetail() ? undefined : client}
                    onError={handleImageError}
                />
            )}
        </Wrapper>
    );
}
