import styled from 'styled-components';
import UiBox from '../../ui/box/UiBox';

export default styled(UiBox)`
    width: 1200px;
    max-height: 90vh;
    overflow-x: scroll;
    @media screen and (max-width: 600px) {
        width: 100%;
    }

    .items {
        display: grid;
    }

    .row {
        display: flex;
    }

    .column {
        flex: 50%;
    }

    @media screen and (max-width: 600px) {
        .column {
            width: 100%;
        }
        .row {
            flex-direction: column;
        }
        .errors {
            order: 2;
        }
    }
`;
