import React from 'react';
import { stores } from '../../../stores';
import { mapValues } from 'lodash/object';
import { useStore } from '../../../hooks/useStore';

export const withCloseModal = (Component, modalName) => (props) => {
    const { children, onClick = () => {} } = props;

    const [modals, setModals] = useStore(stores.cms.modals);

    function updatedOnClick(event) {
        onClick(event);

        if (modalName && modalName.length > 0) {
            setModals({
                ...modals,
                [modalName]: false,
            });
        } else {
            setModals(mapValues(modals, () => false));
        }
    }
    return (
        <Component {...props} onClick={updatedOnClick}>
            {children}
        </Component>
    );
};
