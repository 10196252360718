import React from 'react';
import { UniqueSelection } from '../../../../services/sports/types';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import Wrapper from './styles';

interface Props {
    matchInfo: UniqueSelection;
}

export default function SportTicketMatchScore({ matchInfo }: Props) {
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId: matchInfo.sport_category_id });

    return (
        <Wrapper>
            <div className="match-result">
                <span className="score">{isAmericanLayout ? matchInfo.score_away : matchInfo.score_home}</span>-
                <span className="score">{isAmericanLayout ? matchInfo.score_home : matchInfo.score_away}</span>
            </div>
        </Wrapper>
    );
}
