import styled from 'styled-components';
import { SaddleColors, defaultSaddleTowelColor } from '../../../../services/horse-racing/horse-racing';

type StyledProps = {
    saddleColor: SaddleColors;
};

export default styled.div<StyledProps>`
    .number {
        width: 1.45rem;
        height: 1.45rem;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: var(--font-12);
        line-height: 0;
        user-select: none;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}

        border: 1px solid;
        border-color: ${(props) => props.saddleColor.borderColor || defaultSaddleTowelColor.borderColor};
        color: ${(props) => (props.saddleColor?.textColor ? props.saddleColor.textColor : '#FFFFFF')};
        text-shadow: ${(props) => (props.saddleColor?.textShadow ? props.saddleColor.textShadow : 'none')};
        background: ${(props) => props.saddleColor.bg || defaultSaddleTowelColor.bg};
    }

    .post-position {
        font-size: var(--font-10);
    }
`;
