import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import UiTabsItem from '../../../ui/tabs/item/UiTabsItem';
import UiTabs from '../../../ui/tabs/UiTabs';

interface Props {
    numberOfCards: number;
    clearSelections: (id?: number) => void;
}

export default function ComboCardsBetslipHeader({ numberOfCards, clearSelections }: Props) {
    return (
        <Wrapper>
            <div className="betslip-header">
                <div className="header-title">
                    <div className="cards-count">{numberOfCards}</div>
                    {translate('Betslip', 'ui.sportsbook')}
                </div>
                <span className="clear-all" onClick={() => clearSelections()}>
                    {translate('Clear all', 'ui.sportsbook')}
                </span>
            </div>
            <UiTabs>
                <UiTabsItem selected={true} size="small" dataTest="combo-card">
                    {translate('Combo card', 'ui.sportsbook')}
                </UiTabsItem>
            </UiTabs>
        </Wrapper>
    );
}
