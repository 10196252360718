import { isNaN } from 'lodash/lang';
import moment from 'moment';
import {
    subscribeToInplayMatchData,
    subscribeToLivebetMatchChanges,
    unsubscribeFromInplayMatchData,
    unsubscribeFromLivebetMatchChanges,
} from '../../microservices/pusher';
import { fetchMatchesLiveBet } from '../../microservices/sbgate';
import { stores, useStoreWithSelector } from '../../stores';
import { withUsageMonitoringById } from '../subscription';
import { translate } from '../translate';
import { fetchInPlayData } from '../../microservices/sports';
import { timedQueue } from './general';
import { useEffect } from 'react';
import { BG_GAME_SPORTS, BG_SETS_SPORTS, SPORT_ID } from './constants';
import { setGroupedCategoriesResponseToStore } from './sport-category';
import { FoCategoryMatch } from '@staycool/sbgate-types';
import { getStoreValue } from '../../stores/store/utils';
import { media } from '../../stores/media/media';

const MATCH_INPLAY_PHASE = {
    PRE_MATCH: 'PreMatch',
    POST_MATCH: 'PostMatch',
    HALF_TIME: 'HalfTime',
    FIRST_HALF: 'FirstHalf',
    SECOND_HALF: 'SecondHalf',
    EXTRA_TIME_FIRST_HALF: 'ExtraTimeFirstHalf',
    EXTRA_TIME_SECOND_HALF: 'ExtraTimeSecondHalf',
};

function cleanInplayMatchScoresStore(unusedIds, usageById) {
    const unusedIdsAfterDelay = unusedIds.filter((id) => (usageById[id] || 0) <= 0);
    if (!unusedIdsAfterDelay.length) {
        return;
    }
    stores.sports.inplayMatchData.set((state) => {
        unusedIdsAfterDelay.forEach((id) => delete state[id]);
    });
}

export const {
    subscribeWithMonitor: subscribeToInplayMatchChanges,
    unsubscribeWithMonitor: unsubscribeFromInplayMatchChanges,
} = withUsageMonitoringById(subscribeToLivebetMatchChanges, unsubscribeFromLivebetMatchChanges, 60);

const { subscribeWithMonitor: subscribeToInplayScoresById, unsubscribeWithMonitor: unsubscribeFromInplayScoresById } =
    withUsageMonitoringById(subscribeToInplayMatchData, unsubscribeFromInplayMatchData, 60, (unusedIds, usageById) => {
        setTimeout(() => {
            cleanInplayMatchScoresStore(unusedIds, usageById);
        }, 20000);
    });

export function useInplayScores(matchId: number) {
    useEffect(() => {
        if (!matchId) {
            return;
        }
        updateInplayData(matchId);
        subscribeToInplayScoresById(matchId);
        return () => unsubscribeFromInplayScoresById(matchId);
    }, [matchId]);
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[matchId], [matchId]);
    return inplayData;
}

export function getLiveBetgeniusMatchStats(matchInplayData) {
    const currentMatchState = matchInplayData.status || matchInplayData.current_phase;
    const translatedMatchState = getTranslatedMatchPhase(currentMatchState);

    let matchPhaseName;
    let matchPhaseStartTime;
    let previousMatchPhasesCumulativeRunTimeInMinutes;

    switch (currentMatchState) {
        case MATCH_INPLAY_PHASE.PRE_MATCH: {
            matchPhaseName = translatedMatchState;
            break;
        }
        case MATCH_INPLAY_PHASE.POST_MATCH: {
            matchPhaseName = 'FT';
            break;
        }
        case MATCH_INPLAY_PHASE.HALF_TIME: {
            matchPhaseName = 'HT';
            break;
        }
        case MATCH_INPLAY_PHASE.FIRST_HALF: {
            matchPhaseName = translatedMatchState;
            matchPhaseStartTime = matchInplayData.custom.start_times?.first_half;
            previousMatchPhasesCumulativeRunTimeInMinutes = 0;
            break;
        }
        case MATCH_INPLAY_PHASE.SECOND_HALF: {
            matchPhaseName = translatedMatchState;
            matchPhaseStartTime = matchInplayData.custom.start_times?.second_half;
            previousMatchPhasesCumulativeRunTimeInMinutes = 45;
            break;
        }
        case MATCH_INPLAY_PHASE.EXTRA_TIME_FIRST_HALF: {
            matchPhaseName = translatedMatchState;
            matchPhaseStartTime = matchInplayData.custom.start_times?.extra_time_first_half;
            previousMatchPhasesCumulativeRunTimeInMinutes = 90;
            break;
        }
        case MATCH_INPLAY_PHASE.EXTRA_TIME_SECOND_HALF: {
            matchPhaseName = translatedMatchState;
            matchPhaseStartTime = matchInplayData.custom.start_times?.extra_time_second_half;
            previousMatchPhasesCumulativeRunTimeInMinutes = 105;
            break;
        }
        default:
            matchPhaseName = translatedMatchState;
    }

    const shouldDisplayMatchRunningTime =
        (matchPhaseStartTime !== undefined && previousMatchPhasesCumulativeRunTimeInMinutes !== undefined) ||
        (matchInplayData.custom && matchInplayData.custom.clock);

    if (shouldDisplayMatchRunningTime) {
        const cumulativeMatchRunTimeInMinutes = getMatchCumlativeRunTimeInMinutes(
            matchPhaseStartTime,
            previousMatchPhasesCumulativeRunTimeInMinutes,
        );

        return {
            matchPhaseName,
            cumulativeMatchRunTimeInMinutes,
        };
    }

    return { matchPhaseName };
}

function getTranslatedMatchPhase(matchCurrentPhase) {
    if (!matchCurrentPhase) {
        return '???';
    }

    const splitMatchCurrentPhase = matchCurrentPhase.split('<');

    if (splitMatchCurrentPhase.length === 2) {
        const translationKey = splitMatchCurrentPhase[0].trim();
        const translatedMatchState = translate(translationKey, 'ui.sportsbook.lb');
        return `${translatedMatchState}${splitMatchCurrentPhase[1]}`;
    }
    return translate(matchCurrentPhase, 'ui.sportsbook.lb');
}

function getMatchCumlativeRunTimeInMinutes(
    matchCurrentPhaseStartTime,
    previousMatchPhasesCumulativeRunTimeInMinutes = 0,
) {
    if (matchCurrentPhaseStartTime) {
        const currentTime = moment();
        const matchCurrentPhaseStartMoment = moment(matchCurrentPhaseStartTime);
        const matchRunTimeFromCurrentPhaseStart = currentTime.diff(matchCurrentPhaseStartMoment, 'minutes');
        const cumulativeMatchRunTimeInMinutes =
            matchRunTimeFromCurrentPhaseStart + previousMatchPhasesCumulativeRunTimeInMinutes + 1;
        return `${cumulativeMatchRunTimeInMinutes}'`;
    }
}

export function getInhouseLivebetMatchStats(matchInplayData, sportId) {
    let matchPhaseName;
    const hasScoreboard = Object.keys(matchInplayData.scoreboard).length;
    if (matchInplayData.current_phase && hasScoreboard) {
        const phaseNumber = parseInt(matchInplayData.current_phase);
        const isOverTimeRound = Boolean(matchInplayData.scoreboard[phaseNumber]?.name?.toString().includes('OT'));
        if (isOverTimeRound) {
            const rounds: { name?: string }[] = Object.values(matchInplayData.scoreboard);
            const overTimeNumber = rounds[phaseNumber]?.name?.match(/\d+/g);
            matchPhaseName = `${getTranslatedMatchPhase('overtime')} ${overTimeNumber} `;
        } else {
            matchPhaseName = !isNaN(phaseNumber)
                ? `${getTranslatedMatchPhase(`round-name-${sportId}`)} ${phaseNumber + 1}`
                : `${getTranslatedMatchPhase(`${matchInplayData.current_phase}`)}`;
        }
    } else {
        matchPhaseName = `${getTranslatedMatchPhase('Started')}`;
    }
    if (!matchInplayData.custom || !matchInplayData.custom.clock) {
        return { matchPhaseName };
    }
    const currentTime = moment(matchInplayData.custom.clock.paused || undefined);
    const matchCurrentPhaseStartMoment = moment(matchInplayData.custom.clock.start);
    let cumulativeMatchRunTime = currentTime.diff(matchCurrentPhaseStartMoment);
    if (matchInplayData.custom.clock.reverse) {
        cumulativeMatchRunTime *= -1;
    }
    const cumulativeMatchRunTimeMinutesSeconds = moment(cumulativeMatchRunTime).format('mm:ss');
    return { matchPhaseName, cumulativeMatchRunTimeInMinutes: cumulativeMatchRunTimeMinutesSeconds };
}

function getCurrentTimeInSeconds(lastUpdatedTime, timeRemaining, isClockRunning) {
    let currentTimeInSeconds = parseTimerTime(timeRemaining);
    if (isClockRunning) {
        const now = moment();
        const secondsFromLastUpdate = Math.ceil(moment.duration(now.diff(lastUpdatedTime)).asSeconds());
        currentTimeInSeconds -= secondsFromLastUpdate;
    }
    return Math.max(currentTimeInSeconds, 0);
}

export function getMatchStats(matchInplayData, currentSavedTimeString, currentSavedTimeSeconds, lastUpdatedTime) {
    const { custom } = matchInplayData;
    const translatedMatchState = getTranslatedMatchPhase(custom.current_phase || matchInplayData.current_phase);
    const { is_clock_running, time_remaining } = custom;
    const updateTime = moment(custom.time_remaining_updated_at);

    if (!currentSavedTimeString || moment(lastUpdatedTime) < updateTime) {
        currentSavedTimeSeconds = getCurrentTimeInSeconds(updateTime, time_remaining, is_clock_running);
        currentSavedTimeString = secondsToMinutes(currentSavedTimeSeconds);
        lastUpdatedTime = updateTime.toISOString();
    }
    if (is_clock_running) {
        currentSavedTimeSeconds -= 1;
        currentSavedTimeSeconds = currentSavedTimeSeconds < 0 ? 0 : currentSavedTimeSeconds;
        currentSavedTimeString = secondsToMinutes(currentSavedTimeSeconds);
    }

    return {
        matchPhaseName: translatedMatchState,
        cumulativeMatchRunTimeInMinutes: currentSavedTimeString,
        currentSavedTimeSeconds,
        lastUpdatedTime,
    };
}

function parseTimerTime(timeRemaining: string) {
    if (!timeRemaining) {
        return 0;
    }
    // PT14M47S, PT1S, PT13M
    const ptRegex = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
    const matches = timeRemaining.match(ptRegex);
    if (!matches) {
        return 0;
    }
    const [, hours, minutes, seconds] = matches;
    return Number(hours || 0) * 60 * 60 + Number(minutes || 0) * 60 + Number(seconds || 0);
}

function secondsToMinutes(remainingInPhase) {
    const seconds = formatNumber(Math.floor(remainingInPhase % 60));
    const minutes = formatNumber(Math.floor((remainingInPhase / 60) % 60));
    return `${minutes}:${seconds}`;
}

function formatNumber(number) {
    return String(number).padStart(2, '0');
}

export function checkInplayDataAndSubscribe(matchId) {
    const inplayMatchData = getStoreValue(stores.sports.inplayMatchData)[matchId];
    if (!inplayMatchData) {
        subscribeToInplayScoresById(matchId);
        updateInplayData(matchId);
    }
}

const updateInplayData = timedQueue(updateInplayDataByMatchIds, 100);

async function updateInplayDataByMatchIds(matchIds: number[]) {
    const inplayData = await fetchInPlayData(matchIds);
    stores.sports.inplayMatchData.set((inplayDataByMatchId) => {
        for (const inplayMatch of inplayData) {
            inplayDataByMatchId[inplayMatch.match_id] = inplayMatch;
        }
    });
}

export async function updateMatchesLiveBet(page?: number) {
    const { isPhone } = getStoreValue(media);
    const language = getStoreValue(stores.language);
    const { categories, sportCategoryIds, hasMore } = await fetchMatchesLiveBet({
        isMobile: isPhone || undefined,
        language,
        page,
    });
    if (!categories) {
        return { categories, sportCategoryIds, leagueCategoryIds: [], isLast: true };
    }
    const leagueCategoryIds = categories.map(({ id }) => id);
    categories.flatMap(({ matches }) => matches);
    if (sportCategoryIds?.length) {
        stores.sports.liveNow.filterIds.set(sportCategoryIds);
    }
    setGroupedCategoriesResponseToStore(categories);
    if (page !== undefined) {
        stores.sports.matchesLiveBet.set((currentCategories) => {
            const updatedCategories = [...currentCategories];
            keepOpenMatchesInView(updatedCategories, categories);
            const firstCategoryId = leagueCategoryIds[0];
            const categoryToAmend = updatedCategories.find(({ id }) => id === firstCategoryId);
            if (categoryToAmend) {
                const newMatchesList = [...categoryToAmend.matches, ...categories[0].matches];
                categories[0].matches = newMatchesList;
                updatedCategories.pop();
            }
            return [...updatedCategories, ...categories];
        });
    } else {
        stores.sports.matchesLiveBet.set(categories);
    }

    return { categories, sportCategoryIds, leagueCategoryIds, isLast: !hasMore };
}

function keepOpenMatchesInView(oldState, newState) {
    const openMatchIds = getStoreValue(stores.sports.currentlyOpenMatchIds);

    if (openMatchIds.length) {
        openMatchIds.forEach((matchId) => {
            const findMatchFromSport = (sport) => sport.matches.find((match) => match.id === matchId);
            let matchSport = newState.find(findMatchFromSport);
            if (matchSport) {
                return;
            }
            matchSport = oldState.find(findMatchFromSport);
            if (!matchSport) {
                return;
            }
            const existingSport = newState.find((existingSport) => existingSport.id === matchSport.id);
            const match = matchSport.matches.find((match) => match.id === matchId);
            if (existingSport) {
                existingSport.matches.push(match);
                return;
            }
            newState.push({ ...matchSport, matches: [match] });
        });
    }
}

export function getScoreboardType(match: FoCategoryMatch) {
    let scoreboardType;
    if (match.scorecenter_available || match.scoreboard_url) {
        scoreboardType = 'betgenius';
    } else if (BG_SETS_SPORTS.includes(match.sport) || BG_GAME_SPORTS.includes(match.sport)) {
        scoreboardType = 'bgRounds';
    } else if (match.livebet_our) {
        scoreboardType = 'inhouse';
    } else if (match.sport === SPORT_ID.ICEHOCKEY) {
        scoreboardType = 'bgIcehockey';
    } else if (match.sport === SPORT_ID.AMERICAN_FOOTBALL) {
        scoreboardType = 'bgAmericanFootball';
    } else if (match.sport === SPORT_ID.DARTS) {
        scoreboardType = 'bgDarts';
    } else if (match.sport === SPORT_ID.FOOTBALL) {
        scoreboardType = 'bgFootball';
    } else if (match.sport === SPORT_ID.BASEBALL) {
        scoreboardType = 'bgBaseball';
    } else if (match.sport === SPORT_ID.TENNIS) {
        scoreboardType = 'bgTennis';
    } else if (match.sport === SPORT_ID.BASKETBALL) {
        scoreboardType = 'bgBasketball';
    } else if (match.scoreboard_available) {
        scoreboardType = 'betgenius';
    }
    return scoreboardType;
}
