import React from 'react';
import moment from 'moment';

export const withShowOnDays = (Component) => (props) => {
    const delimiter = ',';
    const format = 'dd';
    const showOnDays = props['show-on-days'] && props['show-on-days'].split(delimiter);

    const component = <Component {...props}>{props.children}</Component>;

    if (props['show-on-days'] !== undefined) {
        const today = moment().format(format);
        const showToday = showOnDays.includes(today);
        return showToday ? component : null;
    }

    return component;
};
