import React, { useMemo } from 'react';
import { getDateFilters } from '../../../../services/bet-history';
import { stores } from '../../../../stores';
import UiTabs from '../../../ui/tabs/UiTabs';
import UiTabsItem from '../../../ui/tabs/item/UiTabsItem';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    selectedFilter: string;
    onFilterSelected: (time: string) => void;
    className?: string;
}

export default function BetHistoryFiltersDate({ selectedFilter, onFilterSelected = () => {} }: Props) {
    const [language] = useStore(stores.language);
    const dateFilters = useMemo(() => getDateFilters(), [language]);

    return (
        <UiTabs>
            {dateFilters.map((filter) => (
                <UiTabsItem
                    size="small"
                    key={filter.label}
                    onClick={() => onFilterSelected(filter.time)}
                    selected={filter.time === selectedFilter}
                >
                    {filter.label}
                </UiTabsItem>
            ))}
        </UiTabs>
    );
}
