import styled from 'styled-components';
import UiModal from '../../../ui/modal/UiModal';

export default styled(UiModal)`
    h2 {
        padding: 0;
        margin-bottom: 1rem;
    }
    .login-button {
        margin: 0 auto;
    }
`;
