import styled from 'styled-components';

export default styled.div`
    margin-bottom: var(--spacing-8);
    .container {
        padding: var(--spacing-8);
        border: var(--button-active-border);
        border-radius: var(--ui-radius);
        background-color: var(--button-active-bg);
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .image-wrapper {
            background-color: var(--surface-level-2-bg);
            border: var(--surface-level-2-border);
            border-radius: var(--ui-radius);
            padding: var(--spacing-16);
        }
        p {
            font-size: 14px;
            padding: 0 var(--spacing-8);
            margin: 0;
        }
        a {
            color: var(--button-primary-text-color);
        }
    }
`;
