import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthLoader from '../components/auth/loader/AuthLoader';
import Maintenance from '../components/maintenance/Maintenance';
import { stores } from '../stores';
import { getLastActiveProductRoute, getRoute } from './router';
import { storageGet, storageRemove } from './storage';

export function checkLoginRequired(loginRequired, isRootNavigator, isAuthenticated) {
    const isLogoutInProgress = storageGet('isLogoutInProgress');
    if (isRootNavigator) {
        storageRemove('isLogoutInProgress');
    }

    if (loginRequired && !isAuthenticated) {
        if (isLogoutInProgress) {
            return <Redirect to={getLastActiveProductRoute()} />;
        }
        stores.modals.isLoginModalOpen.set(true);
        return <AuthLoader />;
    }
}

export function checkMaintenanceSportsbook(maintenanceState) {
    const { pathname } = window.location;
    if (!maintenanceState) {
        return null;
    }
    const isPreMatchPage = pathname.includes(getRoute('sport')) && !pathname.includes(getRoute('sport.live'));

    if (maintenanceState.preMatch && isPreMatchPage) {
        return <Maintenance endDate={maintenanceState.endDate} />;
    }
}
