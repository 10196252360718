import { getServiceUrl, httpGet } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { Currency } from '../services/wallet/types';

export const getUrl = (url) => getServiceUrl('cb-sharepic', url);

export function getImageToShareByTicketId(itemId, type) {
    const language = getStoreValue(stores.language);
    const user = getStoreValue(stores.user);
    const wallet = getStoreValue(stores.wallet);
    const urlParams: any = { itemId, itemUrl: type, language };

    if (wallet && wallet.currency !== Currency.EUR) {
        urlParams.currency = wallet.currency;
    }
    if (user) {
        urlParams.user = user.id;
    }
    const url = getUrl(`create-image`);
    return httpGet<{ id: string; url: string }>(url, urlParams);
}
