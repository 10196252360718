import { getServiceUrl, httpGet, httpPost } from '../services/api';

const getUrl = (url) => getServiceUrl('locks', url);

export function cancelBonusLock(bonus) {
    const url = getUrl(`lock/${bonus.lock.id}/cancel/`);
    return httpPost<any>(url);
}

export function getLockedAmount() {
    const url = getUrl('lock/');
    return httpGet<{ lockedAmount: number }>(url);
}
