import React, { useEffect } from 'react';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export const withModal = (Component, modalReference) => (props) => {
    const { children } = props;

    const [modals, setModals] = useStore(stores.cms.modals);

    useEffect(() => {
        setModals((modals) => ({
            ...modals,
            [modalReference]: false,
        }));
    }, []);

    if (modals[modalReference]) {
        return <Component {...props}>{children}</Component>;
    }
    return null;
};
