import styled from 'styled-components';

type StyledProps = {
    $isVisible?: boolean;
    $delay: number;
    $duration: number;
};

export default styled.div<StyledProps>`
    animation-delay: ${({ $delay }) => ($delay ? `${$delay}ms` : '')};
    animation-duration: ${({ $duration }) => ($duration ? `${$duration}ms` : '')};
    pointer-events: ${({ $isVisible }) => ($isVisible ? 'all' : 'none')};
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    transition: ${({ $delay }) => ($delay ? `opacity ${$delay}ms` : '')};
`;
