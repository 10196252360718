import styled from 'styled-components';

import { lighten } from 'polished';

export default styled.div`
    .coolbet-footer-license-content {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        padding: 0 5px 0 5px;
        max-width: ${({ theme }) => theme.layout.maxWidth};
        margin: 0 auto;

        .coolbet-footer-license-content-box {
            padding: 10px;
            display: flex;
            flex: 1 0 0;
            flex-wrap: nowrap;
            color: ${({ theme }) => lighten(0.05, theme.typography.fontColorSubtle)};
            align-items: stretch;
            min-width: 0;

            .coolbet-footer-license-content-box-icon-container {
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 0 10px;
            }

            .coolbet-footer-license-content-box-icon {
                height: 40px;
                width: 40px;
                flex-grow: 0;
                flex-shrink: 0;
                padding: 5px;
            }

            .coolbet-footer-license-content-box-text-container {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin-left: 15px;
                padding: 5px 0;
                justify-content: center;

                .coolbet-footer-license-content-box-text-title {
                    font-size: 0.85rem;
                    color: var(--font-color-secondary);
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: 1px;
                    padding: 1rem 0;
                }

                .coolbet-footer-license-content-box-text {
                    color: var(--font-color-subtle);
                    font-size: 0.85rem;
                    line-height: 1.2rem;

                    p {
                        padding: unset;
                        margin: unset;
                        display: inline-block;
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .coolbet-footer-license-content {
            flex-direction: column;
            padding: 0 0 var(--mobile-nav-height) 0;

            .coolbet-footer-license-content-box {
                flex: 1 0 auto;
                &:not(:last-child) {
                    border-right: unset;
                }
            }
        }
    }
`;
