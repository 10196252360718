import { useRef, useEffect } from 'react';
import difference from 'lodash/difference';

export function useNumberArrayEffect(cb: () => void | (() => void), deps: number[]) {
    const ref = useRef<number[]>(deps);

    if (difference(deps, ref.current).length || difference(ref.current, deps).length) {
        ref.current = deps;
    }

    useEffect(cb, [ref.current]);
}
