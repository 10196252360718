import React from 'react';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export const withForceLogin = (Component) => (props) => {
    const { onClick = () => {} } = props;

    const [isAuthenticated] = useStore(stores.isAuthenticated);

    function updatedOnClick(event) {
        onClick(event);

        if (!isAuthenticated) {
            stores.modals.isLoginModalOpen.set(true);
        }
    }

    return (
        <Component {...props} onClick={updatedOnClick}>
            {props.children}
        </Component>
    );
};
