import AccountVerificationProofOfAddressSteps0 from './0/AccountVerificationProofOfAddressSteps0';
import AccountVerificationProofOfAddressSteps1 from './1/AccountVerificationProofOfAddressSteps1';
import AccountVerificationProofOfAddressSteps2 from './2/AccountVerificationProofOfAddressSteps2';
import AccountVerificationProofOfAddressSteps3 from './3/AccountVerificationProofOfAddressSteps3';
import AccountVerificationProofOfAddressSteps4 from './4/AccountVerificationProofOfAddressSteps4';

export default [
    AccountVerificationProofOfAddressSteps0,
    AccountVerificationProofOfAddressSteps1,
    AccountVerificationProofOfAddressSteps2,
    AccountVerificationProofOfAddressSteps3,
    AccountVerificationProofOfAddressSteps4,
];
