import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    .campaign-bet {
        display: flex;
        align-items: center;
        padding: var(--spacing-12);
        border: var(--surface-level-2-border);
        background: var(--surface-level-2-bg);
        border-radius: var(--ui-radius);
        margin-top: var(--spacing-4);
        transition: box-shadow 200ms linear, border-color 200ms linear;

        &.selected {
            border: 1px solid var(--color-highlight);
            background: var(--surface-level-2-bg);

            &:hover {
                box-shadow: none;
            }
            .campaing-description {
                color: var(--color-highlight);
            }
        }

        &:hover {
            box-shadow: 0 0 5px var(--color-highlight);
            border-color: var(--color-highlight);
        }
        .campaign-icon {
            svg {
                color: var(--color-highlight);
                width: 21px;
                height: 21px;
            }
        }
        .campaing-description {
            font-size: 0.6rem;
            color: var(--font-color-primary);
            text-transform: uppercase;
            letter-spacing: 1px;
            padding: 0 0.6rem;
            flex: 1;
            @media ${mediaQuery.isPhone} {
                font-size: var(--font-12);
            }
        }
    }
`;
