import { getServiceUrl, httpGet, httpPost } from '../services/api';

const getUrl = (url) => getServiceUrl('recommendations', url);

export async function getRecommendedSideBet(matchId, sportId, betProduct) {
    return httpGet<number[]>(getUrl(`upsell/recommendation`), {
        matchId,
        sportId,
        bet_product: betProduct,
    });
}

export async function sendRecommendedSidebetStat(previousTicketId, recommendedMarketId) {
    const stat = {
        previous_ticket_id: previousTicketId,
        recommended_market_id: recommendedMarketId,
    };
    return httpPost<UpsellStat>(getUrl('upsell/stats'), stat);
}

type UpsellStat = {
    previous_ticket_id: string;
    recommended_market_id: string;
    rule_id_used: string;
    user_id: string;
};
