import styled from 'styled-components';

export default styled.div`
    .betslip-receipt {
        margin-top: var(--spacing-24);
    }
    p {
        font-size: 0.7rem;
        margin: 1rem 0;
        text-align: center;
    }
    .remove-receipt {
        margin-bottom: var(--spacing-8);
    }
`;
