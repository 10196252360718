import React from 'react';
import { useProofOfIdVerificationContext } from '../../../../../../contexts/proof-of-id-verification/ProofOfIdVerificationContext';
import AccountVerificationDocumentUpload from '../../../document/upload/AccountVerificationDocumentUpload';

export default function AccountVerificationProofOfIdSteps4() {
    const { documentUpload, reset, submissionState } = useProofOfIdVerificationContext();
    return (
        <AccountVerificationDocumentUpload
            documentUpload={documentUpload}
            extraData={{ expiryDate: submissionState.expiryDate }}
            onResetButtonClick={reset}
        />
    );
}
