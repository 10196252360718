import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';

interface Props {
    text: string;
}

export default function UiTextTruncate({ text }: Props) {
    function shouldTruncate(text: string): boolean {
        const TEXT_MAX_LENGTH = 105;
        return text.length >= TEXT_MAX_LENGTH;
    }

    return (
        <>
            <Wrapper $shouldTruncate={shouldTruncate(text)}>{text}</Wrapper>
            {shouldTruncate(text) && <span>{`${translate('Read more', 'ui.gdpr')} ›`}</span>}
        </>
    );
}
