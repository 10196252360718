import React from 'react';
import { getClient } from '../../services/environment';
import { translate } from '../../services/translate';
import UiButton from '../ui/button/UiButton';
import UiCloseButton from '../ui/close-button/UiCloseButton';
import Wrapper from './styles';
import { OPERATING_SYSTEM, getOS } from '../../services/device';
import Logo from '../logo/Logo';
import DangerousHtml from '../dangerous-html/DangerousHtml';

interface Props {
    onClose: () => void;
}

export default function PushToAppInstall({ onClose }: Props) {
    const client = getClient();

    return (
        <Wrapper>
            <UiCloseButton onClick={onClose} className="close-button" />
            <div className="image-wrapper">
                <Logo size={35} />
            </div>
            <h2>{translate('Download the APP', 'ui.common')}</h2>
            <p>
                {translate(
                    'To proceed with betting, please download the official APP and enjoy your betting experience',
                    'ui.common',
                )}
            </p>
            <UiButton color="primary" block>
                <DangerousHtml
                    content={translate(
                        `${client}.${getOS() === OPERATING_SYSTEM.ANDROID ? 'android' : 'ios'}.app.download`,
                    )}
                />
            </UiButton>
        </Wrapper>
    );
}
