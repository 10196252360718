import styled from 'styled-components';

export default styled.div`
    position: relative;
    width: 85px;
    margin: 1.6rem 0;
    .percentage {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        color: var(--font-color-primary);
        background: var(--surface-level-1-bg);
        z-index: 2;
        border-radius: 100px;
        justify-content: center;

        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}
    }
    circle {
        fill: var(--surface-level-2-bg);
        stroke-width: 50;
        stroke-dasharray: 25 158;
        transition: stroke-dasharray 0.3s ease;
    }

    svg {
        height: 85px;
        width: 85px;
        margin: 0 auto;
        transform: rotate(-90deg);
        background: var(--surface-level-2-bg);
        border: var(--surface-level-2-border);
        border-radius: 50%;
        display: block;
        box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    }
`;
