import styled from 'styled-components';

export default styled.div`
    .stake {
        display: flex;
        align-items: center;
        margin-top: 0.4rem;
        label {
            flex: 1;
            text-align: right;
            margin: 0 0.4rem 0 0;
            color: ${({ theme }) => theme.colors.secondary};
        }
        input {
            width: 70px;
            height: 30px;
            margin: 0;
            text-align: center;
            border-color: ${({ theme }) => theme.colors.secondary};
        }
    }
    .potential-return {
        font-size: 0.8rem;
        text-align: right;
        margin: 0.4rem;
        span {
            color: var(--color-highlight);
            letter-spacing: 1px;
            border-bottom: 1px dotted;
        }
    }
    .total-stake {
        font-size: 0.8rem;
        text-align: right;
        margin: 0.4rem;
        span {
            color: ${({ theme }) => theme.colors.secondary};
            letter-spacing: 1px;
            border-bottom: 1px dotted;
        }
    }
`;
