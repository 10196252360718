import styled from 'styled-components';
import UiModal from '../../../ui/modal/UiModal';
import { mediaQuery } from '../../../../styles/utils';

export default styled(UiModal)`
    .info-required-form {
        position: relative;
        min-width: 320px;
        overflow-y: auto;
        max-height: 100vh;
    }

    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: var(--surface-level-1-bg) url(/assets/images/turnover/coolbet-logo-45deg.png) repeat 0 -40px;
        background-size: 35%;
        max-width: -webkit-fill-available;

        .info-required-form {
            width: 340px;
            margin: 0 auto;
            padding: 1.5rem;
        }
    }

    @media ${mediaQuery.isPhone} {
        .info-required-form {
            max-height: calc(100vh - 160px);
        }
    }

    &:before {
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: #1b1b1fdb;
        }
    }

    .input-phone {
        flex: 1;
    }

    .essential-info-confirm button {
        height: 55px;
        font-size: 0.875rem;
        margin-top: 0.8rem;
        padding: 0px 2.5rem;
    }
`;
