import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Wrapper from './styles';

interface Props {
    height?: string;
    light?: boolean;
    width?: string;
}

export default function UiPlaceholder(props: Props) {
    return (
        <Wrapper>
            <Skeleton count={1} {...props} inline />
        </Wrapper>
    );
}
