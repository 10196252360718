import styled from 'styled-components';

export default styled.div`
    .cashout-button {
        background-color: var(--button-cashout-bg);
        border: var(--button-cashout-border);
        color: var(--button-cashout-text-color);
        box-shadow: none;
        &.up {
            background-color: ${({ theme }) => theme.colors.secondary};
        }
        &.down {
            background-color: ${({ theme }) => theme.colors.red};
        }
        &:hover {
            background-color: var(--button-cashout-bg-hover);
        }
    }
    .cashout-confirm {
        display: flex;
        align-items: center;
        justify-content: center;
        > *:not(:last-of-type) {
            margin-right: var(--spacing-4);
        }
    }
    .tooltip-box {
        display: flex;
        svg {
            margin-left: 0.5rem;
            width: 12px;
            color: var(--font-color-subtle);
        }
        .tooltip {
            max-width: 150px;
        }
    }
`;
