import styled from 'styled-components';

export default styled.div`
    :hover {
        svg {
            animation: jumping 1s cubic-bezier(0.4, 0, 1, 1) 3;
        }
    }

    .icon {
        color: var(--font-color-subtle);
        margin-right: 1rem;
        width: 70px;
    }

    .responsible-gaming-header {
        align-items: center;
        display: flex;
        margin-bottom: 1rem;

        p {
            margin: 0;
        }
    }

    .deposit-limit-form {
        form {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type='number'] {
            -moz-appearance: textfield;
        }
    }

    .input-container {
        padding: 24px 12px;
        h3 {
            margin: 0 0 1rem 0;
        }
    }
`;
