import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    text-align: right;
    position: sticky;
    background: var(--betslip-bg);
    top: 0;
    z-index: 2;
    button {
        padding: 0.4rem;
        flex: 1;
    }
    .betslip-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: var(--spacing-16) 0;
        border-bottom: 1px solid var(--surface-level-2-line-color);
    }
    .header-title {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}
        > *:first-of-type {
            margin-right: var(--spacing-8);
        }
        .bets-count {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 1.625rem;
            height: 1.625rem;

            border-radius: 100px;
            border: 2px solid var(--odds-text-color);

            font-size: var(--font-14);
            color: var(--odds-text-color);
        }
    }
    .bet-type-controls {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: auto;
        height: 3rem;
        margin-bottom: 0.4rem;
        &::-webkit-scrollbar {
            display: none;
        }
        > * {
            &:not(:last-of-type) {
                margin-right: var(--spacing-4);
                @media ${mediaQuery.isPhone} {
                    margin-right: 0.875rem;
                }
            }
        }
    }
    .clear-all {
        font-size: 0.75rem;
        color: var(--font-color-secondary);
        cursor: pointer;
        margin-left: auto;
    }
    .betslip-settings {
        margin-left: auto;
    }
    .betslip-header-toggle {
        padding: 0 0 var(--spacing-8) 0;

        * {
            font-size: 0.8rem;
        }
    }
`;
