import React, { ReactNode } from 'react';
import { useProofOfPaymentVerificationContext } from '../../../../../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import AccountVerificationMultiStepHeader from '../../multi-step/header/AccountVerificationMultiStepHeader';
import AccountVerificationProofOfPaymentDocument from '../document/AccountVerificationProofOfPaymentDocument';

interface Props {
    children: ReactNode;
}

export default function AccountVerificationProofOfPaymentHeader({ children }: Props) {
    const { selectedDocument, selectingSide, activeStep } = useProofOfPaymentVerificationContext();
    return (
        <AccountVerificationMultiStepHeader>
            {selectedDocument && (
                <AccountVerificationProofOfPaymentDocument
                    document={selectedDocument}
                    displayDeclineReasonOnlyForSide={activeStep === 2 ? selectingSide : undefined}
                />
            )}
            {children}
        </AccountVerificationMultiStepHeader>
    );
}
