import { getServiceUrl, httpGet, httpPost } from '../services/api';

const getUrl = (url) => getServiceUrl('sb-favorites', url);

export async function loadUserFavorites() {
    const url = getUrl('favorites');
    return httpGet<{ categories?: number[]; matches?: number[] }>(url);
}

export async function updateUserFavorites(userFavorites) {
    const url = getUrl('favorites');
    return httpPost<{ message: string }>(url, { userFavorites });
}
