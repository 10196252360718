import React from 'react';
import { translate } from '../../../services/translate';
import UiAlert from '../../ui/alert/UiAlert';
import { useRouter } from '../../../services/router';
import UiButton from '../../ui/button/UiButton';

interface Props {
    message?: string;
    onBack?: () => void;
}
export default function PaymentProviderNotAvailable({ message, onBack }: Props) {
    const { goBack } = useRouter();
    return (
        <>
            <UiAlert failure>{translate(message || 'Provider not available.', 'ui.payments')}</UiAlert>
            <UiButton onClick={onBack || goBack} block>
                {translate('Back', 'ui.common')}
            </UiButton>
        </>
    );
}
