import styled from 'styled-components';

export default styled.div`
    .line-separator {
        font-size: 0.6rem;
        margin: 1rem 0;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .line-separator:before,
    .line-separator:after {
        background-color: var(--font-color-faded);
        content: '';
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 10%;
    }
    .line-separator:before {
        right: 1em;
        margin-left: -50%;
    }
    .line-separator:after {
        left: 1em;
        margin-right: -50%;
    }
`;
