import { useEffect } from 'react';
import { timedQueue } from './sports/general';

export function useSubscription(subscription, callback, args?) {
    useEffect(() => {
        const subscription$ = subscription.unsubscribe ? subscription : subscription.subscribe(callback, args);
        return () => subscription$.unsubscribe();
    }, [subscription]);
}

export function withUsageMonitoringById(subscriptionFn, unsubscribeFn, delayUseBatching?, cleanupCallbackFn?) {
    const instanceCountsById = {};
    const batchedSubscribe = delayUseBatching ? timedQueue(subscriptionFn, delayUseBatching) : subscriptionFn;
    const batchedUnsubscribe = delayUseBatching
        ? timedQueue((objectIds) => {
              const objectIdsNotUsedAfterDelay = objectIds.filter((objId) => instanceCountsById[objId] <= 0);
              if (!objectIdsNotUsedAfterDelay.length) {
                  return;
              }
              unsubscribeFn(objectIdsNotUsedAfterDelay);
              objectIdsNotUsedAfterDelay.forEach((objectId) => delete instanceCountsById[objectId]);
              if (cleanupCallbackFn) {
                  cleanupCallbackFn(objectIdsNotUsedAfterDelay, instanceCountsById);
              }
          })
        : unsubscribeFn;

    const subscribeWithMonitor = (objectId: number | string) => {
        if (!objectId) {
            return;
        }
        const subscriptionCount: number = instanceCountsById[objectId];
        instanceCountsById[objectId] = (subscriptionCount || 0) + 1;
        if (subscriptionCount > 0) {
            return;
        }
        batchedSubscribe(objectId);
    };
    const unsubscribeWithMonitor = (objectId: number | string) => {
        if (!objectId || !instanceCountsById[objectId]) {
            return;
        }
        instanceCountsById[objectId]--;
        if (instanceCountsById[objectId] > 0) {
            return;
        }
        batchedUnsubscribe(objectId);
    };
    return { subscribeWithMonitor, unsubscribeWithMonitor, instanceCountsById };
}
