import styled from 'styled-components';

export default styled.div`
    .banner-header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;

        .banner-header {
            font-size: 0.625rem;
            color: var(--font-color-secondary);
            font-weight: var(--font-weight-bold);
            letter-spacing: 1px;
            padding: var(--spacing-12);
            ${({ theme }) => theme.optimizeFontRendering};
        }
    }
    .banner-image {
        border-radius: 10px;
        display: block;
    }
`;
