import React from 'react';

export const withScrollToId = (Component) => (props) => {
    const { children, onClick = () => {} } = props;

    function updatedOnClick(event) {
        onClick(event);

        const scrollToId = props['scroll-to-id'];
        if (scrollToId && document.getElementById(scrollToId)) {
            const idNode = document.getElementById(scrollToId)!;
            window.scroll({
                top: idNode.offsetTop - 100,
                left: 0,
                behavior: 'smooth',
            });
        }
    }
    return (
        <Component {...props} onClick={updatedOnClick}>
            {children}
        </Component>
    );
};
