import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';

type AnimationString =
    | 'bounce'
    | 'flash'
    | 'pulse'
    | 'rubberBand'
    | 'shake'
    | 'headShake'
    | 'swing'
    | 'tada'
    | 'wobble'
    | 'jello'
    | 'bounceIn'
    | 'bounceInDown'
    | 'bounceInLeft'
    | 'bounceInRight'
    | 'bounceInUp'
    | 'bounceOut'
    | 'bounceOutDown'
    | 'bounceOutLeft'
    | 'bounceOutRight'
    | 'bounceOutUp'
    | 'fadeIn'
    | 'fadeInDown'
    | 'fadeInDownBig'
    | 'fadeInLeft'
    | 'fadeInLeftBig'
    | 'fadeInRight'
    | 'fadeInRightBig'
    | 'fadeInUp'
    | 'fadeInUpBig'
    | 'fadeOut'
    | 'fadeOutDown'
    | 'fadeOutDownBig'
    | 'fadeOutLeft'
    | 'fadeOutLeftBig'
    | 'fadeOutRight'
    | 'fadeOutRightBig'
    | 'fadeOutUp'
    | 'fadeOutUpBig'
    | 'flipInX'
    | 'flipInY'
    | 'flipOutX'
    | 'flipOutY'
    | 'lightSpeedIn'
    | 'lightSpeedOut'
    | 'rotateIn'
    | 'rotateInDownLeft'
    | 'rotateInDownRight'
    | 'rotateInUpLeft'
    | 'rotateInUpRight'
    | 'rotateOut'
    | 'rotateOutDownLeft'
    | 'rotateOutDownRight'
    | 'rotateOutUpLeft'
    | 'rotateOutUpRight'
    | 'hinge'
    | 'jackInTheBox'
    | 'rollIn'
    | 'rollOut'
    | 'zoomIn'
    | 'zoomInDown'
    | 'zoomInLeft'
    | 'zoomInRight'
    | 'zoomInUp'
    | 'zoomOut'
    | 'zoomOutDown'
    | 'zoomOutLeft'
    | 'zoomOutRight'
    | 'zoomOutUp'
    | 'slideInDown'
    | 'slideInLeft'
    | 'slideInRight'
    | 'slideInUp'
    | 'slideOutDown'
    | 'slideOutLeft'
    | 'slideOutRight'
    | 'slideOutUp';

interface Props {
    animationIn?: AnimationString;
    animationOut?: AnimationString;
    animationInDelay?: number;
    animationOutDelay?: number;
    animationInDuration?: number;
    animationOutDuration?: number;
    isVisible?: boolean;
    className?: string;
    children?: any;
}

export default function UiAnimate({
    animationIn = 'fadeIn',
    animationOut = 'fadeOut',
    animationInDelay = 0,
    animationOutDelay = 0,
    animationInDuration = 1000,
    animationOutDuration = 1000,
    isVisible = true,
    className = '',
    children,
}: Props) {
    const [animationOptions, setAnimationOptions] = useState({
        animation: animationOut,
        duration: animationOutDuration,
        delay: animationOutDelay,
    });

    useEffect(() => {
        if (isVisible) {
            setAnimationOptions({
                animation: animationIn,
                duration: animationInDuration,
                delay: animationInDelay,
            });
        } else {
            setAnimationOptions({
                animation: animationOut,
                duration: animationOutDuration,
                delay: animationOutDelay,
            });
        }
    }, []);

    return (
        <Wrapper $delay={animationOptions.delay} $duration={animationOptions.duration} $isVisible={isVisible}>
            <div className={classNames('animated', animationOptions.animation, className)}>{children}</div>
        </Wrapper>
    );
}
