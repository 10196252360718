import { storageGet, storageSet } from '../services/storage';
import { createStores } from './store/utils';

export const blog = createStores({
    categories: [],
    activeArticle: undefined,
    activeCategoryArticles: [] as any[],
    blogVisitDate: storageGet('blogVisitDate') || storageSet('blogVisitDate', new Date()),
    newBlogPostsCount: undefined,
});
