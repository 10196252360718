import React from 'react';
import { CategoryMatch, MarketTypeCategory } from './types';

export const getHiddenMarketsCount = (topMarketTypes?: MarketTypeCategory[], match?: CategoryMatch) => {
    if (!topMarketTypes || !match?.markets?.length) {
        return 0;
    }
    const marketTypesWithMarketCount = topMarketTypes.filter((topMarketType) =>
        match.markets.find((market) => market.market_type_id === topMarketType.id),
    ).length;
    return Math.max(0, (match?.markets_total_count || 0) - marketTypesWithMarketCount);
};

const SportsMatchContextDefault = {
    turnoverVisible: false,
    isOpen: false,
    sidebetsSearch: '',
    currentVisualization: '',
    isCurrentVisualizationIconClicked: false,
    isBetbuilderActive: false,
    isBetbuilderIconClicked: false,
    isBetbuilderFilter: false,
};
type SMC = typeof SportsMatchContextDefault;

export const SportsMatchContext = React.createContext<
    SMC & { setContext?: (newValues: Partial<SMC> | ((x: Partial<SMC>) => void)) => void }
>(SportsMatchContextDefault);

export const useSportsMatchContext = () => React.useContext(SportsMatchContext);
