import { ComponentType, DetailedHTMLProps, HTMLAttributes } from 'react';
import styled from 'styled-components';

export default styled.ul`
    list-style-type: none;
    max-height: 500px;
    min-height: 100px;
    overflow-y: auto;
    padding: 0;
    scrollbar-width: thin;

    .divider {
        color: var(--font-color-primary);
        margin-bottom: var(--spacing-24);
        text-align: center;
        display: block;
        width: 100%;
        font-size: 1.125rem;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}

        &::before {
            margin-right: ${({ theme }) => theme.layout.gutterHalf};
        }

        &::after {
            margin-left: ${({ theme }) => theme.layout.gutterHalf};
        }

        &.declined {
            color: ${({ theme }) => theme.colors.error};
        }

        &.pending {
            color: var(--font-color-faded);
        }
    }

    .list-item {
        border-radius: var(--ui-radius);
        margin: ${({ theme }) => theme.layout.gutterHalf};
        padding: var(--spacing-12) !important;
        position: relative;
        transition: filter 0.3s ease-in-out;

        background: var(--surface-level-3-bg);
        border: var(--surface-level-3-border);
        border-radius: var(--ui-radius);

        &.pending {
            filter: brightness(0.6);
        }

        &:not(.pending) {
            &::after {
                border-radius: var(--ui-radius);
                bottom: 0;
                content: '';
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition: opacity 0.3s ease-in-out;
                width: 100%;
                z-index: -1;
            }

            &:hover,
            &:active {
                cursor: pointer;
                filter: brightness(1.4);

                &::after {
                    opacity: 1;
                }
            }
        }
    }
` as ComponentType<DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>>;
