import React from 'react';
import Wrapper from './styles';
import { isOldBrowser } from '../../../../services/browser';
import { translate } from '../../../../services/translate';
import { storageSet } from '../../../../services/storage';
import { stores } from '../../../../stores';
import { useStore } from '../../../../hooks/useStore';

export default function CoolbetHeaderWarning() {
    const [isOldBrowserWarningDisabled] = useStore(stores.isOldBrowserWarningDisabled);

    function disableOldBrowserWarning() {
        storageSet('isOldBrowserWarningDisabled', true);
        stores.isOldBrowserWarningDisabled.set(true);
    }

    if (!isOldBrowser() || isOldBrowserWarningDisabled) {
        return null;
    }

    return (
        <Wrapper>
            <div className="header-container warning">
                <h3>
                    {translate(
                        'You are using old browser that is not supported anymore! Please switch to newer browser!',
                        'ui.common',
                    )}
                </h3>
                <button onClick={disableOldBrowserWarning}>X</button>
            </div>
        </Wrapper>
    );
}
