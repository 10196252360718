import styled from 'styled-components';

export default styled.div`
    font-size: 0.8rem;

    .hint {
        color: ${({ theme }) => theme.colors.error};
        font-size: 1rem;
    }
`;
