import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    position: relative;
    min-width: 320px;

    .close-button {
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
    }

    .snippet-text {
        margin: 0.6rem;
        overflow-y: auto;
        @media ${mediaQuery.isPhone} {
            max-height: 60vh;
        }
    }
`;
