import React, { useEffect, useState } from 'react';
import { getRoute } from '../../../services/router';
import UiNavLink from '../../../components/ui/nav-link/UiNavLink';
import { stores } from '../../../stores';

export const bonusRouteByAttribute = {
    'open-bonus-campaign': 'promotions.bonuses',
    'open-bonus-deposit': 'deposit',
};

export const withOpenBonus = (Component, bonusCode) => (props) => {
    const { children, onClick = () => {} } = props;

    const [route, setRoute] = useState('');

    useEffect(() => {
        initializeButton();
    }, []);

    function initializeButton() {
        for (const attribute of Object.keys(bonusRouteByAttribute)) {
            if (!(attribute in props)) {
                continue;
            }
            setRoute(getRoute(bonusRouteByAttribute[attribute]));
        }
    }

    function updatedOnClick(event) {
        onClick(event);

        if (props['open-bonus-campaign'] || props['open-bonus-deposit']) {
            stores.bonuses.prefilledBonusCode.set(bonusCode);
        }
    }

    return (
        <UiNavLink key={route} to={route}>
            <Component {...props} onClick={updatedOnClick}>
                {children}
            </Component>
        </UiNavLink>
    );
};
