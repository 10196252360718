import React, { useEffect, useState } from 'react';

import { translate } from '../../../../../services/translate';
import { StoreSetter } from '../../../../../stores/store/types';
import UiAlert from '../../../../ui/alert/UiAlert';
import UiGroup from '../../../../ui/group/UiGroup';
import UiButton from '../../../../ui/button/UiButton';
import { useCountdown } from '../../../../../services/hooks';
import { BetSlipPlacingState } from '../../../../../services/sports/types';
import { PlaceBetHandle } from '../../../../../services/sports/betslip';
import UiAnimate from '../../../../ui/animate/UiAnimate';
interface Props {
    onPlaceBet: PlaceBetHandle;
    clearSelection: () => void;
    isForceDuplicate?: boolean;
    betSlipPlacingState: BetSlipPlacingState;
    setBetSlipPlacingState: StoreSetter<BetSlipPlacingState>;
}

export default function SportBetslipButtonAndErrorsConfirm({
    onPlaceBet,
    isForceDuplicate: isDuplicate,
    clearSelection,
    betSlipPlacingState,
    setBetSlipPlacingState,
}: Props) {
    const { isLoading } = betSlipPlacingState;
    const [duplicateCooldown, setDuplicateCooldown] = useState(5);
    const [isCooldownTimerEnabled, setIsCooldownTimerEnabled] = useState(false);

    useCountdown(
        () => setDuplicateCooldown((p) => p - 1),
        () => setIsCooldownTimerEnabled(false),
        1000,
        5,
        [isCooldownTimerEnabled],
        () => {
            return isCooldownTimerEnabled && !!isDuplicate;
        },
    );

    useEffect(() => {
        if (isDuplicate) {
            setIsCooldownTimerEnabled(true);
        }
    }, [isDuplicate]);

    function confirmDuplicate() {
        setBetSlipPlacingState({ ...betSlipPlacingState, isConfirmed: true, isLoading: true });
        onPlaceBet(false, false, isDuplicate);
    }

    function cancelBet() {
        if (isDuplicate) {
            clearSelection();
        } else {
            setBetSlipPlacingState({ ...betSlipPlacingState, needsConfirm: false });
        }
    }

    return (
        <>
            <UiGroup expand>
                {
                    <UiButton onClick={cancelBet}>
                        {isDuplicate ? translate('Clear', 'ui.sportsbook') : translate('Back', 'ui.sportsbook')}
                    </UiButton>
                }
                <UiButton
                    color="primary"
                    isLoading={isLoading}
                    disabled={isLoading || isCooldownTimerEnabled}
                    onClick={confirmDuplicate}
                >
                    {translate('Confirm', 'ui.sportsbook')}
                    {isCooldownTimerEnabled && ` (${duplicateCooldown})`}
                </UiButton>
            </UiGroup>

            <UiAnimate animationIn="bounceIn" animationOut="bounceOut" isVisible={true}>
                <UiAlert success className="alert-message">
                    {translate('Just double check your selection and click confirm', 'ui.sportsbook')}
                </UiAlert>
            </UiAnimate>
        </>
    );
}
