import styled from 'styled-components';

export default styled.div`
    .address-input-container {
        display: flex;
        flex-direction: column;

        button {
            margin-bottom: ${({ theme }) => theme.layout.gutter};
        }
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
