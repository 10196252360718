import QrCode from 'qrcode.react';
import React from 'react';

interface Props {
    value: string;
    size?: number;
    bgColor?: string;
    includeMargin?: boolean;
}

export default function UiQr({ value, size, bgColor, includeMargin }: Props) {
    return <QrCode value={value} bgColor={bgColor} includeMargin={includeMargin} size={size || 64} />;
}
