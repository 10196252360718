import React from 'react';
import Wrapper from './styles';

interface Props {
    active?: boolean;
    oddsTooBig?: boolean;
    compact?: boolean;
    children?: React.ReactNode;
}

export default function SportTicketOdds({ active, oddsTooBig, children, compact }: Props) {
    return (
        <Wrapper active={active} oddsTooBig={oddsTooBig} compact={compact}>
            {children}
        </Wrapper>
    );
}
