import { useEffect } from 'react';
import { getAllCrmMessages, loadUserCrmPreferences } from '../../../services/crm';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export default function SystemCrm() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);

    useEffect(() => {
        if (isAuthenticated && user) {
            getAllCrmMessages();
            loadCrmPreferences();
        }
    }, [isAuthenticated, user]);

    async function loadCrmPreferences() {
        await loadUserCrmPreferences();
    }

    return null;
}
