import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 1.2rem;
    }
    .multiplier-bonus-progress {
        margin-top: 0.5rem;
        .progress-bar {
            height: 1.2rem;
        }
    }
`;
