import styled from 'styled-components';

export default styled.div`
    p {
        font-size: 0.7rem;
        margin: 1rem 0;
        text-align: center;
    }

    .share-button {
        width: 100%;
    }
    .close-details {
        display: none;
    }
    .match-name {
        color: var(--font-color-primary);
    }
`;
