import styled from 'styled-components';

export default styled.div`
    .race-stake-input {
        min-width: 100px;
        select {
            border-color: var(--ticket-stake-text);
        }
    }
    .race-stake-input > input {
        height: 2.4rem;
        border-color: var(--ticket-stake-text);
    }
    .race-stake-input > input::-webkit-inner-spin-button,
    .race-stake-input > input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .race-stake-input > label {
        color: var(--ticket-stake-text);
    }
`;
