import classNames from 'classnames';
import React, { Fragment, useEffect, useRef } from 'react';
import { useProofOfPaymentVerificationContext } from '../../../../../../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import { translate } from '../../../../../../services/translate';
import AccountVerificationProofOfPaymentDocument from '../AccountVerificationProofOfPaymentDocument';
import Wrapper from './styles';

export default function AccountVerificationProofOfPaymentDocumentList() {
    const { documentListScrollPosition, requestedDocuments, setDocumentListScrollPosition, setSelectedDocument } =
        useProofOfPaymentVerificationContext();
    const listRef = useRef<HTMLUListElement>(null);
    const dividerIndexes = requestedDocuments.reduce<
        Partial<Record<typeof requestedDocuments[number]['status'], number>>
    >((indexes, method, index) => {
        indexes[method.status] = indexes[method.status] ?? index;
        return indexes;
    }, {});

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = documentListScrollPosition;
        }
        return () => setDocumentListScrollPosition(listRef.current?.scrollTop || 0);
    }, []);

    return (
        <Wrapper ref={listRef}>
            {requestedDocuments.map((document, index) => (
                <Fragment key={document.id}>
                    {index === dividerIndexes.Declined && (
                        <li className="divider declined">{translate('declined', 'ui.account.kyc.pop')}</li>
                    )}
                    {index === dividerIndexes.Requested && (
                        <li className="divider">{translate('verification required', 'ui.account.kyc.pop')}</li>
                    )}
                    {index === dividerIndexes.Pending && (
                        <li className="divider pending">{translate('pending', 'ui.account.kyc.pop')}</li>
                    )}
                    <li
                        className={classNames('list-item', {
                            pending: document.status === 'Pending',
                            declined: document.status === 'Declined',
                        })}
                        onClick={document.status !== 'Pending' ? () => setSelectedDocument(document) : undefined}
                    >
                        <AccountVerificationProofOfPaymentDocument document={document} isCompact />
                    </li>
                </Fragment>
            ))}
        </Wrapper>
    );
}
