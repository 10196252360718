import React from 'react';
import { Ticket, UniqueSelection } from '../../../../services/sports/types';
import Wrapper from './styles';

interface Props {
    ticket: Ticket | UniqueSelection;
    className?: string;
}

export default function SportBadgeTicketPlatform({ className, ticket }: Props) {
    const defaultStatusLength = 10;

    if (!ticket.extra || !ticket.extra.brand) {
        return null;
    }

    const platform = ticket.extra.brand;

    return (
        <Wrapper $statusLength={platform.length || defaultStatusLength} className={className}>
            {platform.toUpperCase()}
        </Wrapper>
    );
}
