import React, { useEffect, useState } from 'react';
import { stores } from '../../../stores';
import { logger } from '../../../services/logger';
import { checkIsUserEligibleForContentBySegment } from '../../../microservices/cms';
import UiDotsLoader from '../../../components/ui/dots-loader/UiDotsLoader';
import { useStore } from '../../../hooks/useStore';

export const withCheckSegment = (Component, segmentId) => (props) => {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isUserEligible, setIsUserEligible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isAuthenticated) {
            validateUserSegment();
        }
    }, [isAuthenticated]);

    async function validateUserSegment() {
        setIsLoading(true);
        try {
            const { isUserInSegment } = await checkIsUserEligibleForContentBySegment(segmentId);
            setIsUserEligible(isUserInSegment);
        } catch (error) {
            logger.error('withCheckSegment', 'validateUserSegment', error);
        }
        setIsLoading(false);
    }

    if (!isAuthenticated) {
        return null;
    }
    if (isLoading) {
        return <UiDotsLoader />;
    }

    return <>{!isUserEligible ? <div /> : <Component {...props}>{props.children}</Component>}</>;
};
