import React from 'react';
import { stores } from '../../../stores';

export const withDynamicContentButton = (Component, reference) => (props) => {
    const { children, onClick = () => {} } = props;

    function updatedOnClick(event) {
        onClick(event);

        stores.cms.dynamicContent.set((dynamicContent) => ({
            ...dynamicContent,
            [reference]: !dynamicContent[reference],
        }));
    }

    return (
        <Component {...props} onClick={updatedOnClick}>
            {children}
        </Component>
    );
};
