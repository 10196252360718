import styled from 'styled-components';

export default styled.div`
    position: relative;
    background: var(--ticket-bg);
    border: var(--ticket-border);
    border-radius: var(--ui-radius);
    padding: var(--spacing-12);
}

.race-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--spacing-12);
    
    .bet-id {
        font-size: 0.7rem;
        margin-bottom: var(--spacing-8);
    }

    .track-name {
        color: var(--match-primary-color);
        font-size: var(--match-name-size);
        font-weight: var(--match-name-weight);
        ${({ theme }) => theme.optimizeFontRendering};

        margin-bottom: var(--spacing-8);
    }
}
    .amount-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        
        font-size: var(--font-12);
        color: var(--font-color-secondary);
        .amount-value {
            color: var(--ticket-stake-text);
            font-size: 1.5rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
    }
`;
