import { W2gEventStatus } from '@staycool/bets-types/w-2-g';

export function findW2gStatusToDisplay(
    w2gEvents?: { id: number; status: W2gEventStatus }[] | null,
    w2gEventStatus?: string,
) {
    if (w2gEventStatus) {
        return w2gEventStatus;
    }
    if (!w2gEvents?.length) {
        return;
    }
    return w2gEvents.some((w) => w.status === 'PENDING') ? 'PENDING' : 'RESOLVED';
}
