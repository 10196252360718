import styled, { css } from 'styled-components';
import { mediaQuery } from '../../styles/utils';

type StyledProps = {
    isMaltaPanelVisible: boolean;
};

export default styled.div<StyledProps>`
    height: 100vh;
    @media ${mediaQuery.isPhone} {
        height: unset;
    }

    .account-balance {
        display: none;
    }

    .main-phone-navigation {
        display: none;
    }

    .main {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .main-container {
            flex: 1 0 0;
            width: 100%;
            max-width: unset;
            margin: unset;
            padding: unset;
        }

        .header {
            margin-bottom: 0;
        }
    }

    .footer {
        display: none;
    }

    @media (max-width: 1200px) {
        .header {
            display: none;
        }
    }

    &.casino-game-layout-semi-full-screen {
        .header,
        .casino-game-desktop-footer-container,
        .casino-game-desktop-layout-left,
        .casino-game-desktop-layout-right {
            display: none;
        }

        .casino-game-desktop-layout-container {
            padding: 0;
        }
    }

    ${({ isMaltaPanelVisible }) =>
        isMaltaPanelVisible &&
        css`
            @media (min-width: 1201px) {
                .main {
                    overflow: unset;

                    .main-container {
                        flex: 1 0 calc(100% - ${({ theme }) => theme.header.height});
                    }
                }

                .footer {
                    display: unset;
                    margin-top: unset;
                    z-index: 150;

                    .footer-links,
                    .footer-partners-casino,
                    .footer-partners-payments,
                    .footer-navigation {
                        display: none;
                    }
                }
            }
        `}
`;
