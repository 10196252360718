import { getServiceUrl, httpGet } from '../services/api';
import { logger } from '../services/logger';

const getUrl = (url) => getServiceUrl('betradar-vs', url);

export async function getMyBetradarTickets(limit, offset) {
    try {
        const url = getUrl('bets/me/');
        return await httpGet<any>(url, { limit, offset });
    } catch (error) {
        logger.error('BetradarVsMicroservice', 'getMyBetradarTickets', error);
        return { tickets: [], count: 0 };
    }
}

export async function getBetradarTicketDetails(ticketDisplayId) {
    try {
        const url = getUrl(`bets/me/${ticketDisplayId}`);
        return httpGet<any>(url);
    } catch (error) {
        logger.error('BetradarVsMicroservice', 'getBetradarTicketDetails', error);
        return {};
    }
}
