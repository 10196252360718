import styled from 'styled-components';
import { lighten, darken } from 'polished';

export default styled.div`
    position: relative;
    margin-top: 0.5rem;
    padding: 0.3rem;
    border: 1px solid ${({ theme }) => lighten(0.1, theme.box.background)};
    background-color: ${({ theme }) => lighten(0.05, theme.box.background)};
    box-shadow: var(--ui-shadow);
    border-radius: var(--ui-radius);
    .bet-header {
        background-color: ${({ theme }) => darken(0.05, theme.box.background)};
        text-align: center;
        padding: 0.4rem;
        border-top-left-radius: var(--ui-radius);
        border-top-right-radius: var(--ui-radius);
    }
    .remove-market {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
    }
    .banker-button {
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
    }
    .match-name {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }
    .market-name {
        font-size: 0.7rem;
        color: var(--font-color-subtle);
    }
`;
