import styled from 'styled-components';
import { lighten } from 'polished';

type StyledProps = {
    $statusLength: number;
};

export default styled.div<StyledProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--font-color-secondary);
    border: 1px solid ${({ theme }) => lighten(0.3, theme.box.background)};
    padding: 0 0.4rem;
    border-radius: 100px;
    background-color: ${({ theme }) => lighten(0.2, theme.box.background)};
    font-size: ${({ $statusLength }) => ($statusLength > 15 ? '0.5rem' : '0.7rem')};
    max-width: 85px;
    height: 20px;
    line-height: 1;
`;
