import styled from 'styled-components';

type StyledProps = {
    $isCompactViewEnabled: boolean;
};

export default styled.div<StyledProps>`
    flex: 1;
    min-width: 0;
    padding: var(--spacing-12);
    .match-sport {
        display: flex;
        align-items: center;

        font-size: 0.75rem;
        color: var(--ticket-text-color-secondary);

        margin-bottom: var(--spacing-8);
        > *:not(:last-child) {
            margin-right: 0.4rem;
        }
        .match-league {
            text-transform: capitalize;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .match-info {
        display: flex;
        align-items: center;

        font-size: 0.875rem;
        color: var(--ticket-text-color-primary);
        font-weight: var(--match-name-weight);
        ${({ theme }) => theme.optimizeFontRendering};

        margin-bottom: var(--spacing-8);
        .match-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            width: 100%;
        }
        .match-stats {
            margin-left: 0.4rem;
            font-size: 0.75rem;
        }
    }

    .ticket-meta {
        display: flex;
        align-items: center;
        margin: ${({ $isCompactViewEnabled }) =>
            $isCompactViewEnabled ? '0 0 var(--spacing-12) 0' : 'var(--spacing-8) 0 0 0'};
        padding-bottom: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'var(--spacing-12)' : '')};
        border-bottom: ${({ $isCompactViewEnabled }) =>
            $isCompactViewEnabled ? '1px dashed var(--ticket-selection-separator)' : ''};

        .ticket-id {
            color: var(--font-color-subtle);
            font-size: 12px;
        }
        > *:not(:last-child) {
            margin-right: var(--spacing-8);
        }
    }

    .selected-market {
        display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : 'flex')};
        align-items: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : 'center')};

        font-size: 0.75rem;
        color: var(--ticket-text-color-secondary);

        > *:not(:last-child) {
            margin-right: 0.4rem;
        }

        .market-name {
            max-width: 70%;
            margin-bottom: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'var(--spacing-8)' : '')};
        }
        .market-outcome {
            color: var(--color-odds);
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            white-space: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : 'nowrap')};
            ${({ theme }) => theme.optimizeFontRendering};
        }
    }
`;
