import { translate } from './translate';
import { stores } from '../stores';
import { setTokenAndAuthenticate } from './auth';

export function getErrorMessage(error) {
    if (error && error.message) {
        return translate(error.message, 'ui.common');
    }
    return translate('TECHNICAL_ERROR', 'ui.common');
}

function resetTwoFactorStore() {
    stores.twoFactor.smsPinId.set(undefined);
    stores.twoFactor.reason.set(undefined);
    stores.twoFactor.safePhone.set(undefined);
    stores.twoFactor.email.set(undefined);
    stores.twoFactor.smsResendFailedReason.set(undefined);
    stores.twoFactor.country.set('');
}

export async function finishLoginWithToken(token) {
    await setTokenAndAuthenticate(token);
    resetTwoFactorStore();
}
