import React from 'react';
import moment from 'moment';

export const withShowOnDate = (Component) => (props) => {
    const dateFormat = 'DDMMYY';

    const showOnDate = props['show-on-date'] && moment(props['show-on-date'], dateFormat);
    if (showOnDate && !showOnDate.isSame(moment(), 'day')) {
        return null;
    }
    return <Component {...props}>{props.children}</Component>;
};
