import React, { useState } from 'react';
import { translate } from '../../../services/translate';
import Wrapper from './styles';
import SportSettingsMenuOptionWagerToWin from './option/wager-to-win/SportSettingsMenuOptionWagerToWin';
import SportSettingsMenuOptionTimeFormat from './option/time-format/SportSettingsMenuOptionTimeFormat';
import SportSettingsMenuOptionOddsType from './option/odds-type/SportSettingsMenuOptionOddsType';
import SportSettingsMenuOptionAmericanLayout from './option/american-layout/SportSettingsMenuOptionAmericanLayout';
import SportSettingsMenuOptionCompactView from './option/compact-view/SportSettingsMenuOptionCompactView';
import { SportSetting } from '../../../services/sports/user-settings';
import { isB2B } from '../../../services/environment';
import { filterStyleProps } from '../../../styles/utils';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    settings: SportSetting[];
    highlighted: number;
    isHidden: boolean;
    onClose: (value: boolean) => void;
    hoverProps: any;
    menuRef: any;
}

export default function SportSettingsMenu({ settings, highlighted, isHidden, onClose, hoverProps, menuRef }: Props) {
    const [{ isPhone }] = useStore(media);
    const [isAllDisplayed, setIsAllDisplayed] = useState(false);
    const filteredSettings = settings.filter(isSettingAllowed);

    function isSettingAllowed(setting: SportSetting) {
        switch (setting) {
            case 'AMERICAN_LAYOUT':
                return !isB2B();
            case 'COMPACT_VIEW':
                return isPhone && !isB2B();
            case 'ODDS_TYPE':
                return true;
            case 'TIME_FORMAT':
                return true;
            case 'WAGER_TO_WIN':
                return true;
            default:
                return false;
        }
    }

    function mapSettingToComponent(setting: SportSetting, key: number) {
        switch (setting) {
            case 'AMERICAN_LAYOUT':
                return <SportSettingsMenuOptionAmericanLayout key={key} />;
            case 'COMPACT_VIEW':
                return <SportSettingsMenuOptionCompactView key={key} />;
            case 'ODDS_TYPE':
                return <SportSettingsMenuOptionOddsType key={key} />;
            case 'TIME_FORMAT':
                return <SportSettingsMenuOptionTimeFormat key={key} />;
            case 'WAGER_TO_WIN':
                return <SportSettingsMenuOptionWagerToWin key={key} />;
            default:
                return null;
        }
    }

    function toggleAllSettings() {
        setIsAllDisplayed(!isAllDisplayed);
    }

    function hasHiddenSettings() {
        return filteredSettings.length > highlighted;
    }

    if (isHidden) {
        return null;
    }

    return (
        <Wrapper {...filterStyleProps(hoverProps)} ref={menuRef}>
            <div className="settings-list">
                {filteredSettings.slice(0, highlighted).map(mapSettingToComponent)}
                {isAllDisplayed && filteredSettings.slice(highlighted).map(mapSettingToComponent)}
            </div>
            <div className="settings-menu">
                {hasHiddenSettings() && (
                    <a onClick={toggleAllSettings} className="more-settings link">
                        {isAllDisplayed
                            ? translate('Less settings', 'ui.sportsbook')
                            : translate('More settings', 'ui.sportsbook')}
                    </a>
                )}
                <a onClick={() => onClose(true)} className="close-menu">
                    {translate('Close', 'ui.sportsbook')}
                </a>
            </div>
        </Wrapper>
    );
}
