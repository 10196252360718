import React, { ReactNode } from 'react';
import Wrapper from './styles';
import UiBrandLine from '../brand-line/UiBrandLine';

interface Props {
    title: string;
    children?: ReactNode;
    dataTest?: string;
    center?: boolean;
    className?: string;
}

export default function UiHeading({ title, children, dataTest, center, className }: Props) {
    return (
        <Wrapper center={center} className={className}>
            <div className="content-container">
                <div className="title" data-test={dataTest}>
                    {title}
                </div>
                <div className="line-container">
                    <UiBrandLine center={center} fullWidth />
                </div>
            </div>
            {children}
        </Wrapper>
    );
}
