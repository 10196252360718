import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { getJson } from '../../../microservices/marketing';
import UiTable from '../../ui/table/UiTable';
import { translate } from '../../../services/translate';
import UiFormInput from '../../ui/form/input/UiFormInput';
import sortBy from 'lodash/sortBy';
import Svg from '../../svg/Svg';
import { logger } from '../../../services/logger';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import UiButton from '../../ui/button/UiButton';

interface Props {
    json: string;
    sortby: string;
}

export default function CmsSortableTable({ json: url, sortby }: Props) {
    const [tableData, setTableData] = useState<any[]>([]);
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState<any[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!filter) {
            setFilteredData(tableData);
            return;
        }
        const filterToLowerCase = filter.toLowerCase();
        const result = tableData.filter((item) =>
            Boolean(Object.values(item).find((value) => String(value).toLowerCase().includes(filterToLowerCase))),
        );
        setFilteredData(result);
    }, [filter]);

    async function loadData() {
        try {
            const response = await getJson(url.slice(1, url.length - 1));
            const sortedResponse = sortBy(response, sortby);
            setFilteredData(sortedResponse);
            setTableData(sortedResponse);
        } catch (error) {
            logger.error('CmsSortableTable', 'loadData', error);
        }
    }

    return (
        <Wrapper>
            {!tableData && <UiDotsLoader />}
            {tableData && (
                <div>
                    <div className="sort-by">
                        <Svg icon="search-ico" />
                        <UiFormInput value={filter} onValueChange={setFilter} className="search" />
                        {filter !== '' && (
                            <UiButton onClick={() => setFilter('')}>
                                {translate('Reset filter', 'ui.sortingtable.component')}
                            </UiButton>
                        )}
                    </div>
                    {filteredData && filteredData.length > 0 && (
                        <div>
                            <UiTable className="sortable-table">
                                <div className="table-row heading">
                                    {Object.keys(filteredData[0]).map((key) => (
                                        <div className="table-cell" key={key}>
                                            {key}
                                        </div>
                                    ))}
                                </div>
                                {filteredData.map((row, index) => (
                                    <div className="table-row" key={index}>
                                        {Object.values(row).map((column: any) => (
                                            <div className="table-cell" key={column}>
                                                {column}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </UiTable>
                        </div>
                    )}
                </div>
            )}
        </Wrapper>
    );
}
