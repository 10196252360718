import styled from 'styled-components';

export default styled.div`
    text-align: right;
    button {
        padding: 0.4rem;
        flex: 1;
    }
    .clear-selections {
        font-size: 0.6rem;
        margin: 0.6rem 0;
        padding: 0.2rem 0;
        background-color: transparent;
        border: none;
        outline: none;
        color: var(--font-color-subtle);
        text-align: right;
        display: inline-block;
        border-bottom: 1px dotted;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-decoration: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 10px);
    }
    .bet-type-controls {
        display: flex;
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        > * {
            align-items: center;
            flex: 1;
            &:not(:last-of-type) {
                margin-right: 0.2rem;
            }
        }
    }
`;
