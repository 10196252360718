import { storageGet } from '../storage';
import { camsEnrollConsentKey, camsSessionTokenSorageKey, camsUserIdStorageKey } from './constants';

export const enum OrchestrationStatus {
    approved = 'approved',
    denied = 'denied',
    pending = 'pending',
    notInitiated = 'Not Initiated',
}

export interface UserStatus {
    userRegistered: boolean;
    emailVerified: boolean;
    phoneVerified: boolean;
    userBiometric: 'face' | undefined;
    manualPIIEntry: boolean;
    userScanId?: boolean;
    complianceStatus: boolean;
    userApproved: boolean;
    requestSSN9: boolean;
    requestResAddress: boolean;
    requestScanID: boolean;
    underAge: boolean;
    userLocation: boolean;
    expiredDate: string;
    orchestrationStatus: OrchestrationStatus;
}

type RegistrationCamsState = {
    currentStep: CamsStep;
    userId: string;
    email?: string;
    status?: UserStatus;
    isCameraInUse?: boolean;
    transferToken?: string;
    skippedStep?: string;
    cameraError?: string;
    isInactivityCheck: boolean;
    enrollConsent?: boolean;
    portrait?: ImageData;
    frontScanConfidenceScore?: number;
    inPerson?: boolean;
    socialSecurityNumber?: string;
};

export enum CamsStep {
    details = 'details',
    editDetails = 'editDetails',
    enroll = 'enroll',
    missingData = 'missingData',
    documentFront = 'documentFront',
    documentBack = 'documentBack',
    switchDevice = 'switchDevice',
}

export const registrationCamsStoreDefaultValue: RegistrationCamsState = {
    currentStep: CamsStep.details,
    userId: storageGet(camsUserIdStorageKey),
    transferToken: storageGet(camsSessionTokenSorageKey),
    isInactivityCheck: false,
    enrollConsent: storageGet(camsEnrollConsentKey),
};
