import React from 'react';
import Wrapper from './styles';
import HorseRacingBetslip from '../betslip/HorseRacingBetslip';
import { stores } from '../../../stores';
import HorseRacingSidebarRacebookClosed from './racebook-closed/HorseRacingSidebarRacebookClosed';
import { useStore } from '../../../hooks/useStore';

export default function HorseRacingSidebar() {
    const [isRacebookClosed] = useStore(stores.horseRacing.isRacebookClosed);
    return (
        <Wrapper>
            {isRacebookClosed ? (
                <HorseRacingSidebarRacebookClosed />
            ) : (
                <div className="betslip-box">
                    <HorseRacingBetslip />
                </div>
            )}
        </Wrapper>
    );
}
