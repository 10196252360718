import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import React from 'react';

export function timedQueue<T = number>(functionToCallWithMultipleIds: (ids: T[]) => void, collectForMilliSeconds = 50) {
    const idsCollector: T[] = [];

    const debouncedFunction = debounce(() => {
        functionToCallWithMultipleIds(uniq(idsCollector));
        idsCollector.length = 0;
    }, collectForMilliSeconds);

    return (id: T) => {
        idsCollector.push(id);
        debouncedFunction();
    };
}

export const ErrorContext = React.createContext({ suppressError: false });
