import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import UiAlert from '../../../../ui/alert/UiAlert';
import Wrapper from './styles';
import DangerousHtml from '../../../../dangerous-html/DangerousHtml';

interface Props {
    isDisabled?: boolean;
    maxSizeInBytes?: number;
    onDrop?: (files: File[]) => void;
    selectButtonText: string;
    accept?: string;
    maxSize?: number;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    multiple: boolean;
}

export default function AccountVerificationDocumentInput({
    accept = 'image/jpeg, image/png, image/gif, image/tif, image/bmp, image/webp, application/pdf',
    isDisabled,
    maxSizeInBytes = 15,
    maxSize = maxSizeInBytes * 1024 * 1024,
    onClick = () => void 0,
    onDrop = () => void 0,
    selectButtonText,
    ...rest
}: Props) {
    const [errorMessage, setErrorMessage] = useState('');

    function handleOnClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (isDisabled) {
            return event.preventDefault();
        }
        onClick(event);
    }

    function handleOnDrop(acceptedFiles: File[], rejectedFiles: File[]) {
        setErrorMessage('');
        onDrop(acceptedFiles);
        if (rejectedFiles.length) {
            if (rejectedFiles.some((file) => !accept.includes(file.type))) {
                setErrorMessage(translate('Unsupported file type', 'ui.account.kyc'));
            } else {
                setErrorMessage(
                    translate(
                        'Oops! Each uploaded file should be less than {{size}}MB in size. Please try uploading smaller files.',
                        'ui.account',
                        { size: maxSizeInBytes },
                    ),
                );
            }
        }
    }

    return (
        <Wrapper>
            <Dropzone accept={accept} onClick={handleOnClick} maxSize={maxSize} onDrop={handleOnDrop} {...rest}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <UiButton color="primary" block disabled={isDisabled}>
                            <DangerousHtml content={selectButtonText} />
                        </UiButton>
                    </div>
                )}
            </Dropzone>
            {errorMessage && (
                <UiAlert failure className="alert">
                    {errorMessage}
                </UiAlert>
            )}
        </Wrapper>
    );
}
