import { useEffect, useRef, useState } from 'react';
import { Store, StoreSetter } from '../stores/store/types';
import { storageRemove, storageSet } from './storage';
import isNil from 'lodash/isNil';
import { useStore } from '../hooks/useStore';

interface SharedPersistedValueHandler<T> {
    deregister: () => void;
    emit: (value: T) => void;
}

interface SharedPersistedValue<T> {
    callbacks: Set<Setter<T>>;
    value: T;
}

interface StateSetter<T> {
    (updateState: T): void;
    (updateState: (oldValue: T) => void): T;
}

type Setter<T> = StoreSetter<T> | StateSetter<T>;

const sharedPersistedValueByKey: {
    [key: string]: SharedPersistedValue<any>;
} = {};

function getSharedPersistedValueHandler<T>(
    key: string,
    initialValue: T,
    setState: Setter<T>,
    keepValueInStorageAfterDestroy = true,
): SharedPersistedValueHandler<T> {
    if (!sharedPersistedValueByKey[key]) {
        sharedPersistedValueByKey[key] = { callbacks: new Set<Setter<T>>(), value: initialValue };
    }

    sharedPersistedValueByKey[key].callbacks.add(setState);

    return {
        deregister() {
            if (!keepValueInStorageAfterDestroy && sharedPersistedValueByKey[key].callbacks.size === 1) {
                storageRemove(key);
            }

            sharedPersistedValueByKey[key].callbacks.delete(setState);
        },
        emit(newValue: T) {
            if (sharedPersistedValueByKey[key].value === newValue) {
                return;
            }

            sharedPersistedValueByKey[key].value = newValue;

            sharedPersistedValueByKey[key].callbacks.forEach((callback) => {
                if (setState !== callback) {
                    callback(newValue);
                }
            });
        },
    };
}

function useSharedLocalStorageValue<T>(
    key: string,
    state: T,
    setState: Setter<T>,
    keepValueInStorageAfterDestroy = true,
    syncBetweenInstances = true,
): [T, typeof setState] {
    const globalState = useRef<SharedPersistedValueHandler<T>>(
        getSharedPersistedValueHandler<T>(key, state, setState, keepValueInStorageAfterDestroy),
    );

    useEffect(() => {
        storageSet(key, state);

        if (syncBetweenInstances) {
            globalState.current.emit(state);
        }

        return () => {
            globalState.current.deregister();
        };
    }, []);

    useEffect(() => {
        const parse = (value: string | null) => {
            if (isNil(value)) {
                return value;
            }

            try {
                return JSON.parse(value);
            } catch (error) {
                return value;
            }
        };

        const storageEventHandler = (storageEvent: StorageEvent) => {
            if (storageEvent.key !== key) {
                return;
            }

            const newState = parse(storageEvent.newValue);

            if (state !== newState) {
                setState(newState);
            }
        };

        window.addEventListener('storage', storageEventHandler);

        return () => {
            window.removeEventListener('storage', storageEventHandler);
        };
    }, [state]);

    return [
        state,
        (newValue) => {
            setState(newValue);
            storageSet(key, newValue);

            if (syncBetweenInstances) {
                globalState.current.emit(newValue);
            }
        },
    ];
}

export function useSharedStorage<T>(key: string, initialState: T) {
    const [state, setState] = useState<T>(initialState);
    return useSharedLocalStorageValue<T>(key, state, setState as Setter<T>);
}

export function useSharedStore<T>(key: string, store: Store<T>) {
    const [state, setState] = useStore<T>(store);
    return useSharedLocalStorageValue<T>(key, state, setState, false, false);
}
