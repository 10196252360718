import React from 'react';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { translate } from '../../../../services/translate';
import { validators } from '../../../../services/validators';
import { useForm } from '../../../../services/form';
import { stores } from '../../../../stores';

export default function RegistrationFieldsAcceptTermsAndConditions() {
    const form = useForm(stores.registration.form);

    return (
        <UiFormInput
            {...form.acceptTermsAndConditions}
            checkbox
            validator={validators.terms}
            label={translate('acceptterms', 'ui.registration')}
            data-test="acceptTermsAndConditions"
        />
    );
}
