import styled from 'styled-components';

export default styled.div`
    position: relative;

    min-height: calc(100vh - 245px);
    height: 100%;
    width: 100%;
    background-size: cover;
    border-radius: var(--ui-radius);
    background-color: #000000;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .message-box {
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: var(--ui-radius);
        max-width: 320px;
        position: relative;
        margin: 0 auto;
        padding: 40px;
        border: 1px solid var(--color-highlight);
        box-shadow: inset 0 0 30px #000;
        p {
            color: var(--font-color-subtle);
            font-size: 1rem;
            margin: 1rem 0 0;
        }
        h1 {
            font-size: 2rem;
            margin: 0;
            font-weight: bold;
            line-height: 1;
            text-transform: uppercase;

            em {
                color: var(--color-highlight);
                font-style: normal;
            }
        }
        h2 {
            font-size: 16px;
            font-weight: normal;
            color: var(--color-highlight);
            margin: 0.5rem 0 0 0;
        }
        .stay-cool-text {
            padding-left: 100px;
            font-weight: bold;
            letter-spacing: 1px;
            span:first-of-type {
                color: ${({ theme }) => theme.colors.secondary};
            }
            span:last-of-type {
                color: #00bfff;
            }
        }
    }

    .noize {
        background-image: url('data:image/svg+xml,<svg viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg"><filter id="noiseFilter"><feTurbulence type="fractalNoise" baseFrequency="0.65" numOctaves="3" stitchTiles="stitch" /></filter><rect width="100%" height="100%" filter="url(%23noiseFilter)" /></svg>');
        animation: noize-animation 0.2s infinite;
        position: absolute;
        top: -50%;
        left: -50%;
        bottom: -50%;
        right: -50%;
        height: 300%;
        width: 300%;
        opacity: 0.3;
    }

    @keyframes noize-animation {
        0% {
            transform: translate(0, 0);
        }
        10% {
            transform: translate(-5%, -5%);
        }
        20% {
            transform: translate(-10%, 5%);
        }
        30% {
            transform: translate(5%, -10%);
        }
        40% {
            transform: translate(-5%, 15%);
        }
        50% {
            transform: translate(-10%, 5%);
        }
        60% {
            transform: translate(15%, 0);
        }
        70% {
            transform: translate(0, 10%);
        }
        80% {
            transform: translate(-15%, 0);
        }
        90% {
            transform: translate(10%, 5%);
        }
        100% {
            transform: translate(5%, 0);
        }
    }
`;
