import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.1rem;
    flex: 1;

    .odds-button-spacer {
        position: relative;
        width: 100%;
        height: ${({ theme }) => theme.odds.outcomeHeight};
        @media (max-width: 767px) {
            font-size: 1.2rem;
            margin: 0.2rem;
        }
    }
`;
