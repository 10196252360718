const templateDictionary = {
    camera: {
        type: 'url',
        processor: 'mustache',
        provider: '/assets/templates/camera.html',
    },
    filesystem: {
        type: 'url',
        processor: 'mustache',
        provider: '/assets/templates/fileSystem.html',
    },
    passiveLiveness: {
        type: 'url',
        processor: 'mustache',
        provider: '/assets/templates/passiveLiveness.html',
    },
    smartCapture: {
        type: 'url',
        processor: 'mustache',
        provider: '/assets/templates/smartCapture.html',
    },
    tripleScanGuidance: {
        type: 'url',
        processor: 'mustache',
        provider: '/assets/templates/tripleScanGuidance.html',
    },
    view: {
        type: 'url',
        processor: 'mustache',
        provider: '/assets/templates/view.html',
    },
    result: {
        type: 'url',
        processor: 'mustache',
        provider: '/assets/templates/result.html',
    },
};

export const defaultSdkParams = {
    tripleScanGuidancePage: true,
    fileUploadOnCameraCaptureJourneys: true,
    previewPages: {
        documentStep: {
            smartCapture: false,
            manualCapture: false,
        },
        facematchStep: {
            manualCapture: false,
        },
        poaStep: {
            manualCapture: false,
        },
        passiveLivenessStep: {
            manualCapture: false,
        },
    },
    manualCapture: {
        enabled: true,
        timeout: 15,
    },
    camera: {
        facingMode: 'environment',
    },
    templates: templateDictionary,
    assetsDirectory: '/lib/GBG_WSDK_12.0.0/assets',
    smartCapture: {
        workerScriptUrl: `${window.location.origin}/lib/GBG_WSDK_12.0.0/ides-micro.js`,
        asmScriptUrl: `${window.location.origin}/lib/GBG_WSDK_12.0.0/idesmicro_asm.js`,
        timeout: 5000,
    },
};
