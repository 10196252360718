import React from 'react';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import Wrapper from './styles';

interface Props {
    isNextButtonDisabled?: boolean;
    nextButtonText?: string;
    isPreviousButtonVisible?: boolean;
    onPreviousButtonClick?: () => void;
    onNextButtonClick?: () => void;
}

export default function AccountVerificationMultiStepFooter({
    isNextButtonDisabled,
    nextButtonText = translate('Continue', 'ui.account'),
    isPreviousButtonVisible,
    onNextButtonClick,
    onPreviousButtonClick,
}: Props) {
    return (
        <Wrapper>
            {isPreviousButtonVisible && (
                <UiButton onClick={onPreviousButtonClick}>{translate('Go back', 'ui.common')}</UiButton>
            )}
            <UiButton className="next-button" disabled={isNextButtonDisabled} onClick={onNextButtonClick}>
                {nextButtonText}
            </UiButton>
        </Wrapper>
    );
}
