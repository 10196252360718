import styled, { css } from 'styled-components';
import { TICKET_STATUS } from '../../../../services/bet-history';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $status: any;
    $block?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    height: 1.125rem;

    text-align: center;
    color: var(--ticket-badge-pending-text-color);
    font-size: 0.625rem;
    line-height: 1;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    padding: 0 0.4rem;
    border-radius: 100px;
    border: var(--ticket-badge-pending-border);
    background-color: var(--ticket-badge-pending-bg);

    ${({ $status }) =>
        ($status === TICKET_STATUS.WON || $status === TICKET_STATUS.PARTIALLY_WON) &&
        css`
            border: var(--ticket-badge-won-border);
            background-color: var(--ticket-badge-won-bg);
            color: var(--ticket-badge-won-text-color);
        `}
    ${({ $status }) =>
        $status === TICKET_STATUS.LOST &&
        css`
            border: var(--ticket-badge-lost-border);
            background-color: var(--ticket-badge-lost-bg);
            color: var(--ticket-badge-lost-text-color);
        `}
    ${({ $status }) =>
        $status === TICKET_STATUS.MANUAL_ACCEPTANCE &&
        css`
            border: var(--ticket-badge-warning-border);
            background-color: var(--ticket-badge-warning-bg);
            color: var(--ticket-badge-warning-text-color);
        `}
    ${({ $status }) =>
        ($status === TICKET_STATUS.MANUAL_ACCEPTANCE_REJECTED ||
            $status === TICKET_STATUS.MANUAL_ACCEPTANCE_DENIED ||
            $status === TICKET_STATUS.VOIDED ||
            $status === TICKET_STATUS.CANCELLED) &&
        css`
            border: var(--ticket-badge-warning-border);
            background-color: var(--ticket-badge-warning-bg);
            color: var(--ticket-badge-warning-text-color);
        `}
    ${({ $status }) =>
        $status === TICKET_STATUS.CASHOUT &&
        css`
            border: var(--ticket-badge-cashout-border);
            background-color: var(--ticket-badge-cashout-bg);
            color: var(--ticket-badge-cashout-text-color);
        `}
    ${({ $block }) =>
        $block &&
        css`
            border-radius: var(--ui-radius);
            width: 100%;
            max-width: initial;
        `}

    &:hover {
        max-width: initial;
    }
    @media ${mediaQuery.isPhone} {
        max-width: initial;
    }
`;
