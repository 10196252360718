import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

type StyledProps = {
    isColumnLayout?: boolean;
    itemHeight?: number;
    itemWidth?: number;
};
export default styled.div<StyledProps>`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: ${(props) => (props.isColumnLayout ? 'column' : 'row')};
    margin-bottom: ${({ theme }) => theme.layout.gutter};
    .preview-item-container {
        border: 1px solid var(--font-color-faded);
        border-radius: var(--ui-radius);
        height: ${(props) => (props.itemHeight ? `${props.itemHeight}px` : 'auto')};
        width: ${(props) => (props.itemWidth ? `${props.itemWidth}px` : 'auto')};
        margin: 0.2rem;
        padding: 0.2rem;
        position: relative;

        :hover {
            .remove-button {
                display: block;
            }
        }

        .remove-button {
            display: none;
            @media ${mediaQuery.isPhone} {
                display: block;
            }
            position: absolute;
            top: -12px;
            right: -12px;
        }
    }
`;
