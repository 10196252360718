import clone from 'lodash/clone';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import { useStoreWithSelector } from '../../../../stores';
import VirtualSportsBetslipOdds from '../odds/VirtualSportsBetslipOdds';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import UiButton from '../../../ui/button/UiButton';
import { MarketInfo } from '../../../../services/sports/types';
import { virtualSports } from '../../../../stores/virtual-sports/virtual-sports';

interface Props {
    marketInfo: MarketInfo;
    isDisabled: boolean;
}

export default function VirtualSportsBetslipOutcomes({ marketInfo, isDisabled }: Props) {
    const [selectedOutcomeId] = useStoreWithSelector(
        virtualSports.betslipMarketIdToOutcomeId,
        (state) => state[marketInfo.id],
    );
    const [isExpanded, setIsExpanded] = useState(false);

    const initialOutcomesAmountVisibleInListView = 1;
    let outcomes = clone(marketInfo.outcomes);
    const isListViewType = outcomes.length > 3;

    const hasExtraOutcomesToDisplay = marketInfo.outcomes.length > initialOutcomesAmountVisibleInListView;
    const isListViewExtensionToggleVisible = isListViewType && hasExtraOutcomesToDisplay;

    if (isListViewType && !isExpanded && isListViewExtensionToggleVisible) {
        outcomes = sortBy(outcomes, (outcome) => outcome.id !== selectedOutcomeId);
    }

    if (isListViewType) {
        outcomes = outcomes.slice(0, isExpanded ? undefined : initialOutcomesAmountVisibleInListView);
    }

    return (
        <Wrapper $isListViewType={isListViewType}>
            {outcomes.map((outcome) => {
                if (!outcome.market_id) {
                    outcome.market_id = marketInfo.id;
                }

                return (
                    <div className="outcome" key={outcome.id}>
                        <div className="outcome-name">
                            <span>{outcome.name.replace(/\//g, ' / ')}</span>
                        </div>

                        <div className="odds">
                            <VirtualSportsBetslipOdds isDisabled={isDisabled} outcome={outcome} />
                        </div>
                    </div>
                );
            })}

            {isListViewExtensionToggleVisible && (
                <UiButton size="small" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? translate('Collapse', 'ui.sportsbook') : translate('Expand', 'ui.sportsbook')}
                </UiButton>
            )}
        </Wrapper>
    );
}
