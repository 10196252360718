import styled from 'styled-components';
import UiModal from '../../../../ui/modal/UiModal';

export default styled(UiModal)`
    .id-scan-button-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    [wsdk-container] .preview-info-main-text {
        color: var(--font-link-color);
        text-decoration: underline;
    }
    [wsdk-container] .preview-info-icon {
        display: none;
    }
    [wsdk-container] .preview-title {
        display: none;
    }
    [wsdk-container] .light-inner-provider-container {
        text-align: center;
        font-size: 14px;
    }
    [wsdk-container] .preview-info-mobile-button {
        justify-content: center;
    }
    [wsdk-container] .preview-info-inner-container {
        z-index: 4;
        border-radius: var(--ui-radius);
    }
`;
