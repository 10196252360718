import React from 'react';

interface Props {
    content: string;
    className?: string;
    elementType?: keyof JSX.IntrinsicElements;
}

export default function DangerousHtml({ content, className, elementType: ElementType = 'span' }: Props) {
    return <ElementType className={className} dangerouslySetInnerHTML={{ __html: content }} />;
}
