import React from 'react';
import { useProofOfAddressVerificationContext } from '../../../../../../contexts/proof-of-address-verification/ProofOfAddressVerificationContext';
import { translate } from '../../../../../../services/translate';
import UiButton from '../../../../../ui/button/UiButton';
import AccountVerificationProofOfAddressHeader from '../../header/AccountVerificationProofOfAddressHeader';

export default function AccountVerificationProofOfAddressSteps0() {
    const { setActiveStep, documentUpload } = useProofOfAddressVerificationContext();

    return (
        <>
            <AccountVerificationProofOfAddressHeader>
                {documentUpload.statusAlert}
            </AccountVerificationProofOfAddressHeader>
            {documentUpload.isEnabled && (
                <UiButton onClick={() => setActiveStep(1)} block>
                    {translate('Upload documents', 'ui.account.kyc')}
                </UiButton>
            )}
        </>
    );
}
