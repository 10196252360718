import styled from 'styled-components';

export default styled.div`
    display: flex;
    justify-content: center;
    padding: var(--spacing-16) 0;
    background: var(--ticket-bg);
    border: var(--ticket-border);
    border-radius: var(--ui-radius);

    > *:not(:last-child) {
        margin-right: var(--spacing-24);
    }
`;
