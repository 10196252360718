import styled from 'styled-components';

type StyledProps = {
    $isListViewType: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: flex-end;
    ${({ $isListViewType }) => ($isListViewType ? 'flex-direction: column' : '')};
    padding-top: var(--spacing-8);
    margin-top: var(--spacing-8);
    border-top: 1px dashed var(--surface-level-3-line-color);
    .outcome {
        flex: 1;
        display: flex;
        flex-direction: ${({ $isListViewType }) => ($isListViewType ? 'row' : 'column')};
        justify-content: center;
        min-width: 0;
        ${({ $isListViewType }) => ($isListViewType ? 'width: 100%' : '')};
        margin-bottom: 0.4rem;

        text-align: center;
        > * {
            flex-direction: ${({ $isListViewType }) => ($isListViewType ? 'row' : 'column')};
        }
        &:not(:last-child) {
            margin-right: 0.2rem;
        }
        .odds-outcome {
            min-width: 0;
        }
    }
    .expand-button {
        margin: 0.4rem 0 0 0;
    }
`;
