import styled from 'styled-components';

export default styled.div`
    .verification-code {
        font-weight: bold;
        font-size: 2rem;
        text-align: center;
        margin: 1rem;
    }
`;
