import React from 'react';
import Wrapper from './styles';
import UiModal from '../../ui/modal/UiModal';
import { translate } from '../../../services/translate';
import Snippet from '../../snippet/Snippet';
import UiButton from '../../ui/button/UiButton';
import { storageSet } from '../../../services/storage';
import { stores } from '../../../stores';
import UiBox from '../../ui/box/UiBox';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import UiHeading from '../../ui/heading/UiHeading';
import { getUserMarketCode } from '../../../services/user';
import { useRouter } from '../../../services/router';
import { useStore } from '../../../hooks/useStore';

export default function CmsMessageModal() {
    const [user] = useStore(stores.user);
    const { navigateTo } = useRouter();
    const [isMessageModalOpen] = useStore(stores.modals.isMessageModalOpen);
    const translatedUrl = translate('ui.common.cms-modal-cta-link');
    function handleModalAcceptance() {
        if (user) {
            storageSet('is-modal-accepted', { userId: user.id, isModalAccepted: true });
            if (translatedUrl !== 'ui.common.cms-modal-cta-link') {
                navigateTo(translatedUrl);
            }
        }
        handleModalClose();
    }

    function handleModalClose() {
        stores.modals.isMessageModalOpen.set(false);
    }

    return (
        <UiModal open={isMessageModalOpen} onClose={handleModalClose}>
            <Wrapper>
                <UiBox>
                    <UiCloseButton className="close-button" onClick={handleModalClose} />
                    <UiHeading title={translate('ui.common.cms-modal-heading')} />
                    <div className="snippet-text">
                        <Snippet snippetKey={`cms-modal-message-${getUserMarketCode().toLowerCase()}`} />
                    </div>

                    <UiButton color="primary" block onClick={handleModalAcceptance}>
                        {translate('ui.common.cms-modal-cta-label')}
                    </UiButton>
                </UiBox>
            </Wrapper>
        </UiModal>
    );
}
