import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    modal: string;
    children: React.ReactNode;
}

export default function CmsModal({ children, ...rest }: Props) {
    return (
        <Wrapper {...filterStyleProps({ ...rest })}>
            <div>{children}</div>
        </Wrapper>
    );
}
