import styled from 'styled-components';

export default styled.div`
    display: flex;
    margin-top: ${({ theme }) => theme.layout.gutter};

    .next-button {
        margin-left: auto;
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
