export async function hasCamera() {
    const deviceInfos = await navigator?.mediaDevices?.enumerateDevices?.();
    return deviceInfos?.some?.((dev) => dev.kind === 'videoinput');
}

const MinCameraWidthForDocScan = 2000;

export async function hasCameraMinWidthOf(widthPx = MinCameraWidthForDocScan) {
    const deviceInfos = await navigator?.mediaDevices?.enumerateDevices?.();
    return deviceInfos
        ?.filter((d): d is InputDeviceInfo => d.kind === 'videoinput')
        .some?.((dev) => ((dev as any).getCapabilities?.()?.width?.max || MinCameraWidthForDocScan) >= widthPx);
}
