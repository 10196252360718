import React from 'react';
import { isExistingUser } from '../../../../services/user';
import AuthLoginButton from '../../../auth/login/button/AuthLoginButton';
import AuthPayAndPlayButton from '../../../auth/pay-and-play/button/AuthPayAndPlayButton';
import AuthRegisterButton from '../../../auth/register/button/AuthRegisterButton';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

export default function CoolbetHeaderAnonymous() {
    const isUserExists = isExistingUser();
    const [{ isPhone, isTablet }] = useStore(media);
    const isMobileDevice = isPhone || isTablet;

    return (
        <>
            {isUserExists && <AuthPayAndPlayButton />}
            <AuthRegisterButton size={isMobileDevice ? 'small' : 'default'} />
            <AuthLoginButton size={isMobileDevice ? 'small' : 'default'} />
        </>
    );
}
