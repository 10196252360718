import styled from 'styled-components';

export default styled.div`
    .point-picker {
        display: flex;
        flex: 1;
        background-color: var(--surface-level-2-bg);
        border: var(--surface-level-2-border);
        border-radius: var(--ui-radius);
        padding: var(--spacing-4);
        margin-bottom: var(--spacing-12);
        .active-point {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .title {
                font-size: 0.7em;
                margin-top: var(--spacing-8);
            }
            .value {
                color: var(--color-highlight);
                font-weight: var(--font-weight-bold);
            }
        }
    }

    .multiple-teaser-points {
        img {
            display: inline;
        }
    }
`;
