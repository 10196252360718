import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    $isCompactViewEnabled: boolean;
    $isTicketDetailsOpen: boolean;
};

export default styled.div<StyledProps>`
    position: relative;
    border: 1px solid ${({ theme }) => lighten(0.05, theme.box.background)};
    background-color: var(--surface-level-2-bg);
    box-shadow: var(--ui-shadow);
    border-radius: var(--ui-radius);
    margin-bottom: ${({ theme }) => theme.layout.gutterHalf};
    padding: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.5rem' : '0.7rem 0.5rem')};
    transition: border-color 200ms linear;
    font-size: 0.875rem;
    cursor: pointer;
    ${({ $isTicketDetailsOpen }) =>
        $isTicketDetailsOpen &&
        css`
            margin-top: 2rem;
        `};
    @media ${mediaQuery.isPhone} {
        margin-bottom: ${({ theme }) => theme.layout.gutter};
    }
    &:hover {
        border-color: ${({ theme }) => lighten(0.1, theme.box.background)};
        .bet-location .copy-ticket-button {
            display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'none' : 'flex')};
            @media ${mediaQuery.isPhone} {
                display: initial;
            }
        }
    }
    @media (max-width: 1200px) {
        padding: 0.5rem;
    }

    .sport-ticket {
        display: flex;
        align-items: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'flex-start' : 'center')};
        flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'column' : 'row')};
        padding: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.5rem' : '0.75rem')};
        @media ${mediaQuery.isPhone} {
            flex-direction: column;
            align-items: flex-start;
            padding: 0.5rem;
        }
    }

    .bet-location {
        width: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'absolute' : 'relative')};
        ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'right: 10px; top: 10px; width: auto;' : '')};
        @media ${mediaQuery.isPhone} {
            position: absolute;
            right: 10px;
            top: 10px;
            width: auto;
            height: 100%;
        }

        .flag-icon {
            margin: 0 0 0.2rem 0;
        }
        .city {
            font-size: 0.8rem;
            color: var(--font-color-subtle);
            margin-top: 0.4rem;
            text-align: center;
            display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'none' : 'block')};
            @media ${mediaQuery.isPhone} {
                display: none;
            }
        }
        .copy-ticket-button {
            animation: fadeIn 200ms linear;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: none;
            background-color: var(--surface-level-2-bg);
            justify-content: center;
            align-items: center;
            width: 100%;
            z-index: 2;
            @media ${mediaQuery.isPhone} {
                display: block;
                top: 50%;
                right: 0;
                transform: translate(-25px, -50%);
                height: 50px;
            }
            > button {
                @media ${mediaQuery.isPhone} {
                    font-size: 2rem;
                    border-radius: 200px;
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    .ticket-type {
        display: flex;
        align-items: center;
        order: 5;
        margin-top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '1rem' : '0.5rem')};
        @media ${mediaQuery.isPhone} {
            margin-top: 1rem;
        }
        .combo {
            order: 6;
        }
        ${({ $isCompactViewEnabled }) =>
            $isCompactViewEnabled ? '' : 'position: absolute; right: 2rem; top: 0.2rem;'};
    }

    .combo {
        color: ${({ theme }) => theme.colors.gold};
        font-size: 0.8rem;
        margin-right: 0.4rem;
    }

    .bet-sport {
        align-items: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'center' : '')};
        margin-bottom: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.2rem' : '')};
        padding: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0' : '0 0.4rem')};
        width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'auto' : '160px')};
        overflow: hidden;
        text-overflow: ellipsis;
        @media ${mediaQuery.isDesktop} {
            width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'auto' : '210px')};
        }
        @media (max-width: 1300px) {
            width: 50px;
            overflow: hidden;
        }
        @media ${mediaQuery.isPhone} {
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;
            padding: 0;
            width: auto;
            max-width: initial;
        }
        .name {
            font-size: 0.7rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'none' : 'flex')};
            align-items: center;
            @media ${mediaQuery.isPhone} {
                display: none;
            }
        }
        .league {
            font-size: 0.8rem;
            color: var(--font-color-subtle);
            display: flex;
            align-items: center;
            margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.6rem 0 0 0' : '0.4rem 0')};
            @media ${mediaQuery.isPhone} {
                margin: 0.6rem 0 0 0;
            }
            .sport-name {
                font-size: 0.8rem;
                margin-left: 0.6rem;
                @media (max-width: 1300px) {
                    display: none;
                    margin-left: 0;
                }
                @media ${mediaQuery.isPhone} {
                    display: block;
                }
                .league-name {
                    text-transform: capitalize;
                    font-size: 0.8rem;
                }
            }
        }
    }

    .bet-match {
        flex: 1;
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.8rem 0' : '0 0.8rem 0 0')};
        width: 100%;
        @media ${mediaQuery.isPhone} {
            margin: 0.8rem 0;
        }
        .match-name {
            margin-bottom: 0.4rem;
            display: flex;
            align-items: center;
            font-size: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '1rem' : '0.875rem')};
            flex-wrap: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'wrap' : '')};
            color: var(--font-color-primary);
            ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : 'position: relative;')};

            @media (max-width: 1300px) {
                max-width: 340px;
            }

            @media ${mediaQuery.isPhone} {
                font-size: 1.2rem;
            }

            .match-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
        }

        .bet-stats {
            margin: 0 0 0 0.3rem;
            font-size: 0.7rem;
        }
    }

    .cashout-button {
        margin-right: ${({ theme }) => theme.layout.gutter};
        order: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '5' : '')};
        margin-top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '1.2rem' : '')};
        margin-left: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : 'auto')};
    }

    .campaign-badge {
        border-radius: 100px;
        font-size: 0.5rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 0.2rem 0.4rem;
        color: var(--color-highlight);
        border: 1px solid var(--color-highlight);
        display: inline-block;
        flex-shrink: 0;
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .bet-market {
        font-size: var(--font-12);
        color: var(--font-color-subtle);
        margin: 0.2rem 0.2rem 0 0;
        display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'flex' : 'block')};
        align-items: flex-start;
        flex-direction: column;
    }

    .bet-outcome {
        color: ${({ theme }) => theme.colors.highlight};
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0' : '0.5rem')};
        ${({ $isTicketDetailsOpen }) =>
            !$isTicketDetailsOpen &&
            css`
                padding-top: 0.4rem;
            `}
    }

    .bet-totals {
        position: relative;
        display: flex;
        min-width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '70px')};
        width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '270px')};
        order: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '3' : '')};
        align-items: center;
        font-size: 0.8rem;
        color: var(--font-color-subtle);

        @media ${mediaQuery.isPhone} {
            font-weight: 600;
            width: 100%;
        }

        .possible-return {
            color: var(--color-highlight);
            padding: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0 0 0.2rem' : '0 0 0 0.5rem')};
            min-width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : '60px')};
            &.LOST,
            &.CANCELLED {
                color: var(--font-color-subtle);
            }
        }
        .cashout-amount {
            color: ${({ theme }) => theme.colors.gold};
            border: none;
            margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0 0 0.2rem' : '0 0 0 0.5rem')};
        }
        .total-stake {
            margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0.2rem 0 0' : '0 0.6rem 0')};
            color: ${({ theme }) => theme.colors.secondary};
            min-width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : '60px')};
        }

        .currency {
            font-size: 60%;
            margin-left: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0' : '0.2rem')};
        }
        &.denied {
            .possible-return,
            .total-stake,
            .ma-label {
                color: var(--font-color-faded);
            }
        }
    }

    .bet-status {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--font-color-secondary);
        border: 1px solid ${({ theme }) => lighten(0.1, theme.box.background)};
        padding: 0 0.8rem;
        background: ${({ theme }) => darken(0.05, theme.box.background)};
        border-radius: 100px;
        background-color: ${({ theme }) => lighten(0.05, theme.box.background)};
        order: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '2' : '')};
        font-size: 0.7rem;
        max-width: 70px;
        height: 25px;
        line-height: 1;
        @media ${mediaQuery.isPhone} {
            order: 2;
        }
        &.lost {
            border-color: ${({ theme }) => theme.colors.red};
            color: ${({ theme }) => theme.colors.red};
        }
        &.won {
            border-color: ${({ theme }) => theme.colors.secondary};
            color: ${({ theme }) => theme.colors.secondary};
        }
        &.cashout {
            border-color: ${({ theme }) => theme.colors.gold};
            color: ${({ theme }) => theme.colors.gold};
        }
    }

    .manual-acceptance-status {
        position: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'absolute' : 'relative')};
        top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '-0.5rem' : '')};
        left: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.5rem' : '')};
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: var(--font-color-secondary);
        border: 1px solid ${({ theme }) => lighten(0.3, theme.box.background)};
        padding: 0 0.4rem;
        margin-top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : '0.4rem')};
        border-radius: 100px;
        background-color: ${({ theme }) => lighten(0.2, theme.box.background)};
        font-size: 0.7rem;
        height: 20px;
        line-height: 1;

        @media ${mediaQuery.isPhone} {
            margin: 0 0 0 0.4rem;
        }
    }

    .total-odds {
        max-width: 65px;
        min-width: 60px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.colors.highlight};
        border: 1px solid ${({ theme }) => lighten(0.2, theme.box.background)};
        border-radius: var(--odds-radius);
        cursor: pointer;
        outline: none;
        letter-spacing: 1px;
        background-color: var(--odds-bg);
        margin-right: 0.6rem;
        padding: 0.4rem;
        @media ${mediaQuery.isPhone} {
            max-width: 85px;
        }
    }
    .bet-info {
        display: flex;
        flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'row' : 'column')};
        align-items: center;
        justify-content: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'flex-start' : 'center')};
        width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '')};
        @media ${mediaQuery.isPhone} {
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
        }
        .ticket-status {
            position: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'absolute' : '')};
            top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '5px' : '')};
            right: ${($isCompactViewEnabled) => ($isCompactViewEnabled ? '5px' : '')};
        }
    }
    .bet-dates {
        font-size: 0.7rem;
        color: var(--font-color-subtle);
        width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '90px' : '')};
        text-align: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'left' : 'right')};
        margin-top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0' : '0.4rem')};
        margin-left: 0.4rem;
        order: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '1' : '')};
        flex: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '1' : '')};
        @media ${mediaQuery.isPhone} {
            margin: ${({ $isTicketDetailsOpen }) => ($isTicketDetailsOpen ? '0 0 0 0.5rem' : '0 0 0.5rem 0')};
            order: 1;
            text-align: left;
            flex: 1;
        }
    }
    .ticket-id {
        color: var(--font-color-subtle);
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0.4rem 0 0' : '0 0 0 0.4rem')};
        font-size: 0.7rem;
        border-radius: 100px;
        padding: 0.2rem 0.4rem;
        border: 1px solid ${({ theme }) => lighten(0.12, theme.box.background)};
        background-color: ${({ theme }) => lighten(0.08, theme.box.background)};
        box-shadow: var(--ui-shadow);
        ${({ $isTicketDetailsOpen }) =>
            $isTicketDetailsOpen &&
            css`
                position: absolute;
                bottom: 100%;
                left: 0px;
                font-size: 0.8rem;
                color: var(--ticket-ticketid-color);
                padding: var(--spacing-4);
                background: none;
                border: none;
            `}
        @media ${mediaQuery.isPhone} {
            margin: 0 0.4rem 0 0;
        }
    }

    .close-details {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        @media ${mediaQuery.isPhone} {
            right: 0;
        }
    }
    .copy-ticket {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        margin: 0 1rem 0 0;
        @media ${mediaQuery.isPhone} {
            margin: 0.8rem 0 0 0;
        }
        .copy-button {
            flex: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '1' : '')};
            @media ${mediaQuery.isPhone} {
                max-width: initial;
                flex: 1;
            }
        }
    }
`;
