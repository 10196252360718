import styled from 'styled-components';

export default styled.div`
    position: relative;
    text-align: center;
    margin-top: 1.2rem;
    .sidebet-recommendations {
        position: relative;
        z-index: 2;

        background-color: var(--surface-level-2-bg);
        border: var(--surface-level-2-border);

        color: var(--color-highlight);

        border-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow);
        padding: 0.75rem;
    }
    .sidebet-market-name {
        font-size: 0.85rem;
        color: var(--match-sidebet-name-color);

        margin: 0.75rem 0;
    }
    .sidebet-outcomes {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
    }
    .sidebet-recommendations-label {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        font-size: 0.6rem;
        letter-spacing: 1px;
        color: var(--color-on-highlight);
        white-space: nowrap;
        text-transform: uppercase;

        background-color: var(--color-highlight);
        padding: 0.2rem 0.8rem;
        border-radius: 100px;
    }
`;
