import styled from 'styled-components';
import UiBox from '../ui/box/UiBox';

export default styled(UiBox)`
    display: flex;
    flex-direction: column;
    text-align: center;

    .image-wrapper {
        display: flex;
        justify-content: center;
    }
    .button-text {
        p {
            margin: 0;
        }
        a {
            border-bottom: none;
            color: var(--button-primary-text-color);
        }
    }
`;
