import styled, { css } from 'styled-components';
import { lighten, saturate, transparentize } from 'polished';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    light?: boolean;
    $isCompactViewEnabled?: boolean;
    $oddsTooBig?: boolean;
    $isActive?: boolean;
    $isDisabled?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.1rem;
    flex: 1;
    .odds-outcome-name {
        text-align: center;
        text-transform: uppercase;
        font-size: 0.65rem;
        color: var(--font-color-secondary);
        letter-spacing: 1px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: ${({ theme }) => theme.layout.gutterHalf};
        @media ${mediaQuery.isPhone} {
            font-size: var(--font-12);
        }
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .odds-button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 33px;
        color: ${({ theme }) => theme.colors.highlight};
        border: 1px solid ${({ theme }) => lighten(0.2, theme.box.background)};
        font-size: 1rem;
        padding: 0;
        box-shadow: var(--ui-shadow);
        border-radius: var(--ui-radius);
        cursor: pointer;
        outline: none;
        letter-spacing: 1px;
        background-color: ${({ theme, light }) =>
            light ? lighten(0.12, theme.box.background) : lighten(0.1, theme.box.background)};
        font-weight: 500;
        border: 1px solid
            ${({ theme, light }) => (light ? lighten(0.18, theme.box.background) : lighten(0.16, theme.box.background))};

        ${({ $isCompactViewEnabled }) =>
            $isCompactViewEnabled &&
            css<{ $oddsTooBig?: boolean }>`
                font-size: ${({ $oddsTooBig }) =>
                    $oddsTooBig &&
                    css`
                        font-size: 0.7rem;
                    `};
                height: 40px;
                margin: 0.2rem;
            `};

        &:hover {
            border-color: ${({ theme }) => saturate(0.3, theme.colors.highlight)};
            color: ${({ theme }) => theme.colors.highlight};
            background-color: ${({ theme }) => transparentize(0.8, theme.colors.highlight)};
        }
        &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
            pointer-events: none;
        }
        span.value {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 0.2rem;
            justify-content: center;
            line-height: 1.2px;
        }

        ${({ $isActive }) =>
            $isActive &&
            css`
                color: #000 !important;
                border-color: ${({ theme }) => saturate(0.3, theme.colors.highlight)};
                background-color: ${({ theme }) => theme.colors.highlight}!important;

                &:hover {
                    color: #000;
                }
            `};
        ${({ $isDisabled }) =>
            $isDisabled &&
            css`
                opacity: 0.4;
                cursor: not-allowed;
                color: var(--font-color-secondary);
            `};
        ${({ $oddsTooBig }) =>
            $oddsTooBig &&
            css`
                font-size: 0.7rem;
            `};
    }
`;
