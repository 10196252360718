import React, { useEffect, useState } from 'react';
import { getArticleRoute, getRoute } from '../../../services/router';
import UiNavLink from '../../../components/ui/nav-link/UiNavLink';

export const pageRouteByAttribute = {
    'open-page-sports': 'sport',
    'open-page-sports-virtual': 'virtual-sports',
    'open-page-livebet': 'sport.live',
    'open-page-casino': 'casino',
    'open-page-casino-live': 'casino.live',
    'open-page-poker': 'poker',
    'open-page-support': 'support',
    'open-page-signup': 'registration',
    'open-page-terms': 'support.terms-and-conditions',
    'open-page-bonuses': 'promotions.bonuses',
    'open-page-rules': '',
};

export const withOpenPage = (Component, payload) => (props) => {
    const { children } = props;

    const [route, setRoute] = useState('');

    useEffect(() => {
        initializeButton();
    }, []);

    function initializeButton() {
        for (const attribute of Object.keys(pageRouteByAttribute)) {
            if (!(attribute in props)) {
                continue;
            }

            if (attribute === 'open-page-rules') {
                const articleRouteByProduct = {
                    sportsbook: getArticleRoute('support-rules-sport-game', 'support.rules'),
                    casino: getArticleRoute('support-rules-sport-game', 'support.rules'),
                    poker: getArticleRoute('support-rules-poker', 'support.rules'),
                };
                setRoute(articleRouteByProduct[payload]);
            } else {
                setRoute(getRoute(pageRouteByAttribute[attribute]));
            }
        }
    }

    return (
        <UiNavLink key={route} to={route}>
            <Component {...props}>{children}</Component>
        </UiNavLink>
    );
};
