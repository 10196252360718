import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;

    display: flex;
    flex-direction: column;

    background-color: var(--surface-level-2-bg);
    border: var(--surface-level-2-border);
    padding: 1rem 1rem 0.2rem 1rem;
    border-radius: var(--ui-radius);
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

    min-width: 100%;

    user-select: none;
    @media ${mediaQuery.isLaptop} {
        min-width: 230px;
        padding: 1.2rem 1.2rem 0.2rem 1.2rem;
    }
    @media ${mediaQuery.isPhone} {
        padding: 1.2rem 1.2rem 0.2rem 1.2rem;
    }

    .settings-list {
        margin: 0 0 0.8rem 0;
        > *:not(:last-child) {
            padding-bottom: 0.8rem;
            margin-bottom: 0.8rem;
            @media ${mediaQuery.isLaptop} {
                padding-bottom: 0.8rem;
                margin-bottom: 0.8rem;
            }
        }
    }

    .setting-item {
        .item-label {
            display: flex;
            align-items: center;

            svg {
                margin-left: 0.8rem;
                color: var(--font-color-subtle);
            }
            .label-text {
                font-size: 0.8rem;
                color: var(--font-color-secondary);
                margin-bottom: 0.8rem;
                min-width: 100px;
            }
        }
        &.item-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .label-text {
                margin-bottom: 0;
            }
            .item-label {
                margin-right: 0.4rem;
            }
        }
        .tooltip {
            max-width: 200px;
        }
    }

    .more-settings {
        display: block;
        width: 100%;

        font-size: 0.8rem;
        text-align: left;
    }
    .settings-menu {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-top: var(--surface-level-2-line-color);

        > a {
            padding: 0.8rem 0;
        }
        .close-menu {
            font-size: 0.8rem;
            color: var(--font-color-subtle);
            text-align: right;
            margin-left: auto;
        }
    }
`;
