import React from 'react';
import { getRoute } from '../../../../../services/router';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import { stores } from '../../../../../stores';
import Svg from '../../../../svg/Svg';
import UiNavLink from '../../../../ui/nav-link/UiNavLink';
import UiPlaceholder from '../../../../ui/placeholder/UiPlaceholder';
import SportMatchScore from '../../../match/score/SportMatchScore';
import SportMatchTimeLive from '../../../match/time/live/SportMatchTimeLive';
import { LiveTreeCategoryFo } from '@staycool/sbgate-types/dist/categoryTree/types';

interface Props {
    category: LiveTreeCategoryFo;
}

export default function SportTreeLivebetCategory({ category }: Props) {
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId: category.sport_category_id });

    function closeSportSideMenu() {
        stores.sports.isSportSideMenuOpen.set(false);
    }

    return (
        <div>
            {category.sections.map((section) => (
                <div key={section.league_category_id}>
                    <div className="sub-category">{section.sectionName || <UiPlaceholder light width="70%" />}</div>

                    {section.matches.map((match) => (
                        <UiNavLink
                            onClick={() => closeSportSideMenu()}
                            to={`${getRoute('sport.live')}/${match.id}`}
                            key={match.id}
                            className="livebet-match"
                        >
                            {match.stream_provider && (
                                <Svg className="stream-icon" icon={match.stream_provider.toLowerCase()} />
                            )}
                            <div className="match-name">
                                {match.home_team_name || (match as any).isFake ? (
                                    <>
                                        <div className="team-name">
                                            {isAmericanLayout
                                                ? `${match.away_team_name || <UiPlaceholder />} @ `
                                                : match.home_team_name || <UiPlaceholder />}
                                        </div>
                                        <div className="team-name">
                                            {isAmericanLayout
                                                ? match.home_team_name || <UiPlaceholder />
                                                : match.away_team_name || <UiPlaceholder />}
                                        </div>
                                    </>
                                ) : (
                                    match.name
                                )}
                            </div>

                            <div className="match-time">
                                {!(match as any).isFake ? (
                                    <SportMatchTimeLive
                                        matchId={match.id}
                                        sportId={category.sport_id}
                                        isOurLivebet={match.livebet_our}
                                        isCompact
                                    />
                                ) : (
                                    <UiPlaceholder />
                                )}
                            </div>

                            <div>
                                {!(match as any).isFake && (
                                    <SportMatchScore
                                        matchId={match.id}
                                        sportId={category.sport_id}
                                        sportCategoryId={category.sport_category_id}
                                        isLivebetOur={match.livebet_our}
                                    />
                                )}
                            </div>
                        </UiNavLink>
                    ))}
                </div>
            ))}
        </div>
    );
}
