import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    min-width: 180px;
    @media ${mediaQuery.isPhone} {
        min-width: 0;
        flex: 1;
    }
    .outcome {
        display: flex;
        flex-direction: column;
        align-items: center;

        border-radius: 10px;
        margin: 0 0.2rem;
        padding: 0.8rem 0.4rem;

        @media ${mediaQuery.isPhone} {
            flex: 1;
        }
        .outcome-name {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            font-size: 0.75rem;

            .name {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.2rem;
                word-break: break-word;
                margin-bottom: 0.8rem;
                @media ${mediaQuery.isPhone} {
                    min-height: 50px;
                }
            }
            .odds {
                width: 80px;
            }
        }
        .outcome-graph {
            padding: 0 0.6rem;
            max-width: 160px;
            flex: 1;
            display: flex;
            align-items: center;
            @media ${mediaQuery.isPhone} {
                flex-direction: column;
            }
        }
        .outcome-totals {
            display: flex;
            flex-direction: column;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            @media ${mediaQuery.isPhone} {
                margin-top: 0.4rem;
            }
            &.small-digits {
                @media ${mediaQuery.isPhone} {
                    font-size: 1rem;
                }
            }
            .turnover,
            .tickets-number {
                display: flex;
                align-items: center;
                span {
                    font-size: 50%;
                    margin-left: 0.2rem;
                }
            }
            .turnover {
                color: var(--color-turnover);
                font-weight: var(--font-weight-bold);
                ${({ theme }) => theme.optimizeFontRendering};
                @media ${mediaQuery.isPhone} {
                    margin: 0;
                }
            }
            .tickets-number {
                color: var(--font-color-secondary);
            }
        }
    }
`;
