import { translate } from '../translate';

export function getTranslationsDictionary() {
    return {
        BLUR_CHECK_REASON: translate('Blur - the document is unclear', 'ui.kyc'),
        CAMERA_CAPTURE_BACK: translate('Position the BACK of your Identity Document within the frame', 'ui.kyc'),
        CAMERA_CAPTURE_CAPTION: translate('Click on Start Auto Capture to begin', 'ui.kyc'),
        CAMERA_CAPTURE_FRONT: translate(
            'Position the photo side of your Identity Document within the frame above.',
            'ui.kyc',
        ),
        CAMERA_CAPTURE_SELFIE_CAPTION: translate(
            'Position your face within the oval, look directly into the camera and click on the button below to take a selfie.',
            'ui.kyc',
        ),
        CAMERA_CAPTURE_START_BUTTON: translate('Start Auto Capture', 'ui.kyc'),
        CAMERA_CONDITION_ALIGNMENT: translate('Document not in frame', 'ui.kyc'),
        CAMERA_CONDITION_BLUR: translate('Blur Detected', 'ui.kyc'),
        CAMERA_CONDITION_GLARE: translate('Glare Detected', 'ui.kyc'),
        CAMERA_MANUAL_CAPTURE_FRONT: translate(
            'Position your Identity Document within the frame and click on the button below',
            'ui.kyc',
        ),
        CAMERA_PREVENT_BLUR_HINT: translate('Hold the document or device steady.', 'ui.kyc'),
        CAMERA_PREVENT_GLARE_HINT: translate('Move the document away from direct light sources.', 'ui.kyc'),
        CAMERA_PREVENT_LOW_RES_HINT: translate('Fit the document fully within the frame.', 'ui.kyc'),
        CAMERA_SMARTCAPTURE_OFF: translate('AUTO CAPTURE - OFF', 'ui.kyc'),
        CAMERA_SMARTCAPTURE_ON: translate('AUTO CAPTURE - ON', 'ui.kyc'),
        CANCEL_JOURNEY: translate('Cancel', 'ui.common'),
        CANCEL: translate('Cancel', 'ui.common'),
        EDGES_CHECK_REASON: translate(
            'Missing Edges - the document is not positioned in the frame properly;',
            'ui.kyc',
        ),
        FAILURE_REASON_EXPIRED: translate('Your Identity Document is expired.', 'ui.kyc'),
        FAILURE_REASON_REFER: translate('"Your Identity Document failed the validation.', 'ui.kyc'),
        FAILURE_REASON_NOT_SUPPORTED: translate(
            'Your Identity Document could not be classified or is not supported.',
            'ui.kyc',
        ),
        FILESYSTEM_CHOOSE: translate('Choose a file', 'ui.kyc'),
        FILESYSTEM_IDENTITY_DOCUMENT: translate('Identity Document', 'ui.kyc'),
        FILESYSTEM_MAX_FILE_SIZE: translate('Max size: up to 10MB', 'ui.kyc'),
        FILESYSTEM_SUPPORTED_TYPES_END: translate('(for PDF, only the first page will be processed)', 'ui.kyc'),
        FILESYSTEM_SUPPORTED_TYPES_START: translate('Accepted formats: JPG, PNG, PDF ', 'ui.kyc'),
        FLOW_STATE_IDENTITY_BACK: translate('Back Side', 'ui.kyc'),
        'FLOW_STATE_IDENTITY:BACK': translate('Back Side', 'ui.kyc'),
        FLOW_STATE_IDENTITY_FRONT: translate('Photo Side', 'ui.kyc'),
        'FLOW_STATE_IDENTITY:FRONT': translate('Photo Side', 'ui.kyc'),
        GLARE_CHECK_REASON: translate('Glare - the document is unreadable due to a bright light source;', 'ui.kyc'),
        HELP_MODAL_AUTOCAPTURE_BLUR_SUBTITLE: translate('Hold the document or device steady.', 'ui.kyc'),
        HELP_MODAL_AUTOCAPTURE_BLUR_TITLE: translate('Prevent Blur', 'ui.kyc'),
        HELP_MODAL_AUTOCAPTURE_FAR_SUBTITLE: translate('Fit the document fully within the frame.', 'ui.kyc'),
        HELP_MODAL_AUTOCAPTURE_FAR_TITLE: translate('Document Too Far', 'ui.kyc'),
        HELP_MODAL_AUTOCAPTURE_GLARE_SUBTITLE: translate('Move the document away from direct light sources.', 'ui.kyc'),
        HELP_MODAL_AUTOCAPTURE_GLARE_TITLE: translate('Prevent Glare', 'ui.kyc'),
        HELP_MODAL_AUTOCAPTURE_MANUAL_CAPTURE: translate(
            'Still struggling? Take a photo with Manual Capture',
            'ui.kyc',
        ),
        HELP_MODAL_AUTOCAPTURE_SUBTITLE: translate(
            'When Auto Capture is on, the capture screen will automatically take a photo of your document when it has recognised that it is of good enough quality.',
            'ui.kyc',
        ),
        HELP_MODAL_AUTOCAPTURE_TIPS: translate('Tips for taking a good quality photo', 'ui.kyc'),
        HELP_MODAL_AUTOCAPTURE_TITLE: translate('How to take a photo of your Identity Document', 'ui.kyc'),
        HELP_MODAL_AUTOCAPTURE_TURN_MANUAL_CAPTURE: translate('Manual Capture', 'ui.kyc'),
        HELP_MODAL_NEED_HELP: translate('Need help?', 'ui.kyc'),
        INITIALIZING_DESCRIPTION: translate('Checking hardware capabilities, please wait...', 'ui.kyc'),
        INITIALIZING_TITLE: translate('Initializing', 'ui.kyc'),
        JOURNEY_SELECT_CONTINUE: translate('Continue', 'ui.account'),
        MANUAL_PHOTO_UPLOAD_TOGGLE_FALSE: translate('Upload a photo instead', 'ui.kyc'),
        MANUAL_PHOTO_UPLOAD_TOGGLE_TRUE: translate('Take a photo instead', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_FACE_IS_OCCLUDED_TITLE: translate('Uh Oh...We are unable to detect a face.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_FACE_NOT_FOUND_TITLE: translate('Uh Oh...We are unable to detect a face.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_FACE_NOT_FOUND: translate(
            'We are unable to detect a face within the image. Please try again.',
            'ui.kyc',
        ),
        PASSIVE_LIVENESS_ERROR_FACE_CLOSE_TO_BORDER: translate(
            'You are too close, please move further away so that your face fits within the provided frame. Please try again.',
            'ui.kyc',
        ),
        PASSIVE_LIVENESS_ERROR_FACE_TOO_CLOSE_TITLE: translate('Uh Oh...You are too close.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_FACE_TOO_CLOSE: translate(
            'You are too close, please move further away so that your face fits within the provided frame. Please try again.',
            'ui.kyc',
        ),
        PASSIVE_LIVENESS_ERROR_FACE_TOO_SMALL_TITLE: translate('Uh Oh...You are too far away.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_FACE_TOO_SMALL: translate(
            'You are too far away, please move closer to the camera and make sure your face fits within the frame. Please try again.',
            'ui.kyc',
        ),
        PASSIVE_LIVENESS_ERROR_FACE_ANGLE_TOO_LARGE_TITLE: translate('Uh Oh...Something went wrong.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_FACE_ANGLE_TOO_LARGE: translate('Something went wrong. Please try again.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_FACE_CROPPED_TITLE: translate('Uh Oh...Something went wrong.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_FACE_CROPPED: translate('Something went wrong. Please try again.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_INTERNAL_TITLE: translate('Uh Oh...Something went wrong.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_INTERNAL: translate('Something went wrong. Please try again.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_UNKNOWN_TITLE: translate('Uh Oh...Something went wrong.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_UNKNOWN: translate('Something went wrong. Please try again.', 'ui.kyc'),
        PASSIVE_LIVENESS_ERROR_TOO_MANY_FACES_TITLE: translate('Uh Oh...We are unable to detect a face.', 'ui.kyc'),
        PLEASE_TRY_AGAIN_EXPIRED: translate('Please try again with a valid document.', 'ui.kyc'),
        PLEASE_TRY_AGAIN_NOT_SUPPORTED: translate('Please try again.', 'ui.kyc'),
        PLEASE_TRY_AGAIN_REFER: translate('Please try again.', 'ui.kyc'),
        PREVIEW_ATTEMPT_NUMBER_END: translate(' remaining.', 'ui.kyc'),
        PREVIEW_ATTEMPT_NUMBER_START: translate('You have ', 'ui.kyc'),
        PREVIEW_INFO_BAD_EXAMPLE_ANGLE_FACEMATCH: translate('Selfie must show the full face.', 'ui.kyc'),
        PREVIEW_INFO_BAD_EXAMPLE_ANGLE_ID: translate('Photo must show all four corners.', 'ui.kyc'),
        PREVIEW_INFO_BAD_EXAMPLE_BLUR_FACEMATCH: translate('Selfie must not be blurry.', 'ui.kyc'),
        PREVIEW_INFO_BAD_EXAMPLE_BLUR_ID: translate('Photo must not be blurry.', 'ui.kyc'),
        PREVIEW_INFO_BAD_EXAMPLE_COVER_FACEMATCH: translate('Face must not be covered.', 'ui.kyc'),
        PREVIEW_INFO_BAD_EXAMPLE_COVER_ID: translate('Document must not be covered.', 'ui.kyc'),
        PREVIEW_INFO_BAD: translate('Bad', 'ui.kyc'),
        PREVIEW_INFO_GOOD_EXAMPLE_FACEMATCH: translate('Selfie is clear, in focus, and directly face on.', 'ui.kyc'),
        PREVIEW_INFO_GOOD_EXAMPLE_ID: translate('Photo is clear, in focus, and directly face on.', 'ui.kyc'),
        PREVIEW_INFO_GOOD: translate('Good', 'ui.kyc'),
        PREVIEW_INFO_TITLE_FACEMATCH: translate('Tips for a good quality selfie', 'ui.kyc'),
        PREVIEW_INFO_TITLE_ID: translate('Examples of good and bad photos', 'ui.kyc'),
        PROVIDER_SUBTITLE_FILESYSTEM_ID: translate(
            'Please upload a photo of your Identity Document that is clear, in focus, not covered and directly face on.',
            'ui.kyc',
        ),
        PROVIDER_SUBTITLE_VIEW_ID: translate(
            'Please check the preview of your document is clear, in focus, not covered and directly face on.',
            'ui.kyc',
        ),
        PROVIDER_TITLE_FILESYSTEM_ID: translate('Upload a photo of your Identity Document', 'ui.kyc'),
        PROVIDER_TITLE_VIEW_ID: translate('Check the preview of your Identity Document', 'ui.kyc'),
        QUALUTY_ISSUE_DETECTED: translate('Quality Issues Detected!', 'ui.kyc'),
        REQUIRED_ACTION_CLOSE: translate('Close', 'ui.common'),
        RESULTS_HIGH_LEVEL_ABORTED: translate('Aborted', 'ui.kyc'),
        RESULTS_HIGH_LEVEL_EXPIRED: translate('Expired', 'ui.kyc'),
        RESULTS_HIGH_LEVEL_NOT_ACCEPTED: translate(
            'Your document did not pass the automatic ID check. Please try to upload a different document or contact our customer support team for further information about the decline reason.',
            'ui.kyc',
        ),
        RESULTS_HIGH_LEVEL_NOT_SUPPORTED: translate(
            'Your document did not pass the automatic ID check. Please try to upload a different document or contact our customer support team for further information about the decline reason.',
            'ui.kyc',
        ),
        RESULTS_HIGH_LEVEL_NOTSUPPORTED: translate(
            'Your document did not pass the automatic ID check. Please try to upload a different document or contact our customer support team for further information about the decline reason.',
            'ui.kyc',
        ),
        RESULTS_HIGH_LEVEL_PASSED: translate('Documentcapture is completed!', 'ui.kyc'),
        RESULTS_HIGH_LEVEL_REFER: translate(
            'Your ID document did not pass the automatic ID check and has been sent to manual review. We’ll update the status here and also shoot you an email once the manual review has been completed.',
            'ui.kyc',
        ),
        RESULTS_HIGH_LEVEL_UNDEFINED: translate(
            'Your document did not pass the automatic ID check. Please try to upload a different document or contact our customer support team for further information about the decline reason.',
            'ui.kyc',
        ),
        SCANNER_TITLE_PROCESSING: translate('Verifying ...', 'ui.kyc'),
        SCANNER_TITLE_SCANNING: translate('Scanning ...', 'ui.kyc'),
        TRIPLESCAN_GUIDANCE_TRYAGAIN: translate('Try again', 'ui.kyc'),
        VALIDATION_FAILED_REASON_DOC_EXPIRED: translate('- document expired.', 'ui.kyc'),
        VALIDATION_FAILED_REASON_DOC_UNKNOWN: translate('- document unknown.', 'ui.kyc'),
        VALIDATION_FAILED: translate('Validation failed', 'ui.kyc'),
        VERIFYING_TITLE: translate('Verifiying ...', 'ui.kyc'),
        VIEW_RETRY: translate('Change photo', 'ui.kyc'),
        VIEW_UPLOAD: translate('Continue', 'ui.account'),
    };
}
