import React from 'react';
import Wrapper from './styles';

interface Props {
    unexpectedError: any;
}

export default function GeocomplyFeedbackErrorsUnexpectedError({ unexpectedError }: Props) {
    if (!unexpectedError) {
        return null;
    }

    return (
        <Wrapper>
            <div className="unexpected-error">{unexpectedError.message}</div>
        </Wrapper>
    );
}
