import React from 'react';
import { BET_TYPE } from '../../../../services/virtual-sports/constants';
import { useStoreWithSelector } from '../../../../stores';
import Loader from '../../../loader/Loader';
import UiAlert from '../../../ui/alert/UiAlert';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import VirtualSportsBetslipOutcomes from '../outcomes/VirtualSportsBetslipOutcomes';
import VirtualSportsBetslipStakeInput from '../stake-input/VirtualSportsBetslipStakeInput';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import { isMobile } from '../../../../services/browser';
import { getRoute, useRouter } from '../../../../services/router';
import { getActiveCurrency } from '../../../../services/currency';
import classNames from 'classnames';
import { getMinStake } from '../../../../services/sports/limits';
import { virtualSports } from '../../../../stores/virtual-sports/virtual-sports';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    marketId: string;
}

export default function VirtualSportsBetslipMarket({ marketId }: Props) {
    const { navigateTo } = useRouter();
    const [marketInfo] = useStoreWithSelector(virtualSports.betslipMarketInfoById, (state) => state[marketId]);
    const [{ betType, stakeByMarketId }] = useStore(virtualSports.betslipUserState);
    const [betslipPlacingState] = useStore(virtualSports.betslipPlacingState);
    const { receipt, betslipErrors, isLoading } = betslipPlacingState;
    const betslipError = betslipErrors[marketId];
    const { gameWeek } = marketInfo || {};
    const [gameWeekInfo] = useStoreWithSelector(virtualSports.gameWeekInfo, (state) => state[gameWeek]);
    const { bettingStatus } = gameWeekInfo || {};
    const isDisabled = isLoading || bettingStatus === 'SUSPENDED';

    if (!marketInfo) {
        return <Loader />;
    }

    const translatedError = getTranslatedError(betslipError);
    function getTranslatedError(err) {
        if (err === 'MIN_STAKE') {
            const currency = getActiveCurrency();
            const minStake = getMinStake();
            return stakeByMarketId[marketId] ? translate('MIN_STAKE', 'ui.sportsbook', [minStake, currency]) : '';
        }
        return translate(err, 'ui.sportsbook');
    }

    function removeMarketFromBetslip() {
        virtualSports.betslipMarketIdToOutcomeId.set((state) => delete state[marketId] && state);
        virtualSports.betslipMarketInfoById.set((state) => {
            delete state[marketId];
        });

        if (Object.entries(virtualSports.betslipMarketIdToOutcomeId.state).length === 0 && isMobile()) {
            navigateTo(getRoute('virtual-sports'));
        }
    }

    return (
        <Wrapper>
            <UiCloseButton className="remove-market" onClick={removeMarketFromBetslip} />

            {!receipt && (
                <div
                    className={classNames({
                        error: Boolean(betslipError),
                        marketContainer: true,
                    })}
                >
                    <div className="bet-header">
                        <div className="match-name">{marketInfo.matchName}</div>
                        <div className="market-name">
                            {marketInfo.name} {marketInfo.line ? marketInfo.line : ''}
                        </div>
                    </div>

                    <VirtualSportsBetslipOutcomes marketInfo={marketInfo} isDisabled={isDisabled} />

                    {betType === BET_TYPE.SINGLE && <VirtualSportsBetslipStakeInput marketId={marketId} />}
                </div>
            )}

            {translatedError && <UiAlert failure>{translatedError}</UiAlert>}
        </Wrapper>
    );
}
