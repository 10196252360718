import React from 'react';
import classNames from 'classnames';
import Wrapper from './styles';
import { stores, useStoreWithSelector } from '../../../../../stores';
import {
    getMarketTypeInfo,
    getParlayCardOutcomeName,
    ParlayCardBetslipOutcome,
    removeOutcomeFromBetslip,
} from '../../../../../services/parlay-card/parlay-card';
import UiAlert from '../../../../ui/alert/UiAlert';
import { translate } from '../../../../../services/translate';
import {
    PARLAY_CARD_SELECTION_LINE_CHANGED,
    PARLAY_CARD_SELECTION_NOT_AVAILABLE,
} from '../../../../../services/sports/constants';
import Svg from '../../../../svg/Svg';
import { isMobile } from '../../../../../services/browser';

interface Props {
    outcome: ParlayCardBetslipOutcome;
    handicapTeaserPoints: number;
    totalsTeaserPoints: number;
    isDisabled: boolean;
    hasLineChanged: boolean;
}

function SportParlayCardBetslipOutcome({
    outcome,
    handicapTeaserPoints,
    totalsTeaserPoints,
    isDisabled,
    hasLineChanged,
}: Props) {
    const { marketId, position } = outcome;

    const [marketInfo] = useStoreWithSelector(stores.sports.parlayCard.marketInfo, (state) => state[marketId], [
        marketId,
    ]);

    if (!marketInfo) {
        return null;
    }

    const { view_type, match_name, market_type_name, result_keys } = marketInfo;

    const { isTotal, isHandicap } = getMarketTypeInfo(result_keys, view_type);
    const teaserPoints = isTotal ? totalsTeaserPoints : isHandicap ? handicapTeaserPoints : 0;

    const outcomeName = getParlayCardOutcomeName(marketInfo, outcome, teaserPoints);

    function onClick() {
        removeOutcomeFromBetslip(outcome.id, marketId);

        if (Object.entries(stores.sports.parlayCard.betSlipMarketIdToOutcomeIds.state).length === 0 && isMobile()) {
            stores.isParlayBetslipOpen.set(false);
        }
    }

    return (
        <Wrapper>
            <div className="selection-row">
                <button
                    className={classNames('selection-button', {
                        disabled: isDisabled,
                    })}
                    onClick={() => onClick()}
                >
                    <span className="value">{position}</span>
                </button>
                <div
                    className={classNames('market-info', {
                        disabled: isDisabled,
                    })}
                >
                    <span className="outcome-name">{outcomeName}</span>
                    {(isTotal || !isHandicap) && <span className="market-type-name">{market_type_name}</span>}
                    {(isHandicap || isTotal) && <span className="match-name">{match_name}</span>}
                </div>

                <div className="remove-icon" onClick={() => onClick()}>
                    <Svg icon="crossmark" size={1.125} />
                </div>
            </div>
            {!isDisabled && hasLineChanged && (
                <UiAlert info className="error-alert">
                    {translate(PARLAY_CARD_SELECTION_LINE_CHANGED, 'ui.betslip')}
                </UiAlert>
            )}
            {isDisabled && (
                <UiAlert failure className="error-alert">
                    {translate(PARLAY_CARD_SELECTION_NOT_AVAILABLE, 'ui.betslip')}
                </UiAlert>
            )}
        </Wrapper>
    );
}

export default React.memo(SportParlayCardBetslipOutcome);
