import React, { useContext } from 'react';
import { DocumentCaptureContext } from '../../../../../../contexts/document-capture/DocumentCaptureContext';
import { translate } from '../../../../../../services/translate';
import UiButton from '../../../../../ui/button/UiButton';
import UiAlert from '../../../../../ui/alert/UiAlert';

export default function AccountVerificationDocumentCapturePreScreenMobile() {
    const { setIsPreScreenOpen } = useContext(DocumentCaptureContext);

    return (
        <div className="verification-options">
            <div className="verification-steps">
                <div className="step">
                    <p>1. {translate('Scan your ID (both sides)', 'ui.account')}</p>
                </div>
                <div className="step">
                    <p>2. {translate('Take a selfie', 'ui.account')}</p>
                </div>
            </div>

            <UiAlert info>
                {translate(
                    'Please make sure that you have allowed access to your camera in your browser in order to complete the ID verification',
                    'ui.account',
                )}
            </UiAlert>

            <div className="prescreen-button-container">
                <UiButton block color="primary" onClick={() => setIsPreScreenOpen(false)}>
                    {translate('start verification', 'ui.account')}
                </UiButton>
            </div>
        </div>
    );
}
