import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';

interface Props {
    participants?: number;
}

export default function PromotionParticipantsBadge({ participants }: Props) {
    if (!(participants && participants >= 50)) {
        return null;
    }

    return (
        <Wrapper>
            <div className="prefix-wrapper">{translate('participating', 'ui.bonuses').toUpperCase()}</div>
            <div className="count-wrapper">{participants}</div>
        </Wrapper>
    );
}
