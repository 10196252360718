import React from 'react';
import Wrapper from './styles';
import { translate } from '../../services/translate';
import UiBrandLine from '../ui/brand-line/UiBrandLine';
import { getClient } from '../../services/environment';
import { GlobalStyles } from '../../theme/global';
import { environment } from '../../stores/environment/environment';
import { useStore } from '../../hooks/useStore';

export default function AppNotAccessible() {
    const [{ IMAGES_HOST }] = useStore(environment);
    const client = getClient();

    return (
        <Wrapper>
            <GlobalStyles />
            <div className="app-not-accessible">
                <div className="app-not-accessible-header">
                    <img src={`${IMAGES_HOST}${client}/common/mx.png`} alt="mexico" />
                </div>
                <div className="app-not-accessible-description">
                    <div className="app-not-accessible-logo">
                        <img
                            src={`${window.coolb2b?.baseUrl || ''}/assets/images/logo/${client}-compact.svg`}
                            alt={client}
                        />
                    </div>
                    <div>
                        {translate(
                            'The app is not accessible from outside Mexico, but you can still use our website',
                            'ui.app-not-accessible-outside-mexico.description',
                        )}
                    </div>
                </div>

                <UiBrandLine center />
            </div>
        </Wrapper>
    );
}
