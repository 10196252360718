import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../../../styles/utils';

interface Props {
    percentage: number;
}

export default function SportMatchTurnoverChart({ percentage, ...rest }: Props) {
    const total = 158;

    const pieSlice = (percentage * total) / 100;

    const chartStyle = {
        strokeDasharray: `${pieSlice}, ${total}`,
    };

    return (
        <Wrapper {...filterStyleProps({ ...rest })}>
            <div className="percentage">{percentage.toFixed()}%</div>

            <svg>
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="var(--color-turnover)" />
                        <stop offset="100%" stopColor="var(--color-turnover)" />
                    </linearGradient>
                </defs>
                <circle r={25} cx={40} cy={40} style={chartStyle} stroke="url(#gradient)" />
            </svg>
        </Wrapper>
    );
}
