import styled from 'styled-components';

export default styled.div`
    position: relative;
    p {
        font-size: 0.7rem;
        margin: 1rem 0;
        text-align: center;
    }
    .betslip-receipt {
        margin-top: var(--spacing-16);
        border: var(--ticket-border);
        border-radius: var(--ui-radius);
        .match-details-container {
            display: flex;
            flex-direction: column;
            padding: 0.75rem;
            .match-name {
                font-size: 0.9rem;
                color: var(--font-color-primary);
                margin-bottom: 0.3rem;
            }
            .outcome-row {
                font-size: 0.8rem;
                .market-name {
                    color: var(--match-secondary-color);
                }
                .arrow {
                    margin: 0 0.5rem;
                }
                .outcome-name {
                    color: var(--color-odds);
                }
            }
        }
    }
`;
