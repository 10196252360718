import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

const matchWidthDesktop = '425px';
const matchWidthLaptop = '280px';
const matchWidthLaptopSmall = '370px';

type StyledProps = {
    $isCompactViewEnabled: boolean;
    $isPublic?: boolean;
    $isTicketDetailsVisible: boolean;
};

export default styled.div<StyledProps>`
    position: relative;

    background: var(--ticket-bg);
    border: var(--ticket-border);
    border-radius: var(--ui-radius);

    margin-bottom: var(--spacing-4);

    cursor: pointer;
    ${({ $isTicketDetailsVisible }) =>
        $isTicketDetailsVisible &&
        css`
            background-color: transparent;
            border: var(--button-active-border);
            border-width: 2px;
        `};

    &:hover {
        .copy-ticket-button {
            display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'none' : 'flex')};
            @media ${mediaQuery.isPhone} {
                display: initial;
            }
        }
    }

    .bet-ticket {
        display: flex;
        align-items: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'flex-start' : 'center')};
        flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'column' : 'row')};
    }

    .bet-sport {
        display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'none' : 'flex')};
        justify-content: center;
        align-items: center;

        min-width: 90px;
        @media ${mediaQuery.isLaptop} {
            min-width: 65px;
        }
    }

    .bet-match {
        display: flex;
        align-items: center;
        width: 100%;
        min-width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : matchWidthDesktop)};
        padding: var(--spacing-8) 0;
        @media ${mediaQuery.isLaptop} {
            min-width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : matchWidthLaptop)};
        }
        @media (max-width: 1300px) {
            max-width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : matchWidthLaptopSmall)};
        }
        @media ${mediaQuery.isTablet} {
            width: 60%;
        }
        @media ${mediaQuery.isPhone} {
            min-width: initial;
            width: 100%;
        }
    }
    .bet-location {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 130px;

        ${({ $isCompactViewEnabled }) =>
            $isCompactViewEnabled &&
            css`
                position: absolute;
                right: var(--spacing-4);
                top: var(--spacing-4);
                width: auto;
            `};

        .flag-icon {
            margin: 0 0 0.2rem 0;
        }
        .location-city {
            font-size: 0.75rem;
            color: var(--ticket-text-color-secondary);
            text-align: center;

            display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'none' : 'block')};
            margin-top: 0.4rem;
            @media ${mediaQuery.isLaptop}, (max-width: 1300px) {
                display: none;
            }
        }
    }

    .copy-ticket-button {
        position: absolute;
        right: 450px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 2;

        display: none;

        animation: fadeIn 200ms linear;
        @media ${mediaQuery.isLaptop} {
            top: 50%;
            right: var(--spacing-12);
        }
        @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
            display: block;
            right: var(--spacing-12);
        }
        > button {
            @media ${mediaQuery.isLaptop}, ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
                font-size: 2rem;
                border-radius: 200px;
                width: 50px;
                height: 50px;
            }
        }
    }
    .bet-status {
        margin-left: auto;
    }

    .manual-acceptance-status {
        display: flex;
        align-items: center;
        justify-content: center;

        position: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'absolute' : 'relative')};
        top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '-0.5rem' : '')};
        left: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.5rem' : '')};
        height: 20px;

        border: 1px solid var(--surface-level-1-line-color);
        padding: 0 0.4rem;
        margin-top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : '0.4rem')};
        border-radius: 100px;
        background-color: var(--surface-level-1-bg);

        font-size: 0.75rem;
        text-align: center;
        color: var(--font-color-secondary);
        line-height: 1;

        @media ${mediaQuery.isPhone} {
            margin: 0 0 0 0.4rem;
        }
    }
    .bet-info {
        display: flex;
        align-items: center;
        flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : 'column')};
        justify-content: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : 'center')};

        margin: 0 0.8rem;
        > *:not(:last-of-type) {
            margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0.4rem 0 0' : '0 0 0.4rem 0')};
        }

        .bet-dates {
            font-size: 0.75rem;
            color: var(--ticket-text-color-secondary);
            text-align: center;
            white-space: nowrap;
            margin-top: var(--spacing-8);
        }
    }
`;
