import round from 'lodash/round';
import React, { useEffect, useState } from 'react';

import {
    calculateTotalOdds,
    getFixedStake,
    hasBetSlipChangedFromOdds,
    validateBetslip,
} from '../../../../services/virtual-sports/betslip';
import { translate } from '../../../../services/translate';
import { useStoreWithSelector } from '../../../../stores';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import Wrapper from './styles';
import { convertOdds } from '../../../../services/odds-format';
import { BET_TYPE } from '../../../../services/virtual-sports/constants';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { virtualSports } from '../../../../stores/virtual-sports/virtual-sports';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    marketId: string | null;
}

export default function VirtualSportsBetslipStakeInput({ marketId }: Props) {
    const [userState, setUserState] = useStore(virtualSports.betslipUserState);
    const [betSlipMarketIdToOutcomeId] = useStore(virtualSports.betslipMarketIdToOutcomeId);
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(virtualSports.betslipPlacingState);
    const [oddsIds] = useStoreWithSelector(virtualSports.betslipOddsByOutcomeId, hasBetSlipChangedFromOdds);
    const [maxWin, setMaxWin] = useState(0);
    const [totalOdds, setTotalOdds] = useState(0);
    const { stakeByMarketId, betType } = userState;
    const [stake, setStake] = useState();

    function stakeChange(stake) {
        setUserState((state) => ({
            ...state,
            stakeByMarketId: { ...stakeByMarketId, [String(marketId)]: getFixedStake(stake) },
        }));
        const { isValid, betslipErrors } = validateBetslip();
        setStake(getFixedStake(stake));
        setBetSlipPlacingState((state) => ({ ...state, betslipErrors, isPlaceBetButtonDisabled: !isValid }));
    }

    function calculateTotalOddsMaxWin() {
        const newTotalOdds = calculateTotalOdds(marketId);
        setTotalOdds(newTotalOdds);

        if (!stake) {
            return;
        }

        const potentialReturn = newTotalOdds * Number(stake);
        virtualSports.potentialReturn.set(potentialReturn);
        setMaxWin(round(potentialReturn, 2));
    }

    useEffect(calculateTotalOddsMaxWin, [stake, oddsIds, betSlipMarketIdToOutcomeId]);

    const loading = betSlipPlacingState.isLoading || betSlipPlacingState.needsConfirm;
    return (
        <Wrapper>
            <div className="stake">
                <UiFormLabel>{translate('Your stake', 'ui.sportsbook')} →</UiFormLabel>
                <UiAnimate animationIn="bounceIn">
                    <UiFormInput type="text" onValueChange={stakeChange} disabled={loading} name="stakeInput" />
                </UiAnimate>
            </div>

            {betType === BET_TYPE.COMBO && (
                <p className="total-odds">
                    <span>{translate('Total odds', 'ui.betslip')} →</span>
                    <span className="value">{convertOdds(totalOdds)}</span>
                </p>
            )}

            {maxWin > 0 && (
                <p className="potential-return">
                    <span>{translate('Potential return', 'ui.betslip')} →</span>
                    <span className="value">{formattedAmountWithCurrency(maxWin)}</span>
                </p>
            )}
        </Wrapper>
    );
}
