import React, { useEffect, useState } from 'react';
import mapValues from 'lodash/mapValues';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export const withMenuLink = (Component, reference) => (props) => {
    const { children, className, onClick = () => {} } = props;
    const [menuPage, setMenuPage] = useStore(stores.cms.menuPage);
    const [updatedClassName, setUpdatedClassName] = useState(className);

    useEffect(() => {
        if (menuPage[reference]) {
            setUpdatedClassName((className) => `${className} active `);
        } else {
            setUpdatedClassName((className) => className.replace(' active ', ''));
        }
    }, [menuPage]);

    function updatedOnClick(event) {
        onClick(event);

        setMenuPage((menuPage) => ({
            ...mapValues(menuPage, () => false),
            [reference]: true,
        }));
    }

    return (
        <Component {...props} onClick={updatedOnClick} className={updatedClassName}>
            {children}
        </Component>
    );
};
