import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100dvh;
    width: 100%;
    color: white;

    .app-not-accessible {
        &-header {
            font-size: 18px;

            a {
                color: var(--font-link-color);
                text-decoration: underline;
                text-underline-offset: 2px;
                font-size: 24px;
            }
        }

        &-description {
            display: flex;
            align-items: center;
            gap: var(--spacing-12);

            border-radius: var(--ui-radius);
            border: 1px solid var(--color-error);
            margin: var(--spacing-32);
            padding: var(--spacing-8);

            font-size: var(--font-12);
            text-align: left;

            max-width: 289px;
        }

        &-logo {
            background-color: var(--surface-level-2-bg);
            border: var(--surface-level-2-border);
            border-radius: var(--ui-radius);
            box-shadow: var(--ui-shadow);
            padding: var(--spacing-4);

            img {
                max-width: none;
                width: 42px;
            }
        }
    }
`;
