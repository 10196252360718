import styled from 'styled-components';

export default styled.div`
    max-width: 500px;
    margin: 0 auto;

    section {
        padding-bottom: 16px;
    }

    h1 {
        margin-top: 16px;
        border-bottom: 2px solid #fff;
        padding-bottom: 8px;
    }

    code {
        display: block;
        background-color: #333;
        border: 1px solid #444;
        border-radius: 5px;
        padding: 16px;
        margin: 16px 0;
    }
`;
