import React from 'react';
import { stores } from '../../../stores';
import { scrollToTop } from '../../../services/util';

export const withOpenModal = (Component, modalName) => (props) => {
    const { onClick = () => {} } = props;

    function updatedOnClick(event) {
        onClick(event);

        scrollToTop();
        stores.cms.modals.set((modals) => ({
            ...modals,
            [modalName]: true,
        }));
    }

    return (
        <Component {...props} onClick={updatedOnClick}>
            {props.children}
        </Component>
    );
};
