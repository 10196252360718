import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { stores, useStoreWithSelector } from '../../../../stores';
import { fetchSidebetMarkets, getMatchDataMinimal } from '../../../../microservices/sbgate';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import { translate } from '../../../../services/translate';
import { MATCH_STATUS } from '../../../../services/sports/constants';
import { CategoryMatch, MarketTypeCategory, SidebetMarketsResponse } from '../../../../services/sports/types';
import { getRecommendedSideBet, sendRecommendedSidebetStat } from '../../../../microservices/recommendations';
import SportMatchMarketsTopMarketsMarket from '../../match/markets/top-markets/market/SportMatchMarketsTopMarketsMarket';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import { logger } from '../../../../services/logger';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    marketId: number | 'null';
}

export default function SportBetslipRecommendation({ marketId: marketIdBetOn }: Props) {
    const { layout } = useSportsUserSettings();
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const [recommendedMarketTypeIds, setRecommendedMarketTypeIds] = useState<number[]>([]);
    const [recommendedMarketType, setRecommendedMarketType] = useState<MarketTypeCategory>();
    const [match, setMatch] = useState<CategoryMatch>();
    const [matchId, setMatchId] = useState<number>();
    const [ticketId] = useStoreWithSelector(
        stores.sports.betSlipPlacingState,
        (state) => state.receiptById[marketIdBetOn],
    );
    const [marketId, setMarketId] = useState<number>();

    useEffect(() => {
        if (marketIdBetOn === 'null') {
            return;
        }
        const matchInfo = marketInfoById[marketIdBetOn];
        const betProduct = matchInfo?.match_status === MATCH_STATUS.LIVE ? 'LIVE' : 'PREMATCH';
        setMatchId(matchInfo.match_id);
        getRecommendedSideBet(matchInfo.match_id, matchInfo.sport_id, betProduct).then(setRecommendedMarketTypeIds);
    }, [marketIdBetOn]);

    useEffect(() => {
        if (recommendedMarketTypeIds && matchId) {
            getOneRecommendedMarket(matchId);
        }
    }, [recommendedMarketTypeIds, layout]);

    if (!recommendedMarketType || !match) {
        return null;
    }

    async function getOneRecommendedMarket(matchId: number) {
        let sidebetMarketsResponse: SidebetMarketsResponse;
        let match: CategoryMatch;
        try {
            match = await getMatchDataMinimal(matchId);
            sidebetMarketsResponse = await fetchSidebetMarkets({ matchId });
        } catch (e) {
            logger.error('SportBetslipRecommendation', 'getOneRecommendedMarket', e);
            return;
        }
        const { markets } = sidebetMarketsResponse;

        const recommendedMarketType = markets.find((market) =>
            recommendedMarketTypeIds.includes(market.market_type_id),
        );

        if (!recommendedMarketType) {
            return;
        }
        const match2 = { ...match };
        if (!match.markets_total_count) {
            match2.markets_total_count = 0;
        }
        setMatch({
            ...match2,
            markets: recommendedMarketType.markets.map((market) => ({
                ...market,
                view_type: recommendedMarketType.view_type,
            })),
        });
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.UPSELL_BET_IN_BETSLIP_SHOWN);
        setRecommendedMarketType({
            id: recommendedMarketType.market_type_id,
            name: recommendedMarketType.translatedName,
            sport_id: match.sport,
            view_type: recommendedMarketType.view_type,
        });
        await sendRecommendedSidebetStat(ticketId, recommendedMarketType.market_type_id);
    }

    function takeRecommendedBet() {
        stores.sports.betSlipUserState.set((state) => ({ ...state, copiedFrom: ticketId }));
        stores.marketing.upsellRecommendation.set((state) => ({
            ...state,
            matchId: matchId,
            marketId: marketId,
        }));
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.UPSELL_BET_IN_BETSLIP_COPIED);
    }

    return (
        <Wrapper>
            <div className="sidebet-recommendations animated fadeInUp">
                <div className="sidebet-recommendations-label">
                    {translate('Recommended sidebet', 'betslip.recommendation')}
                </div>
                <div className="sidebet-market-name">{recommendedMarketType.name}</div>
                <div className="sidebet-outcomes" onClick={takeRecommendedBet}>
                    <SportMatchMarketsTopMarketsMarket
                        marketType={recommendedMarketType}
                        match={match}
                        onMarketIdChanged={setMarketId}
                        isEuropeanLayoutForced
                    />
                </div>
            </div>
        </Wrapper>
    );
}
