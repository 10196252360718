import styled from 'styled-components';

export default styled.div`
    text-align: right;
    position: sticky;
    background: var(--betslip-bg);
    top: 0;
    z-index: 2;
    button {
        padding: 0.4rem;
        flex: 1;
    }
    .betslip-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: var(--spacing-16) 0;
        border-bottom: 1px solid var(--surface-level-2-line-color);
    }
    .header-title {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}
        > *:first-of-type {
            margin-right: var(--spacing-8);
        }
        .cards-count {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 1.625rem;
            height: 1.625rem;

            border-radius: 100px;
            border: 2px solid var(--odds-text-color);

            font-size: var(--font-14);
            color: var(--odds-text-color);
        }
    }
    .clear-all {
        font-size: 0.75rem;
        color: var(--font-color-secondary);
        cursor: pointer;
        margin-left: auto;
    }
`;
