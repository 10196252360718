import React from 'react';
import Wrapper from './styles';
import { SaddleTowelColors, defaultSaddleTowelColor } from '../../../../services/horse-racing/horse-racing';

interface Props {
    bettingInterest: number;
    saddleCloth: string;
    breed?: string;
    postPosition?: number | string;
}

export default function HorseRacingUiHorseNumber({
    saddleCloth,
    bettingInterest,
    breed = 'Thoroughbred',
    postPosition,
}: Props) {
    const saddleColor = SaddleTowelColors[breed]?.[bettingInterest] ?? defaultSaddleTowelColor;

    if (!Boolean(saddleColor)) {
        return null;
    }

    return (
        <Wrapper saddleColor={saddleColor}>
            <span className="number">{saddleCloth}</span>
            {Boolean(postPosition) && !isNaN(Number(postPosition)) ? (
                <span className="post-position">PP:{postPosition}</span>
            ) : (
                postPosition
            )}
        </Wrapper>
    );
}
