import styled from 'styled-components';

export default styled.div`
    .close-button {
        position: absolute;
        top: ${({ theme }) => theme.layout.gutter};
        right: ${({ theme }) => theme.layout.gutter};
    }
    .product-column {
        display: flex;
        flex-direction: row;
    }
    .annual-report-header {
        display: flex;
        align-items: center;
        margin-bottom: ${({ theme }) => theme.layout.gutter};
    }
    .responsible-gaming-options {
        margin: 0 0 ${({ theme }) => theme.layout.gutter} 0;
        .button-text {
            white-space: pre-wrap;
        }
    }
    .responsible-gaming-button {
        display: flex;
        align-items: center;
        svg {
            padding-right: 5px;
        }
    }
    .center-text {
        text-align: center;
    }
    p {
        margin: 0;
    }
`;
