import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    max-width: 100%;
    margin: 0 auto;
    padding: 0 ${({ theme }) => theme.layout.gutter};

    @media ${mediaQuery.isPhone} {
        padding: 0 ${({ theme }) => theme.layout.gutterMobile} 0px ${({ theme }) => theme.layout.gutterMobile};
    }

    .alert {
        display: flex;
        align-items: center;
        justify-content: center;
        .close-button {
            position: relative;
            @media ${mediaQuery.isPhone} {
                margin-left: 0.4rem;
            }
        }
        .text {
            flex-grow: 1;
        }
    }
`;
