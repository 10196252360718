import React from 'react';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import Wrapper from './styles';

interface Props {
    status?: string;
}

export default function SportTicketW2GAlert({ status }: Props) {
    if (!status) {
        return null;
    }

    return (
        <Wrapper>
            {status === 'PENDING' ? (
                <UiAlert warning center className="w2g-alert">
                    {translate('W-2G PENDING', 'ui.sportsbook')}
                </UiAlert>
            ) : (
                <UiAlert info center className="w2g-alert">
                    {translate('W-2G RESOLVED', 'ui.sportsbook')}
                </UiAlert>
            )}
        </Wrapper>
    );
}
