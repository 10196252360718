import React, { useCallback, useState } from 'react';
import { useForm } from '../../../../services/form';
import { translate } from '../../../../services/translate';
import { validators } from '../../../../services/validators';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import UiGroup from '../../../ui/group/UiGroup';

interface Props {
    registrationFlow?: string;
    isPasswordRequired?: boolean;
    emailForm: any;
    passwordForm: any;
}

export default function RegistrationFieldsEmailAndPassword({
    isPasswordRequired = false,
    emailForm,
    passwordForm,
}: Props) {
    const isPasswordInputVisible = isPasswordRequired;
    const form = useForm(stores.registration.form);
    const { email } = form.getValues();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const validatePassword = useCallback((value) => validators.password(value, email), [email]);
    return (
        <>
            <UiFormLabel>
                {translate(isPasswordInputVisible ? 'Email / Password' : 'Email', 'ui.registration')}
            </UiFormLabel>

            <UiFormInput
                {...emailForm}
                type="email"
                placeholder={translate('Email', 'ui.account')}
                validator={validators.registrationEmail}
            />

            {isPasswordRequired && (
                <UiGroup>
                    <UiFormInput
                        {...passwordForm}
                        type={isPasswordVisible ? 'text' : 'password'}
                        placeholder={translate('Password', 'ui.account')}
                        validator={validatePassword}
                        noAutocomplete
                    />

                    <UiButton
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        icon={isPasswordVisible ? 'eye-visible' : 'eye-invisible'}
                    />
                </UiGroup>
            )}
        </>
    );
}
