import React from 'react';
import Wrapper from './styles';
import sample from 'lodash/sample';
import classNames from 'classnames';

interface Props {
    isMainContent?: boolean;
}

export default function UiBlobLoader({ isMainContent }: Props) {
    const arrayOfColors = ['blue', 'orange'];
    const blobColor = `loading-blob-${sample(arrayOfColors)}`;

    return (
        <Wrapper>
            {!isMainContent && <div className={classNames('loading-blob', blobColor)} />}
            {isMainContent && (
                <div className="loading-blob-main-container">
                    <div className={classNames('loading-blob-main-1', blobColor)} />
                    <div className={classNames('loading-blob-main-2', blobColor)} />
                </div>
            )}
        </Wrapper>
    );
}
