import styled from 'styled-components';

import { lighten } from 'polished';

export default styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    color: var(--font-color-primary);

    .prefix-wrapper {
        margin: 2px 10px;
        font-size: 0.8rem;
        display: inline-block;
    }

    .count-wrapper {
        border-radius: 10px;
        background-color: ${({ theme }) => lighten(0.2, theme.box.background)};
        padding: 2px 10px;
        font-size: 1.2rem;
        display: inline-block;
    }
`;
