import React from 'react';
import UiBox from '../../../ui/box/UiBox';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import UiModal from '../../../ui/modal/UiModal';
import { stores } from '../../../../stores';
import AccountDataWithToken from '../with-token/AccountDataWithToken';
import { downloadDataForClosedUser } from '../../../../microservices/liberation';
import { useStore } from '../../../../hooks/useStore';

export default function AccountDataForClosedAccount() {
    const [token] = useStore(stores.gdprDataDownloadToken);
    const [isAccountDataDownloadModalOpen, setIsAccountDataDownloadModalOpen] = useStore(
        stores.modals.isAccountDataDownloadModalOpen,
    );

    if (!token) {
        return null;
    }

    return (
        <UiModal open={isAccountDataDownloadModalOpen} onClose={() => setIsAccountDataDownloadModalOpen(false)}>
            <UiBox>
                <UiCloseButton onClick={() => setIsAccountDataDownloadModalOpen(false)} className="close-button" />
                <AccountDataWithToken isButtonBlock={true} onDownload={() => downloadDataForClosedUser(token)} />
            </UiBox>
        </UiModal>
    );
}
