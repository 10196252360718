import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;
    > *:not(:last-child) {
        margin-right: 0.4rem;
    }
    .combo {
        color: var(--ticket-badge-combo-text-color);
        font-size: 0.8rem;
        margin-right: 0.4rem;
    }
    .campaign-badge {
        display: inline-block;
        flex-shrink: 0;

        max-width: 120px;
        border-radius: 100px;
        padding: 0.2rem 0.4rem;
        background-color: var(--color-highlight);

        font-size: 0.5rem;
        color: var(--color-on-highlight);
        letter-spacing: 1px;
        text-transform: uppercase;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
