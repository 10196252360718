import styled from 'styled-components';

type StyledProps = {
    $isList?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;
    gap: var(--spacing-20);
    padding: ${({ $isList }) => ($isList ? 'var(--spacing-12)' : '')};
    overflow: auto;
    .bonus-list-item,
    .bonus-list-title {
        white-space: nowrap;
    }
    .bonus-list-title {
        font-size: var(--font-10);
        color: var(--font-subtle-color);
        text-transform: uppercase;
        line-height: 25px;
        font-weight: var(--font-weight-medium);
        letter-spacing: 1px;
    }
    .bonus-list-item {
        position: relative;
        color: var(--color-highlight);
        font-size: var(--font-14);
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
        ${({ theme }) => theme.optimizeFontRendering};
        padding: var(--spacing-4) 0;
        cursor: pointer;
        &:before {
            content: '•';
            position: absolute;
            left: calc(var(--spacing-8) * -1);
        }
        &:hover {
            border-bottom: 1px dotted;
        }
    }
    .menu-label {
        font-size: 0.6rem;
        text-transform: uppercase;
        color: var(--font-color-primary);
        font-weight: var(--font-weight-bold);
        letter-spacing: 1px;
        user-select: none;
        white-space: nowrap;
        ${({ theme }) => theme.optimizeFontRendering};
    }
    .menu-disclaimer {
        font-size: 8px;
        margin-top: var(--spacing-4);
        color: var(--font-color-secondary);
    }
    .campaigns-list {
        display: ${({ $isList }) => ($isList ? 'flex' : 'flex')};
        flex-direction: ${({ $isList }) => ($isList ? 'row' : 'column')};
        min-width: 0;

        font-size: var(--font-14);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        white-space: nowrap;
        line-height: 1;

        margin-bottom: var(--spacing-4);
        > * {
            &:not(:first-child) {
                padding: ${({ $isList }) => ($isList ? '' : 'var(--spacing-8)')};
                margin-left: ${({ $isList }) => ($isList ? 'var(--spacing-20)' : '')};
            }
        }
        a {
            color: ${({ $isList }) => ($isList ? 'var(--color-highlight)' : '')};
            border-bottom: ${({ $isList }) => ($isList ? '1px dashed transparent' : '')};
            &:hover {
                border-color: var(--color-highlight);
            }
        }
    }
`;
