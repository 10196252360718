import styled from 'styled-components';

export default styled.div`
    background-color: var(--surface-level-1-bg);
    height: 100%;
    overflow-y: auto;
    width: 285px;
    padding: 0.8rem;
    .sport-menu-toggle {
        display: flex;
        justify-content: flex-start;
        margin-bottom: ${({ theme }) => theme.layout.gutter};
        height: 60px;
        padding: 0 0.8rem;
        > *:not(:last-of-type) {
            margin-right: 2rem;
        }
    }
    .sport-menu {
        .menu-label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-size: 0.6rem;
            text-transform: uppercase;
            color: var(--font-color-secondary);
            font-weight: var(--font-weight-bold);
            letter-spacing: 1px;
            user-select: none;
            ${({ theme }) => theme.optimizeFontRendering};

            margin: 1rem 0.875rem 1.6rem 0.4rem;

            svg {
                width: 12px;
                opacity: 0.4;
            }
        }
    }
`;
