import { useEffect } from 'react';
import { useRouter } from '../../../services/router';
import { copyTicketToBetslipByOutcomeIds } from '../../../services/sports/betslip';
import { useLimits } from '../../../services/sports/limits';
import { loadLocalSportsUserSettings, loadRemoteSportsUserSettings } from '../../../services/sports/user-settings';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export default function SystemSport() {
    const { outcomeId } = useRouter().queryParams;
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    useEffect(() => {
        if (outcomeId) {
            copyTicketToBetslipByOutcomeIds([outcomeId], null);
        }
    }, [outcomeId]);

    useEffect(() => {
        if (isAuthenticated) {
            loadRemoteSportsUserSettings();
        } else {
            loadLocalSportsUserSettings();
        }
    }, [isAuthenticated]);

    useLimits();

    return null;
}
