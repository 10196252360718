import styled from 'styled-components';

export default styled.div`
    margin-top: 1rem;
    .risk-free {
        background-color: #00bfff;
        box-shadow: none;
        &:hover {
            background-color: ${({ theme }) => theme.colors.highlight};
        }
    }
    .campaign {
        background-color: var(--color-highlight);
        box-shadow: none;
        &:hover {
            background-color: ${({ theme }) => theme.colors.warning};
        }
    }
`;
