import styled from 'styled-components';

export default styled.div`
    .qr-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-12);
        text-align: center;
        p {
            margin-top: 0;
        }
    }
`;
