import styled from 'styled-components';

export default styled.div`
    margin-left: calc(-1 * var(--spacing-24));
    margin-right: calc(-1 * var(--spacing-24));
    margin-bottom: calc(-1 * var(--spacing-24));

    .container {
        align-items: center;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        margin: 0 auto;
        max-width: 300px;
    }
`;
