import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    text?: string;
}

export default function UiLineSeparator({ text, ...rest }: Props) {
    return (
        <Wrapper {...filterStyleProps({ ...rest })}>
            <p className="line-separator">{text}</p>
        </Wrapper>
    );
}
