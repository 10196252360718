import { translate } from './translate';

export const TICKET_STATUS = {
    ALL: 'all',
    WON: 'WON',
    PENDING: 'PENDING',
    LOST: 'LOST',
    CONFIRMED: 'CONFIRMED',
    CANCELLED: 'CANCELLED',
    PUSHED: 'PUSHED',
    PARTIALLY_WON: 'PARTIALLY_WON',
    VOIDED: 'VOIDED',
    CASHOUT: 'CONFIRMED',
    MANUAL_ACCEPTANCE: 'MANUAL-ACCEPTANCE',
    MANUAL_ACCEPTANCE_REJECTED: 'MANUAL-ACCEPTANCE DENIED',
    MANUAL_ACCEPTANCE_DENIED: 'MANUAL_ACCEPTANCE_DENIED',
    CASHED: 'CASHED',
};

const excludedTicketStatusForSettled = [
    TICKET_STATUS.PENDING,
    TICKET_STATUS.MANUAL_ACCEPTANCE,
    TICKET_STATUS.MANUAL_ACCEPTANCE_REJECTED,
    TICKET_STATUS.MANUAL_ACCEPTANCE_DENIED,
];

export const TICKET_STATUS_SETTLED = Object.values(TICKET_STATUS)
    .filter((status) => !excludedTicketStatusForSettled.includes(status))
    .join(',');

export enum TicketMaInfoTranslationKey {
    MA_PENDING_INFO = 'MA_PENDING_INFO',
    MA_NON_PENDING_INFO = 'MA_NON_PENDING_INFO',
}

export const TICKET_MA_STATUS = {
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    PENDING: 'pending',
} as const;

type KeysOfMAStatus = keyof typeof TICKET_MA_STATUS;
export type ValuesOfMAStatus = typeof TICKET_MA_STATUS[KeysOfMAStatus];

export const TICKET_TYPE = {
    CASHOUT: 'cashout',
    CAMPAIGN: 'campaign',
    SINGLE: 'single',
    COMBO: 'combo',
    SYSTEM: 'system',
    LIVE: 'LIVE',
    BETBUILDER: 'betbuilder',
    COMBOCARD: 'comboCard',
};

export const TIME_FILTER = {
    TODAY: 'day',
    ALL: 'all',
    WEEK: 'week',
    MONTH: 'month',
    THREE_MONTHS: 'three_months',
};

export function getDateFilters() {
    return [
        {
            label: translate('All', 'ui.common'),
            time: TIME_FILTER.ALL,
        },
        {
            label: translate('Today', 'ui.common'),
            time: TIME_FILTER.TODAY,
        },
        {
            label: translate('This week', 'ui.common'),
            time: TIME_FILTER.WEEK,
        },
        {
            label: translate('This month', 'ui.common'),
            time: TIME_FILTER.MONTH,
        },
        {
            label: translate('Last 3 months', 'ui.common'),
            time: TIME_FILTER.THREE_MONTHS,
        },
    ];
}

export function getStatusFilters() {
    return [
        { label: translate('Pending', 'ui.tickets'), value: TICKET_STATUS.PENDING },
        {
            label: translate('Settled', 'ui.tickets'),
            value: TICKET_STATUS_SETTLED,
        },
        { label: translate('Won', 'ui.tickets'), value: TICKET_STATUS.WON },
        { label: translate('Partially won', 'ui.tickets'), value: TICKET_STATUS.PARTIALLY_WON },
        { label: translate('Lost', 'ui.tickets'), value: TICKET_STATUS.LOST },
        { label: translate('Cashed out', 'ui.sportsbook'), value: TICKET_TYPE.CASHOUT },
        { label: translate('Campaign', 'ui.tickets'), value: TICKET_TYPE.CAMPAIGN },
        {
            label: translate('Cancelled', 'ui.tickets'),
            value: [TICKET_STATUS.CANCELLED, TICKET_STATUS.PUSHED, TICKET_STATUS.MANUAL_ACCEPTANCE_DENIED].join(','),
        },
        { label: translate('All', 'ui.common'), value: TICKET_STATUS.ALL },
    ];
}

export function getTicketStatus(ticket) {
    const status = (ticket?.cashout_status || ticket?.status || '').toString().toLowerCase().replace(/-/g, '_');
    return {
        won: TICKET_STATUS.WON,
        lost: TICKET_STATUS.LOST,
        pending: TICKET_STATUS.PENDING,
        partially_won: TICKET_STATUS.PARTIALLY_WON,
        voided: TICKET_STATUS.VOIDED,
        cancelled: TICKET_STATUS.CANCELLED,
        pushed: TICKET_STATUS.PUSHED,
        confirmed: TICKET_STATUS.CONFIRMED,
        manual_acceptance: TICKET_STATUS.MANUAL_ACCEPTANCE,
        manual_acceptance_denied: TICKET_STATUS.CANCELLED,
        manually_cancelled: TICKET_STATUS.CANCELLED,
        6: TICKET_STATUS.WON,
        7: TICKET_STATUS.LOST,
        3: TICKET_STATUS.PENDING,
        9: TICKET_STATUS.CANCELLED,
    }[status];
}
