import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isCompactViewEnabled?: boolean;
};

export default styled.div<StyledProps>`
    .ticket-details {
        animation: fadeIn 200ms linear;
        display: flex;
        align-items: flex-start;
        position: relative;
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.5rem 0' : '0 1rem')};
        padding: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '1rem 0 0 0.4rem' : '1.25rem 0.5rem 0')};
        flex-direction: column;
        border-top: 1px dashed var(--ticket-selection-separator);
        @media ${mediaQuery.isPhone} {
            flex-direction: column;
            align-items: flex-start;
            padding: 0.7rem 1rem;
            margin: 0.5rem 0;
        }
        .match-info {
            display: flex;
            font-size: var(--font-12);
            color: var(--ticket-text-color-secondary);
            gap: 0.4rem;
            padding-bottom: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.5rem' : '1rem')};
        }

        .bet-info {
            display: flex;
            flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'row' : 'column')};
            align-items: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'center' : 'flex-end')};
            justify-content: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'flex-start' : 'center')};
            width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '')};
            @media ${mediaQuery.isPhone} {
                flex-direction: row;
                width: 100%;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 0.4rem;
            }
            .brand-logo {
                svg {
                    height: 8px;
                    margin-left: ${({ theme }) => theme.layout.gutter};
                }
            }
        }

        .bet-match {
            .match-name {
                @media ${mediaQuery.isLaptop} {
                    max-width: initial;
                }
                &.match-resulted {
                    pointer-events: none;
                    &:hover {
                        border-bottom: 1px dotted transparent;
                    }
                    .match-name-title {
                        ${({ $isCompactViewEnabled }) =>
                            !$isCompactViewEnabled &&
                            css`
                                display: flex;
                                width: 100%;
                                justify-content: space-between;
                            `}

                        @media ${mediaQuery.isPhone} {
                            border-bottom: 1px dotted transparent;
                            padding-bottom: 0;
                        }
                    }
                }
                .match-name-title span {
                    cursor: pointer;
                    border-bottom: 1px dotted transparent;
                    &:hover {
                        border-bottom: 1px dotted var(--font-color-subtle);
                    }
                    @media ${mediaQuery.isPhone} {
                        padding-bottom: 3px;
                    }
                }
            }
        }
        .total-odds {
            min-width: 50px;
            max-width: 65px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${({ theme }) => theme.colors.highlight};
            border: 1px solid ${({ theme }) => lighten(0.2, theme.box.background)};
            border-radius: var(--odds-radius);
            cursor: pointer;
            outline: none;
            letter-spacing: 1px;
            background-color: ${({ theme }) => lighten(0.1, theme.box.background)};
            padding: 0.4rem;
            margin-right: 0;
        }

        .bet-sport {
            display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'flex' : 'block')};
            align-items: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'center' : '')};
            margin-bottom: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.2rem' : '')};
            padding: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0' : '0 0.4rem')};
            width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '160px')};
            @media ${mediaQuery.isPhone} {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                order: 5;
            }
            .name {
                font-size: 0.7rem;
                text-transform: uppercase;
                letter-spacing: 1px;
                display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'none' : 'flex')};
                align-items: center;
                @media ${mediaQuery.isPhone} {
                    display: none;
                }
            }
            .league {
                font-size: 0.8rem;
                color: var(--font-color-subtle);
                display: flex;
                align-items: center;
                margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.6rem 0 0 0' : '0.4rem 0')};
                overflow: hidden;
                @media ${mediaQuery.isPhone} {
                    margin: 0.6rem 0 0 0;
                }
                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .sport-icon {
                    width: 18px;
                    margin-right: 0.6rem;
                }
            }
            .league-name {
                display: flex;
                flex-direction: column;
                margin: 0 0 0 0.4rem;
            }
        }
    }

    .totals-label {
        font-size: 0.5rem;
        font-weight: 500;
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.8rem 0 0 0' : '1rem')};
        color: var(--font-color-secondary);
        padding-left: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '' : '1.4rem')};
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 5px;
        @media ${mediaQuery.isPhone} {
            padding-left: var(--spacing-16);
        }
    }

    .ticket-totals {
        position: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'inherit' : 'relative')};
        z-index: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '2' : '')};
        top: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0' : '')};
        display: flex;
        align-items: center;
        width: 100%;
        padding: ${({ $isCompactViewEnabled }) =>
            $isCompactViewEnabled ? '0 0 0 0.2rem' : '0 0 var(--spacing-16) var(--spacing-16) '};
        border-radius: var(--ui-radius);
        justify-content: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'center' : '')};
        @media ${mediaQuery.isPhone} {
            position: inherit;
            z-index: 2;
        }
        .bet-totals {
            position: relative;
            width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '370px')};
            margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0.1rem 0.4rem 0.1rem' : '')};
            padding: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.5rem 0' : '')};
            order: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '3' : '')};
            align-items: center;
            font-size: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.8rem' : '1rem')};
            color: var(--font-color-subtle);
            display: flex;
            @media ${mediaQuery.isPhone} {
                font-size: 0.8rem;
                font-weight: 600;
                padding: 0.5rem 0.8rem 0.5rem;
                order: 3;
                width: 100%;
            }
            .total-stake {
                margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0.2rem 0 0.3rem' : '0 0.6rem 0')};
                color: ${({ theme }) => theme.colors.secondary};
            }
            .possible-return {
                color: var(--color-highlight);
                padding: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0 0 0.2rem' : '0 0 0 0.6rem')};
                &.ma {
                    color: ${({ theme }) => theme.colors.alert};
                }
                &.LOST,
                &.CANCELLED {
                    color: var(--font-color-subtle);
                }
            }
            .cashout-amount {
                color: ${({ theme }) => theme.colors.gold};
                border: none;
                margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0 0 0.2rem' : '0 0 0 0.5rem')};
            }
            .currency {
                font-size: 60%;
                margin-left: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0' : '0.2rem')};
            }
            .ticket-id {
                margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0 0 0.2rem' : '0 0.8rem 0 0')};
                color: var(--font-color-subtle);
                font-size: 0.7rem;
                border-radius: 100px;
                padding: 0.2rem 0.4rem;
                border: 1px solid ${({ theme }) => lighten(0.12, theme.box.background)};
                background-color: ${({ theme }) => lighten(0.08, theme.box.background)};
                box-shadow: var(--ui-shadow);

                ${({ $isCompactViewEnabled }) =>
                    !$isCompactViewEnabled &&
                    css`
                        position: absolute;
                        bottom: 100%;
                        left: 0;
                        font-size: 0.8rem;
                        color: var(--ticket-ticketid-color);
                        padding: var(--spacing-4);
                        background: none;
                        border: none;
                    `}
            }
            .total-odds {
                min-width: 65px;
                max-width: 65px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                color: ${({ theme }) => theme.colors.highlight};
                border: 1px solid ${({ theme }) => lighten(0.2, theme.box.background)};
                border-radius: var(--odds-radius);
                cursor: pointer;
                outline: none;
                letter-spacing: 1px;
                background-color: ${({ theme }) => lighten(0.1, theme.box.background)};
                margin-right: 0;
                padding: 0.4rem;
            }
        }
    }
    .cashout-button {
        margin-left: ${({ theme }) => theme.layout.gutter};
    }

    .brand-logo {
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.6rem 0 0 auto' : '0 0 0 0.8rem')};
        svg {
            height: 8px;
        }
    }

    .bet-match {
        flex: 1;
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0 0.4rem 0' : '')};

        .match-name {
            margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0.2rem 0 0' : '0 0 0.5rem 0')};
            display: flex;
            align-items: center;
            font-size: 1rem;
            flex-wrap: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'wrap' : '')};
            color: var(--font-color-primary);
            @media ${mediaQuery.isPhone} {
                font-size: 1.2rem;
            }
        }

        .bet-market {
            font-size: 0.8rem;
            color: var(--font-color-subtle);
            margin: 0.2rem 0.2rem 0 0;
            display: flex;
            align-items: flex-start;
            padding-bottom: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.2rem' : '1rem')};
        }
    }

    .bet-outcome {
        color: ${({ theme }) => theme.colors.highlight};
        margin: 0;
        white-space: initial;
        text-align: left;
        font-size: 0.8rem;
        order: 2;
        @media ${mediaQuery.isPhone} {
            font-size: 0.95rem;
        }
    }

    .match-result {
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.4rem 0 0 0' : '0.4rem 0.4rem 0.4rem')};
        text-align: right;
        ${({ $isCompactViewEnabled }) =>
            $isCompactViewEnabled &&
            css`
                margin: 0 0 0.5rem 0;
                position: absolute;
                top: 0.7rem;
                right: 0.5rem;
            `}
        @media ${mediaQuery.isPhone} {
            margin: 0 0 0.5rem 0;
            position: absolute;
            top: 0.7rem;
            right: 0.5rem;
        }

        .score {
            font-size: 0.8rem;
            padding: 0.2rem 0.4rem;
            margin: 0 0.1rem;
            border-radius: var(--ui-radius);
            color: var(--font-color-secondary);
            background-color: ${({ theme }) => lighten(0.2, theme.box.background)};
            border: 1px solid ${({ theme }) => lighten(0.3, theme.box.background)};
        }
    }

    .odds-and-type {
        display: flex;
        align-items: center;
        width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '')};
        gap: 0.5rem;
        color: var(--odds-text-color);
        margin-bottom: var(--spacing-16);
        .total-odds {
            font-size: 0.8rem;
            @media ${mediaQuery.isPhone} {
                font-size: 0.8rem;
            }
        }
        @media ${mediaQuery.isPhone} {
            display: flex;
            flex-direction: row;
        }
    }

    .bet-dates {
        font-size: 0.7rem;
        color: var(--font-color-subtle);
        width: 100%;
        max-width: 180px;
        text-align: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'left' : 'right')};
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0 0 0.4rem' : '0.4rem 0.1rem 0 0 ')};
        flex: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '1' : '')};
        @media ${mediaQuery.isPhone} {
            order: 1;
            text-align: left;
            flex: 1;
            margin: 0 0 0 0.4rem;
        }
    }

    .bet-event-name {
        font-size: 0.7rem;
        color: var(--font-color-subtle);
    }

    .bet-sharing {
        display: flex;
        align-items: center;
        flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'column' : '')};
        > * {
            width: 100%;
        }
        @media ${mediaQuery.isPhone} {
            flex-direction: column;
        }
        button {
            width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '')};
            @media ${mediaQuery.isPhone} {
                width: 100%;
            }
        }
    }

    .share-bet {
        flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'column' : 'row')};
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0' : '0 0.875rem')};
        @media ${mediaQuery.isPhone} {
            width: 100%;
            margin: 0;
            flex-direction: column;
        }

        .alert {
            margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.5rem 0' : '0.3rem 0')};
        }
    }

    .copy-ticket {
        animation: fadeIn 200ms linear;
        @media ${mediaQuery.isPhone} {
            margin-bottom: var(--spacing-16);
            padding: 0;
        }
    }
`;
