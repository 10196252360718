import React, { useMemo } from 'react';
import { getStatusFilters } from '../../../../services/bet-history';
import UiGroup from '../../../ui/group/UiGroup';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    selectedFilter: string;
    onFilterSelected: (status: string) => void;
    isCompact: boolean;
    className?: string;
}

export default function BetHistoryFiltersStatus(props: Props) {
    const [language] = useStore(stores.language);
    const { selectedFilter, isCompact, onFilterSelected = () => {} } = props;
    const statusFilters = useMemo(() => getStatusFilters(), [language]);
    const visibleStatusFilters = isCompact ? statusFilters.slice(0, 2) : statusFilters;
    const [{ isPhone }] = useStore(media);

    return (
        <UiGroup {...props} shouldOverflow={isPhone} layoutGutterInRem="0.25rem">
            {visibleStatusFilters.map((filter) => (
                <UiButton
                    size="small"
                    key={filter.label}
                    onClick={() => {
                        onFilterSelected(filter.value);
                    }}
                    selected={filter.value === selectedFilter}
                >
                    {filter.label}
                </UiButton>
            ))}
        </UiGroup>
    );
}
