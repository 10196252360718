import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    width: 100%;
    user-select: none;

    .divider {
        width: 100%;
        height: 4px;
        background-color: var(--surface-level-3-bg);
        border-radius: 2px;
        margin: 0 0.4rem;
        box-shadow: var(--ui-shadow);
        overflow: hidden;

        ::after {
            content: '';
            width: 0;
            height: 100%;
            background-color: ${({ theme }) => theme.colors.info};
            display: block;
            transition: width 0.2s;
        }

        &.active::after {
            width: 100%;
        }
    }

    .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &.clickable {
            .indicator {
                border: 2px solid ${({ theme }) => theme.colors.info};
            }

            :hover .indicator {
                cursor: pointer;
            }
        }

        &.active {
            .indicator {
                height: 2rem;
                width: 2rem;
                border: 8px solid ${({ theme }) => theme.colors.info};
            }

            .description {
                font-weight: var(--font-weight-medium);
                font-size: 1rem;
                bottom: -1.4rem;
            }
        }

        &.completed {
            .indicator {
                height: 1.8rem;
                width: 1.8rem;
                background-color: ${({ theme }) => theme.colors.info};
                &::after {
                    font-size: 1.5rem;
                }
            }

            .description {
                font-size: 0.8rem;
                font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
                bottom: -1.2rem;
            }
        }

        .indicator {
            box-shadow: var(--ui-shadow);
            background-color: var(--surface-level-3-bg);
            border-radius: 50%;
            height: 1.6rem;
            width: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.2s, width 0.2s, border-width 0.2s, border-size 0.2s;

            ::after {
                content: '✔';
                color: var(--surface-level-3-bg);
                font-size: 0;
                transition: font-size 0.2s;
            }
        }

        .description {
            text-transform: capitalize;
            position: absolute;
            text-align: center;
            bottom: -1rem;
            font-size: 0.6rem;
            font-weight: 100;
            white-space: nowrap;
            transition: font-size 0.2s, margin 0.2s;
        }
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
