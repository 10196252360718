import map from 'lodash/map';
import React, { useEffect } from 'react';
import { getFixedStake, hasBetSlipChangedFromOdds, validateBetslip } from '../../../../services/virtual-sports/betslip';
import { SYSTEM_BET_TYPE_BY_SYSTEM_KEY } from '../../../../services/virtual-sports/constants';
import {
    calculateSystemTotalPotentialReturn,
    calculateSystemTotalStake,
    getSystemCombinations,
} from '../../../../services/virtual-sports/system-bet-helpers';
import { translate } from '../../../../services/translate';
import { useStoreWithSelector } from '../../../../stores';
import UiFormGroup from '../../../ui/form/group/UiFormGroup';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import Wrapper from './styles';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { virtualSports } from '../../../../stores/virtual-sports/virtual-sports';
import { useStore } from '../../../../hooks/useStore';

export default function VirtualSportsBetslipSystemStakeInput() {
    const [userState, setUserState] = useStore(virtualSports.betslipUserState);
    const [placingState, setPlacingState] = useStore(virtualSports.betslipPlacingState);
    const [betSlipMarketIdToOutcomeId] = useStore(virtualSports.betslipMarketIdToOutcomeId);
    useStoreWithSelector(virtualSports.betslipOddsByOutcomeId, hasBetSlipChangedFromOdds);
    const { systemStakes, disableSystemIndividualUpdate } = userState;
    const { isLoading, needConfirm } = placingState;
    const change = (systemType) => (value) => {
        const stake = getFixedStake(value);
        const t_stake = { ...systemStakes };
        stake ? (t_stake[systemType] = stake) : delete t_stake[systemType];
        setUserState((state) => ({ ...state, systemStakes: { ...t_stake } }));
        const { isValid, betslipErrors = {} } = validateBetslip();
        setPlacingState((state) => ({ ...state, betslipErrors, isPlaceBetButtonDisabled: !isValid }));
    };

    const combCountToSystemType = {
        3: { withSingles: 'PATENT' },
        4: { noSingles: 'YANKEE', withSingles: 'LUCKY15' },
        5: { noSingles: 'CANADIAN', withSingles: 'LUCKY31' },
        6: { noSingles: 'HEINZ', withSingles: 'LUCKY63' },
        7: { noSingles: 'SUPER_HEINZ' },
        8: { noSingles: 'GOLIATH' },
    };

    useEffect(() => {
        setUserState((state) => ({ ...state, disableSystemIndividualUpdate: false }));
    }, [Object.values(betSlipMarketIdToOutcomeId).join()]);
    const systemBets = getSystemCombinations(betSlipMarketIdToOutcomeId);
    const countOfSystems = Object.keys(systemBets).length;
    const onBatchStakeSet = (singlesOrNot) => (value) => {
        const systemKeys = Object.keys(systemBets).filter((systemKey) => (singlesOrNot ? true : systemKey !== '1'));
        const stake = getFixedStake(value);
        const tempStakes = {};
        systemKeys.forEach((systemKey) => {
            tempStakes[SYSTEM_BET_TYPE_BY_SYSTEM_KEY[systemKey]] = stake;
        });
        setUserState((state) => ({
            ...state,
            systemStakes: { ...systemStakes, ...tempStakes },
            disableSystemIndividualUpdate: Boolean(value),
        }));
        const { isValid, betslipErrors = {} } = validateBetslip();
        setPlacingState((state) => ({ ...state, betslipErrors, isPlaceBetButtonDisabled: !isValid }));
    };
    const disable = isLoading || needConfirm;

    return (
        <Wrapper>
            <UiFormGroup>
                {map(systemBets, (betsOfType, systemKey) => {
                    const systemType = SYSTEM_BET_TYPE_BY_SYSTEM_KEY[systemKey];
                    return (
                        <div className="stake" key={systemType}>
                            <UiFormLabel>
                                {betsOfType.length} X {translate(systemType, 'ui.betslip')}
                            </UiFormLabel>
                            <UiAnimate animationIn="bounceIn">
                                <UiFormInput
                                    type="text"
                                    disabled={disableSystemIndividualUpdate || disable}
                                    onValueChange={change(systemType)}
                                    value={systemStakes[systemType]}
                                />
                            </UiAnimate>
                        </div>
                    );
                })}
                {combCountToSystemType[countOfSystems]
                    ? Object.keys(combCountToSystemType[countOfSystems]).map((withSinglesOrNotKey) => (
                          <div className="stake" key={withSinglesOrNotKey}>
                              <UiFormLabel>
                                  {translate(
                                      combCountToSystemType[countOfSystems][withSinglesOrNotKey],
                                      'ui.sportsbook',
                                  )}
                              </UiFormLabel>
                              <UiFormInput
                                  disabled={disable}
                                  onValueChange={onBatchStakeSet(withSinglesOrNotKey === 'withSingles')}
                              />
                          </div>
                      ))
                    : null}
            </UiFormGroup>
            <p className="total-stake">
                {translate('Total stake', 'ui.betslip')}: <span>{calculateSystemTotalStake(systemBets)}</span>
            </p>
            <p className="potential-return">
                {translate('Potential return', 'ui.betslip')}:{' '}
                <span>{calculateSystemTotalPotentialReturn(systemBets)}</span>
            </p>
        </Wrapper>
    );
}
