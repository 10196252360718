import React, { useEffect } from 'react';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export const withDynamicContent = (Component, reference) => (props) => {
    const { children } = props;
    const [dynamicContent, setDynamicContent] = useStore(stores.cms.dynamicContent);

    useEffect(() => {
        if (!(reference in dynamicContent)) {
            setDynamicContent((dynamicContent) => ({
                ...dynamicContent,
                [reference]: false,
            }));
        }
    }, []);

    if (dynamicContent[reference]) {
        return <Component {...props}>{children}</Component>;
    }
    return null;
};
