import React from 'react';
import Svg from '../../../svg/Svg';
import Wrapper from './styles';

interface Props {
    options: { value: string; label?: string }[];
    selectedOption?: string;
    onOptionSelected?: (option: string) => void;
}

export default function AccountVerificationOptionsList({
    options,
    selectedOption,
    onOptionSelected = () => void 0,
}: Props) {
    return (
        <Wrapper>
            {options?.map((option) => (
                <li
                    className={option.value === selectedOption ? 'selected' : undefined}
                    key={String(option.value)}
                    onClick={() => onOptionSelected(option.value)}
                >
                    <span>{option.label}</span>
                    {option.value === selectedOption && <Svg className="animated bounceIn" size={1} icon="check" />}
                </li>
            ))}
        </Wrapper>
    );
}
