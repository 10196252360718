import clone from 'lodash/clone';
import { stores } from '../../stores';
import { CategoryMatch, GroupedMarketType } from './types';
import { FoCategoryMarketType } from '@staycool/sbgate-types';
import { getStoreValue } from '../../stores/store/utils';

export function getFlatCategories(category) {
    return category.children.length ? category.children.flatMap(getFlatCategories).concat(category) : category;
}

export function getCategoryByCategoryId(categoryId) {
    const categories = getStoreValue(stores.sports.flatCategories);
    if (!categories) {
        return;
    }

    return categories.find((category) => category.id === Number(categoryId));
}

export function getSportCategoryIdByActiveRouteSlugs(prematchTree, routeSlugsArray) {
    if (!prematchTree.children) {
        return;
    }
    const routeSlugs = clone(routeSlugsArray);
    let currentCategorySlug;
    let currentChildren = prematchTree.children;
    let currentCategory;

    while ((currentCategorySlug = routeSlugs.pop())) {
        currentCategory = currentChildren.find(
            ({ slug, id }) => slug === currentCategorySlug || id === Number(currentCategorySlug),
        );

        if (!currentCategory) {
            return;
        }

        currentChildren = currentCategory.children;
    }

    return currentCategory && currentCategory.id;
}

export function setSingleCategoryMarketTypesToStore({
    matches,
    market_types,
}: {
    matches: CategoryMatch[];
    market_types: FoCategoryMarketType[];
}) {
    const topMarketToAdd: Record<number, FoCategoryMarketType[]> = {};
    for (const { category_id, markets } of matches) {
        if (topMarketToAdd[category_id]) {
            continue;
        }
        if (markets?.length) {
            const marketTypeIds = markets.map(({ market_type_id }) => market_type_id);
            topMarketToAdd[category_id] = market_types.filter(({ id }) => marketTypeIds.includes(id));
        }
    }
    stores.sports.topMarketTypesByCategory.set((topMarketTypesByCategory) => ({
        ...topMarketTypesByCategory,
        ...topMarketToAdd,
    }));
}

export function setGroupedCategoriesResponseToStore(categoryResponse?: GroupedMarketType[]) {
    if (!categoryResponse) {
        return;
    }
    const topMarketToAdd: Record<number, FoCategoryMarketType[]> = {};
    for (const { id, market_types } of categoryResponse) {
        if (topMarketToAdd[id]) {
            continue;
        }
        topMarketToAdd[id] = market_types;
    }
    stores.sports.topMarketTypesByCategory.set((topMarketTypesByCategory) => ({
        ...topMarketTypesByCategory,
        ...topMarketToAdd,
    }));
}
