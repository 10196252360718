import React from 'react';
import { translate } from '../../../../services/translate';
import Wrapper from './styles';

export default function HorseRacingSidebarRacebookClosed() {
    return (
        <Wrapper>
            <div className="racebook-closed">
                <h2>{translate(['RACE_UNAVAILABLE', 'Race unavailable.'], 'ui.racebook')}</h2>
                <h3>
                    {translate(
                        ['RACE_NEVADA_RESIDENTS_ONLY', 'Race is available to Nevada residents only.'],
                        'ui.racebook',
                    )}
                </h3>
                <h3>{translate(['CONTACT_CS', 'Contact customer services for more information.'], 'ui.racebook')}</h3>
            </div>
        </Wrapper>
    );
}
