import styled from 'styled-components';

export default styled.div`
    .sort-by {
        border-radius: 3px;
        display: flex;
        font-size: 21px;
        padding: 6px 20px;
        @media (max-width: 760px) {
            align-items: center;
            padding: 6px;
            margin-bottom: 10px;
        }
        .search {
            max-width: 170px;
            height: 35px;
            margin: 0 20px;
        }
        svg {
            height: 30px;
            margin-top: 5px;
            width: 30px;
        }
    }
    .sortable-table {
        max-height: 400px;
        overflow: auto;
    }
`;
