import styled from 'styled-components';

import darken from 'polished/lib/color/darken';

export default styled.div`
    background-color: ${({ theme }) => darken(0.01, theme.box.background)};
    padding: 2rem 0;
    .coolbet-footer-links-container {
        max-width: ${({ theme }) => theme.layout.maxWidth};
        display: flex;
        flex-wrap: nowrap;
        flex-grow: 1;
        margin: 0 auto;
        height: 40px;
        padding: 0 30px;
        overflow: auto;
    }

    .coolbet-footer-social-media-container {
        display: flex;
        flex-wrap: nowrap;
        flex-grow: 1;
        justify-content: center;
        align-items: center;

        @media (min-width: 2050px) {
            padding-right: 0;
        }

        .coolbet-footer-social-media {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            color: var(--font-color-subtle);
            transition: color 0.2s;
            cursor: pointer;
            margin-right: 15px;

            &:hover {
                color: var(--font-color-secondary);
            }
        }

        .coolbet-footer-social-media-icon {
            width: 25px;
            height: 25px;
            margin-right: 5px;

            display: block;
        }

        .coolbet-footer-social-media-title {
            height: 40px;
            line-height: 40px;
            font-weight: 700;
            font-size: 13px;
        }
    }

    .coolbet-footer-link {
        height: 40px;
        line-height: 40px;
        margin-right: 20px;
        font-weight: 700;
        font-size: 13px;
        transition: color 0.2s;
        color: var(--font-color-subtle);
        //text-decoration: underline;

        &:hover {
            color: var(--font-color-secondary);
        }
    }

    @media (max-width: 900px) {
        .coolbet-footer-links-container {
            flex-wrap: wrap;
            height: unset;
        }

        .coolbet-footer-links {
            flex-direction: column;
        }
    }

    @media (max-width: 1200px) {
        .coolbet-footer-social-media-container {
            padding-right: unset;
        }
    }
`;
