import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-column-gap: 0.8rem;
    padding: var(--spacing-12) 0;

    width: 100%;
    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        width: 100%;
        padding: 0;
        display: block;
    }
    .account-content {
        max-width: 1200px;
        min-height: 300px;
        overflow: auto;

        padding: var(--spacing-24);

        font-size: 0.875rem;
        color: var(--font-color-primary);

        ${({ theme }) => theme.contentBoxStyle};
        @media ${mediaQuery.isPhone} {
            padding: var(--spacing-12);
            min-height: calc(100vh - 140px);
        }
    }
`;
