import React from 'react';
import Wrapper from './styles';
import Loader from '../../loader/Loader';
import { translate } from '../../../services/translate';
import { ClientName } from '../../../services/utils/types';
import Logo from '../../logo/Logo';
import { getClientWithoutFallback } from '../../../services/environment';

export default function UiAppLoader() {
    const client = getClientWithoutFallback(); // to not flash coolbet loader in b2b
    return (
        <Wrapper>
            <div data-test="loader" className="brand-logo">
                {!!client && <Logo size={35} />}
                {client === ClientName.COOLBET && (
                    <div className="motto animated fadeInUp">{translate('Stay Cool', 'app.loader')}</div>
                )}
                <Loader className="loader animated fadeInUp" />
            </div>
        </Wrapper>
    );
}
