import React from 'react';
import UiCloseButton from '../../../../ui/close-button/UiCloseButton';
import AccountVerificationDocumentPreviewItem from './item/AccountVerificationDocumentPreviewItem';
import Wrapper from './styles';

interface Props {
    documents: File[];
    onRemoveDocument?: (document: File) => void;
    itemWidth?: number;
    itemHeight?: number;
    isColumnLayout?: boolean;
}

export default function AccountVerificationDocumentPreview({
    documents,
    onRemoveDocument,
    itemWidth,
    itemHeight,
    isColumnLayout,
}: Props) {
    if (!documents.length) {
        return null;
    }
    return (
        <Wrapper itemWidth={itemWidth} itemHeight={itemHeight} isColumnLayout={isColumnLayout}>
            {documents.map((document, index) => (
                <div className="preview-item-container" key={[document.name, document.lastModified, index].join('-')}>
                    {onRemoveDocument && (
                        <UiCloseButton className="remove-button" onClick={() => onRemoveDocument(document)} />
                    )}
                    <AccountVerificationDocumentPreviewItem document={document} />
                </div>
            ))}
        </Wrapper>
    );
}
