import styled, { css } from 'styled-components';

type StyledProps = {
    justify?: 'spaced' | 'center';
    nowrap?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;
    justify-content: ${({ justify }) => (justify === 'center' ? 'center' : 'space-between')};
    padding: 0.4rem 0;

    ${({ nowrap }) =>
        nowrap &&
        css`
            flex-direction: column;
        `};
    .pseudo-toggle-container {
        position: relative;
        display: inline-block;
        height: 1.5rem;
        margin: 0 0 0 0.8rem;
        min-width: 48px;
        text-transform: none;
        letter-spacing: initial;
    }

    input {
        appearance: none;
        height: 0;
        opacity: 0;
        width: 0;

        &:disabled + .pseudo-toggle {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &:checked + .pseudo-toggle {
            border: var(--form-input-toggle-border-active);
            background-color: var(--form-input-toggle-bg-active);
        }

        &:checked + .pseudo-toggle:after {
            background-color: var(--form-input-toggle-knob-bg-active);
            border: var(--form-input-toggle-border-active);
            transform: translate(calc(100% + 2px), 0);
        }
    }
    label {
        padding: ${({ justify }) => (justify === 'center' ? '0 var(--spacing-8) 0 0' : '0 !important')};
        margin: 0 !important;
    }

    .pseudo-toggle {
        background-color: var(--form-input-toggle-bg);
        border: var(--form-input-toggle-border);
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50px;

        &:after {
            background-color: var(--form-input-toggle-knob-bg);
            border: var(--form-input-toggle-border);
            border-radius: 50%;
            content: '';
            display: block;
            height: calc(1.5rem - 2px);
            width: calc(1.5rem - 2px);
            transform: translate(0, 0);
        }
    }
`;
