import React from 'react';
import Wrapper from './styles';

export default function SportOddsSpacer() {
    return (
        <Wrapper>
            <div className="odds-button-spacer" />
        </Wrapper>
    );
}
