import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { loadUserFavorites, updateUserFavorites } from '../microservices/sb-favorites';
import remove from 'lodash/remove';
import clone from 'lodash/clone';
import { storageGet, storageSet } from './storage';
import uniq from 'lodash/uniq';
import { logger } from './logger';

function updateUserFavoritesCookie(userFavorites) {
    stores.sports.userFavorites.set(clone(userFavorites));
    storageSet('userFavorites', userFavorites);
}

export function toggleMatchOrCategoryInFavorites(matchAndCategory, customUserFavorites) {
    const userFavorites = customUserFavorites || getStoreValue(stores.sports.userFavorites);
    const { match, category } = matchAndCategory;
    const selectedFavoriteCategory = getStoreValue(stores.sports.selectedFavoriteCategory);

    const categoryList = userFavorites.categories ? Array.from(userFavorites.categories) : [];
    const matchList = userFavorites.matches ? Array.from(userFavorites.matches) : [];

    if (match) {
        const removed = remove(matchList, (id) => id === match);
        if (!removed.length) {
            matchList.push(match);
        }
    }

    if (category) {
        const removed = remove(categoryList, (id) => id === category);
        if (!removed.length) {
            categoryList.push(category);
        }
        if (selectedFavoriteCategory === category) {
            stores.sports.selectedFavoriteCategory.set(null);
        }
    }

    saveUserFavorites({
        categories: categoryList,
        matches: matchList,
    });
}

export async function getUserFavorites() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const localFavorites = storageGet('userFavorites');

    if (isAuthenticated) {
        try {
            const userFavorites = await loadUserFavorites();

            if (!userFavorites && localFavorites) {
                saveUserFavorites(localFavorites);
                return localFavorites;
            }

            updateUserFavoritesCookie(userFavorites);

            return userFavorites;
        } catch (e) {
            logger.error('UserFavoritesService', 'getUserFavorites', e);
        }
    }

    if (localFavorites) {
        updateUserFavoritesCookie(localFavorites);
        return localFavorites;
    }
}

export async function saveUserFavorites(customUserFavorites) {
    const userFavorites = customUserFavorites || getStoreValue(stores.sports.userFavorites);
    userFavorites.matches = uniq(userFavorites.matches).filter(Boolean);
    const isAuthenticated = getStoreValue(stores.isAuthenticated);

    updateUserFavoritesCookie(userFavorites);
    if (isAuthenticated) {
        await updateUserFavorites(userFavorites);
    }
}
