import { useEffect } from 'react';
import { useLocation } from 'react-router';
import qs from 'query-string';
import Cookies from 'browser-cookies';
import { handlePageViewAnalytics, setAffiliateBTag } from '../../../services/analytics';

export default function AnalyticsPageView() {
    const location = useLocation();

    useEffect(() => {
        setAffiliateCookie();
        handlePageViewAnalytics();
    }, [location.pathname]);

    function setAffiliateCookie() {
        const parsedUrlParams = qs.parse(location.search);
        const { btag: urlBTag } = parsedUrlParams;
        const isBTagAlreadySet = Boolean(Cookies.get('btag'));

        if (!isBTagAlreadySet && typeof urlBTag === 'string') {
            setAffiliateBTag(urlBTag);
        }
    }

    return null;
}
