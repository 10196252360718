import { createStores } from './store/utils';

export const twoFactor = createStores({
    smsPinId: undefined,
    reason: undefined as string | undefined,
    safePhone: undefined,
    email: undefined as string | undefined,
    smsResendFailedReason: undefined as string | undefined,
    isTwoFactorSmsActive: false,
    isTwoFactorEmailActive: false,
    country: '',
});
