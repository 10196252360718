import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';
import UiModal from '../../ui/modal/UiModal';

export default styled(UiModal)`
    width: 270px;

    @media ${mediaQuery.isPhone} {
        width: 100%;
        position: relative;
        height: 100vh;
        display: flex;
        align-items: center;
        left: 0;
        top: 0;
        transform: translate(0, 0);
        margin: 0 auto;
        background: var(--surface-level-1-bg) url(/assets/images/turnover/coolbet-logo-45deg.png) repeat 0 -40px;
        background-size: 57%;
        max-width: -webkit-fill-available;
    }

    &:before {
        @media ${mediaQuery.isPhone} {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
    }

    .close-button {
        position: absolute;
        top: ${({ theme }) => theme.layout.gutter};
        right: ${({ theme }) => theme.layout.gutter};
    }
`;
