import styled from 'styled-components';

export default styled.div`
    margin-top: 1rem;
    .qr-code-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        background: #fff;
        padding: 0.8rem;
        border-radius: 10px;
        text-align: center;
        border: 2px solid;
        color: #000;

        text-align: center;
        .qr-code-text,
        .qr-code-title {
            margin: 2rem 0;
        }
        .qr-code-title {
            font-size: 1.2rem;
            font-weight: var(--font-weight-heavy);
            ${({ theme }) => theme.optimizeFontRendering};
            text-transform: uppercase;

            max-width: 150px;
        }
        .qr-code-text {
            font-size: 1rem;
            text-align: center;
            color: #000;

            max-width: 250px;
        }
    }
`;
