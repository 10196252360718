import React from 'react';
import { BETSLIP_MODE } from '../../../../stores/virtual-sports/types';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import { StoreSetter } from '../../../../stores/store/types';
import UiButton from '../../../ui/button/UiButton';
import Wrapper from './styles';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    onBetslipModeChanged: StoreSetter<string>;
    betslipMode?: string;
    numberOfBets: number;
}

export default function VirtualSportsBetslipMode({ onBetslipModeChanged, betslipMode, numberOfBets }: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    if (!isAuthenticated) {
        return null;
    }

    return (
        <Wrapper>
            <UiButton
                selected={betslipMode === BETSLIP_MODE.BETSLIP}
                onClick={() => onBetslipModeChanged(BETSLIP_MODE.BETSLIP)}
            >
                {translate('Betslip', 'ui.sportsbook')}
                {numberOfBets > 0 && <span className="bets-number">{numberOfBets}</span>}
            </UiButton>

            <UiButton
                selected={betslipMode === BETSLIP_MODE.HISTORY}
                onClick={() => onBetslipModeChanged(BETSLIP_MODE.HISTORY)}
            >
                {translate('Bet History', 'ui.sportsbook')}
            </UiButton>
        </Wrapper>
    );
}
