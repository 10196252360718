import styled from 'styled-components';
import UiModal from '../../../ui/modal/UiModal';
import { mediaQuery } from '../../../../styles/utils';

export default styled(UiModal)`
    position: relative;
    min-width: 320px;

    .close-button {
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
    }

    h2 {
        padding: 0 2rem 0 0;
    }

    .terms-text {
        max-height: 300px;
        min-height: 300px;
        overflow-y: auto;
        margin: 1rem 0;
        @media ${mediaQuery.isPhone} {
            max-height: 60vh;
        }
    }
    .terms-read-more {
        text-align: center;
        font-size: 0.8rem;
        display: block;
        cursor: pointer;
    }
`;
