import styled from 'styled-components';

export default styled.div`
    margin-top: 0.8rem;
    .or {
        text-align: center;
        color: var(--font-color-subtle);
        font-size: 0.8rem;

        padding: 1rem;
    }
    .progress {
        margin-bottom: 0.2rem;
    }
    .alert-message {
        margin-top: 0.4rem;
    }
    .clear-selections {
        margin-top: 0.25rem;
    }
    .extra-text {
        font-size: 0.65rem;
        white-space: break-spaces;
        margin-top: var(--spacing-8);
    }
`;
