import classNames from 'classnames';
import React from 'react';
import { getActiveCurrency } from '../../../../../services/currency';
import { isB2B } from '../../../../../services/environment';
import { CategoryMatchMarket, Turnover } from '../../../../../services/sports/types';
import { translate } from '../../../../../services/translate';
import { formattedAmountWithCurrency } from '../../../../../services/currency';
import { Currency } from '../../../../../services/wallet/types';
import SportOdds from '../../../odds/SportOdds';
import SportMatchTurnoverChart from '../chart/SportMatchTurnoverChart';
import Wrapper from './styles';
import { filterStyleProps } from '../../../../../styles/utils';

interface Props {
    outcome: { id: number; name: string };
    market: CategoryMatchMarket & { betting_end: Date; in_play: boolean };
    turnover: Turnover;
    currency: Currency;
}

export default function SportMatchTurnoverOutcome({
    outcome,
    turnover,
    market,
    currency = getActiveCurrency(),
    ...rest
}: Props) {
    const stake = turnover[outcome.id] ? turnover[outcome.id].stake : 0;

    return (
        <Wrapper {...filterStyleProps({ ...rest })}>
            <div className="outcome">
                <div className="outcome-name">
                    <div className="name">{outcome.name}</div>
                    <div className="odds">
                        <SportOdds light outcomeId={outcome.id} market={market} small />
                    </div>
                </div>
                <div className="outcome-graph">
                    <SportMatchTurnoverChart
                        percentage={turnover[outcome.id] && turnover[outcome.id].share ? turnover[outcome.id].share : 0}
                    />
                </div>

                {!isB2B() && (
                    <div className={classNames('outcome-totals', { 'small-digits': currency === Currency.CLP })}>
                        <div className="turnover">{formattedAmountWithCurrency(stake)}</div>
                        <div className="tickets-number">
                            {turnover[outcome.id] ? turnover[outcome.id].bets.toFixed(0) : '0'}{' '}
                            <span>{translate('tickets', 'ui.betstats')}</span>
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
