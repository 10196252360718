import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.layout.gutter};
    margin-bottom: ${({ theme }) => theme.layout.gutter};
    text-align: center;

    > *:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.layout.gutter};
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
