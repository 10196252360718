import { lighten, saturate } from 'polished';
import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    background-color: var(--surface-level-1-bg);
    padding: 0.5rem 0;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 2;
    @media ${mediaQuery.isPhone} {
        margin-bottom: ${({ theme }) => theme.layout.gutter};
    }
    button {
        flex: 1;
        &:not(:last-of-type) {
            margin-right: 3px;
        }
    }
    .bets-number {
        box-shadow: inset 0 3px 3px ${({ theme }) => lighten(0.05, theme.colors.highlight)}, var(--ui-shadow);
        border: 1px solid ${({ theme }) => saturate(0.3, theme.colors.highlight)};
        font-size: 0.7rem;
        background-color: ${({ theme }) => theme.colors.highlight};
        color: ${({ theme }) => theme.colors.black};
        border-radius: 100px;
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 20px;
        height: 20px;
    }
`;
