import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

type StyledProps = {
    collapsed?: boolean;
};

export default styled.div<StyledProps>`
    .sidebet {
        padding-bottom: ${({ collapsed }) => (collapsed ? '0' : '')}!important;
    }
    .market-line-select {
        margin-bottom: 0.2rem;
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        align-self: flex-end;

        height: ${({ theme }) => theme.match.marketLineSelectHeight};
        width: ${({ theme }) => theme.match.marketLineSelectWidth};
        max-width: ${({ theme }) => theme.match.marketLineSelectWidth};
        min-width: ${({ theme }) => theme.match.marketLineSelectWidth};

        border: var(--match-line-select-border);
        border-radius: var(--odds-radius);
        margin-left: 0.2rem;

        color: var(--font-color-subtle);
        user-select: none;
        background-color: var(--match-line-select-bg);
        &.selected {
            background-color: var(--button-active-bg) !important;
            border: var(--button-active-border) !important;
            color: var(--button-active-text-color) !important;
            &:hover {
                background-color: var(--button-active-bg-hover) !important;
                color: var(--button-active-text-color-hover) !important;
            }
        }
        &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.6;
            box-shadow: none;
        }
        @media ${mediaQuery.isPhone} {
            height: ${({ theme }) => theme.match.marketLineSelectHeightMobile};
            width: ${({ theme }) => theme.match.marketLineSelectWidthMobile};
            max-width: ${({ theme }) => theme.match.marketLineSelectWidthMobile};
            min-width: ${({ theme }) => theme.match.marketLineSelectWidthMobile};
            border-radius: ${({ theme }) => theme.match.marketLineSelectRadius};
            margin: 0 0 0.2rem 0.2rem;

            background-color: var(--match-line-select-bg);
        }

        @media ${mediaQuery.isTablet} {
            height: ${({ theme }) => theme.match.marketLineSelectHeightMobile};
        }
    }

    .market-lines-list {
        position: absolute;
        top: 100%;
        z-index: 4;

        width: 100%;
        padding: 0.4rem;
        margin-top: 0.2rem;

        background-color: var(--match-line-list-bg);
        border: var(--match-line-list-border);
        border-radius: 0 0 var(--ui-radius) var(--ui-radius);
        box-shadow: rgb(0 0 0 / 56%) 0 45px 50px 0;

        .market-lines-list-odds {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            min-width: 0;
            > * {
                margin-right: 0.2rem;
                margin-bottom: 0.2rem;
                @media ${mediaQuery.isPhone} {
                    margin-right: 0.25rem;
                    margin-bottom: 0.25rem;
                }
            }
            button {
                font-size: 0.85rem;
                height: 32px;
                flex: 1;
            }
        }
    }

    .raw-line {
        color: var(--odds-line-text);
        font-size: 0.875rem;
        font-weight: var(--match-name-weight);
        ${({ theme }) => theme.optimizeFontRendering};
        margin-left: 0.4rem;
    }

    .no-wrap {
        white-space: nowrap;
    }
`;
