import classNames from 'classnames';
import React, { forwardRef, Ref } from 'react';
import { translate } from '../../../../services/translate';
import Wrapper from './styles';

interface Props {
    declineReason?: string;
    isCompact?: boolean;
    className?: string;
}

function AccountVerificationDeclineReason(
    { declineReason, className, isCompact, ...restOfProps }: Props,
    ref: Ref<HTMLDivElement>,
) {
    const translatedDeclineReason =
        !declineReason || declineReason === 'Generic cancel reason'
            ? translate('Please contact our customer service for details', 'ui.account')
            : translate(declineReason, 'ui.account');

    return (
        <Wrapper
            className={classNames(className, { compact: isCompact })}
            dangerouslySetInnerHTML={{ __html: translatedDeclineReason }}
            ref={ref}
            {...restOfProps}
        />
    );
}

export default forwardRef(AccountVerificationDeclineReason);
