import { useEffect, useState } from 'react';
import { ClientTheme } from '../theme/global';
import { environment } from '../stores/environment/environment';
import { useStore } from './useStore';

export const useTheme = () => {
    const [{ THEME: themeName }] = useStore(environment);
    const [theme, setTheme] = useState<ClientTheme>();

    async function handleThemeChange() {
        try {
            const name = localStorage.getItem('THEME') || themeName;
            const styles = await import(`../theme/theme.${name}`);
            setTheme(styles[name]);
        } catch (error) {
            throw Error(`Failed to load theme: ${error}`);
        }
    }

    useEffect(() => {
        if (themeName) {
            handleThemeChange();
        }
    }, [themeName]);

    return theme;
};
