import styled from 'styled-components';

import UiModal from '../../../ui/modal/UiModal';

export default styled(UiModal)`
    p {
        color: var(--font-color-secondary);
        font-size: var(--font-12);
    }

    .session-time {
        color: var(--color-highlight);
        text-align: center;
        margin: 0;
        font-size: var(--font-14);

        em {
            font-style: normal;
        }
    }

    .buttons-group {
        margin: var(--spacing-16) 0;
        > *:not(:last-child) {
            margin-bottom: var(--spacing-4);
        }
    }

    .one-product {
        display: flex;
        justify-content: space-between;
        margin: var(--spacing-8) 0;
        color: var(--font-color-secondary);
        font-size: var(--font-14);
        em {
            font-style: normal;
            color: var(--font-color-secondary);
            &.positive {
                color: var(--color-success);
            }
            &.negative {
                color: var(--color-lost);
            }
        }
    }

    .all-products-total {
        display: flex;
        justify-content: space-between;

        color: var(--font-color-secondary);
        margin: var(--spacing-12) 0;
        padding-top: var(--spacing-12);
        border-top: var(--surface-level-2-border);
        font-size: 1.2rem;

        em {
            color: var(--color-highlight);
            font-style: normal;
        }
    }
    .total-amount {
        color: var(--font-color-secondary);
        &.positive {
            color: var(--color-success);
        }
        &.negative {
            color: var(--color-lost);
        }
        .currency {
            font-size: 70%;
        }
    }
    .heading {
        padding-bottom: ${({ theme }) => theme.layout.gutter};
    }
`;
