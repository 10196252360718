import React, { useCallback, useEffect, useState } from 'react';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import { useSportsMatchContext } from '../../../../../services/sports/match-helpers';
import debounce from 'lodash/debounce';
import { translate } from '../../../../../services/translate';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../../services/analytics';
import UiCloseButton from '../../../../ui/close-button/UiCloseButton';
import { stores } from '../../../../../stores';
import { unaccent } from '../../../../../services/sports/sidebets';
import UiButton from '../../../../ui/button/UiButton';
import Svg from '../../../../svg/Svg';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    isSearching: boolean;
    onIsSearching: (b: boolean) => void;
}

export default function SportMatchMarketsSearch({ isSearching, onIsSearching }: Props) {
    const { setContext, sidebetsSearch } = useSportsMatchContext();
    const debouncedSetTermToContext = useCallback(debounce(setSearchTermToContext, 300), []);
    const [localTerm, setLocalTerm] = useState('');

    const [searchSelected, setSearchSelected] = useStore(stores.sports.searchMatchSelected);

    useEffect(() => {
        if ((searchSelected?.market_type_group_ids?.length || 0) <= 1) {
            return; // case handled by useSearchMarketHightlight
        }
        setSearchSelected(null);
        setTerm(unaccent(searchSelected?.player_team_name));
        onIsSearching(true);
    }, [searchSelected]);

    useEffect(() => {
        if (sidebetsSearch === '' && isSearching) {
            const timer = setTimeout(() => onIsSearching(false), 10_000);
            return () => clearTimeout(timer);
        }
    }, [sidebetsSearch]);

    function setTerm(v) {
        setLocalTerm(v);
        debouncedSetTermToContext(v);
    }

    function setSearchTermToContext(v: string | undefined) {
        return setContext?.({ sidebetsSearch: v?.toLowerCase() });
    }

    function toggle() {
        if (isSearching && sidebetsSearch) {
            // this makes it not use the filter in useSidebetsSearch hooks
            setSearchTermToContext(undefined);
        }
        trackGoogleTagManagerEvent(
            isSearching ? TAG_MANAGER_EVENT.SPORT_SIDEBETS_SEARCH_CLOSE : TAG_MANAGER_EVENT.SPORT_SIDEBETS_SEARCH_OPEN,
        );
        onIsSearching(!isSearching);
    }

    return (
        <>
            <UiButton
                icon={<Svg icon="search-ico" size={0.75} />}
                size="small"
                onClick={toggle}
                selected={isSearching}
            />
            {isSearching && (
                <>
                    <UiFormInput
                        name="sidebets-search"
                        className="sidebets-search-input"
                        placeholder={translate(['sidebets-search', 'Search for market, team, player'], 'ui.sportsbook')}
                        autoFocus
                        data-gramm_editor="false"
                        data-gramm="false"
                        value={localTerm}
                        onValueChange={setTerm}
                    />
                    {localTerm && <UiCloseButton onClick={() => setTerm('')} className="close-button" size={22} />}
                </>
            )}
        </>
    );
}
