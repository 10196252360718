import { getServiceUrl, httpGet } from '../services/api';
import { logger } from '../services/logger';
import { stores } from '../stores';

const getUrl = (url) => getServiceUrl('features', url);

export async function loadFeatures() {
    try {
        const features = await httpGet<string[]>(getUrl('v1/flags/FRONT_OFFICE'));
        stores.features.set(features);
    } catch (error) {
        logger.error('FeaturesMicroservice', 'loadFeatures', error);
    }
}
