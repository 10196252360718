import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import round from 'lodash/round';
import { getStoreValue } from '../../stores/store/utils';
import { SYSTEM_BET_TYPE_BY_SYSTEM_KEY } from './constants';
import { getUniqCombinations } from '../util';
import { virtualSports } from '../../stores/virtual-sports/virtual-sports';

export function baseCalculateStake(systemBets: SystemBets, stakeBySystemType: Record<string, number>) {
    const totalStake = map(systemBets, (betsOfType, systemKey) => {
        const systemType = SYSTEM_BET_TYPE_BY_SYSTEM_KEY[systemKey];
        const betStake = Number(stakeBySystemType[systemType]);
        if (!betStake) {
            return 0;
        }
        return betStake * betsOfType.length;
    })
        .filter((totalBetsOfOneTypeStake) => totalBetsOfOneTypeStake)
        .reduce((totalSystemStake, oneTypeOfSystemStake) => totalSystemStake + oneTypeOfSystemStake, 0);
    return round(totalStake, 2);
}

export function calculateSystemTotalStake(systemBets: SystemBets) {
    const userState = getStoreValue(virtualSports.betslipUserState);
    const { systemStakes } = userState;
    const stakesBySystem = mapValues(systemStakes, (userStake) => Number(userStake));
    return baseCalculateStake(systemBets, stakesBySystem);
}

export function getPotentialReturn(
    systemBets: SystemBets,
    systemStakes: Record<string, string>,
    betSlipMarketIdToOutcomeId: Record<number, number>,
    oddsByOutcomeId: Record<number, number>,
) {
    return map(systemBets, (betsOfType, systemKey) => {
        const stake = systemStakes[SYSTEM_BET_TYPE_BY_SYSTEM_KEY[systemKey]];
        if (!stake) {
            return 0;
        }
        return betsOfType
            .map((marketIdsForBet) => {
                const betTotalOdds = marketIdsForBet
                    .map((marketId) => {
                        const outcomeId = betSlipMarketIdToOutcomeId[marketId];
                        return oddsByOutcomeId[outcomeId] ? oddsByOutcomeId[outcomeId] : 1;
                    })
                    .reduce((oddsMultipliedValue, currentOddsValue) => oddsMultipliedValue * currentOddsValue, 1);
                return Number(stake) * betTotalOdds;
            })
            .reduce((totalBetsPotentialReturn, betPotentialReturn) => totalBetsPotentialReturn + betPotentialReturn, 0);
    })
        .filter((systemBetPotentialReturnOrUndefined) => systemBetPotentialReturnOrUndefined)
        .reduce(
            (totalSystemBetSystemTypePotentialReturn, systemBetSystemTypePotentialReturn) =>
                totalSystemBetSystemTypePotentialReturn + systemBetSystemTypePotentialReturn,
            0,
        );
}

export function calculateSystemTotalPotentialReturn(systemBets: SystemBets) {
    const oddsByOutcomeId = getStoreValue(virtualSports.betslipOddsByOutcomeId);
    const { systemStakes } = getStoreValue(virtualSports.betslipUserState);
    const betSlipMarketIdToOutcomeId = getStoreValue(virtualSports.betslipMarketIdToOutcomeId);
    const potentialReturn = getPotentialReturn(systemBets, systemStakes, betSlipMarketIdToOutcomeId, oddsByOutcomeId);
    virtualSports.potentialReturn.set(potentialReturn);
    return round(potentialReturn, 2);
}

export function getSystemCombinations(betSlipMarketIdToOutcomeId: Record<number, number>): SystemBets {
    const systemBets = {};
    const betSlipMarketIdsWithOutBankers = Object.keys(betSlipMarketIdToOutcomeId);

    for (let combinationLength = 1; combinationLength <= betSlipMarketIdsWithOutBankers.length; combinationLength++) {
        const combinations = getUniqCombinations(betSlipMarketIdsWithOutBankers, combinationLength);
        combinations.forEach((combination) => {
            if (!systemBets[combinationLength]) {
                systemBets[combinationLength] = [];
            }
            systemBets[combinationLength].push(combination);
        });
    }
    return systemBets;
}

type SystemBets = Record<number, number[] | number[][]>;
