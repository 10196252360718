import { getStoreValue } from '../stores/store/utils';
import * as Sentry from '@sentry/react';
import { logger } from './logger';
import { environment } from '../stores/environment/environment';

const ignoredErrors = [
    /Loading chunk \d+ failed/,
    /Load failed/,
    /o\(\)\.getItem/, // localStorage ios error in some sPeCiAl case
    /Failed to execute 'removeChild' on 'Node'/,
    /Token missing/,
    /'getItem'/,
];

export const initSentry = () => {
    try {
        const { SENTRY_DSN, ROOT_URL, CLIENT_NAME, ENVIRONMENT } = getStoreValue(environment);

        if (SENTRY_DSN) {
            const { host } = new URL(ROOT_URL);

            Sentry.init({
                environment: `${CLIENT_NAME}-${ENVIRONMENT}`,
                dsn: SENTRY_DSN,
                allowUrls: [`${host}/static/js`],
                autoSessionTracking: false,
                sampleRate: 0.1,
                tracesSampleRate: 0.01,
                beforeSend: (event, hint) => {
                    const error = hint?.originalException as Error;
                    if (ignoredErrors.some((regex) => regex.test(error.message))) {
                        return null;
                    }
                    return event;
                },
            });
        }
    } catch (error) {
        logger.error('SentryService', 'initSentry', error);
    }
};
