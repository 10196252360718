import styled from 'styled-components';
import UiGroup from '../../../../ui/group/UiGroup';

export default styled(UiGroup)`
    .verification-options {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-12);
    }
    .verification-steps {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-12);
        margin-bottom: var(--spacing-12);
        .step {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 250px;
            text-align: center;
            font-size: var(--font-14);
            border-bottom: var(--surface-level-3-border);
        }
    }

    .prescreen-button-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-12);
    }
`;
