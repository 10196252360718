import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    .stake {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        label {
            flex: 1;
            text-align: right;
            margin: 0 0.4rem 0 0;
            color: ${({ theme }) => theme.colors.secondary};
            @media ${mediaQuery.isPhone} {
                font-size: 0.7rem;
            }
        }
        input {
            width: 90px;
            height: 50px;
            margin: 0;
            text-align: center;
            border: 2px solid ${({ theme }) => theme.colors.secondary};
            -webkit-appearance: none;
            -moz-appearance: textfield;
            padding: 0 0.2rem;
            font-size: 1rem;
            @media ${mediaQuery.isPhone} {
                width: 100px;
                font-size: 1.2rem;
            }
        }
    }
    .total-odds,
    .potential-return {
        font-size: 0.6rem;
        text-align: right;
        margin: 0.8rem 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media ${mediaQuery.isPhone} {
            font-size: 0.7rem;
        }
        > span {
            padding: 0 0.2rem;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        .value {
            min-width: 90px;
            text-align: center;
            font-size: 0.875rem;
            margin-left: 0.2rem;
            @media ${mediaQuery.isPhone} {
                width: 100px;
                font-size: 1.2rem;
            }
        }
    }
    .potential-return span.value {
        color: var(--color-highlight);
        font-weight: bold;
    }
    .total-odds span.value {
        color: ${({ theme }) => theme.colors.highlight};
    }
`;
