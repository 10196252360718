import styled from 'styled-components';

export default styled.div`
    .racebook-closed {
        height: 30rem;
        border: 5px solid var(--color-highlight);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        h2,
        h3 {
            margin-top: 0.5rem;
            padding: 0rem var(--spacing-8) 0rem;
        }
    }
`;
