import styled from 'styled-components';

export default styled.div`
    padding: 0.3rem;

    .description {
        font-size: 0.875rem;
        padding: 0.3rem 0;
    }

    .date-selectors {
        display: flex;
        justify-content: center;
        padding: 0.3rem;
        .document-expiry-field {
            width: 100%;
            margin-right: 0.3rem;
            &.year {
                margin: 0;
            }
        }
    }

    .invalid-date-alert {
        text-align: center;
    }
`;
