import styled from 'styled-components';

export default styled.div`
    background-color: var(--color-body-bg);
    padding: 2rem;

    .coolbet-footer-payment-partners-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        max-width: ${({ theme }) => theme.layout.maxWidth};
        margin: 0 auto;
    }

    .payment-partner-image {
        height: 30px;
        filter: grayscale(100%) contrast(50%);
        transition: filter 0.2s;

        &.MasterCard {
            filter: grayscale(100%) contrast(90%);
        }

        &.Skrill {
            filter: grayscale(100%) contrast(20%);
        }

        &:hover {
            filter: unset;
        }
    }

    .payment-partner-link {
        display: block;
        margin: 0 10px;
        height: 30px;
    }
`;
