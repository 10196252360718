import React from 'react';

import { translate } from '../../../../../services/translate';
import UiAlert from '../../../../ui/alert/UiAlert';
import UiGroup from '../../../../ui/group/UiGroup';
import UiButton from '../../../../ui/button/UiButton';
import UiAnimate from '../../../../ui/animate/UiAnimate';
import { virtualSports } from '../../../../../stores/virtual-sports/virtual-sports';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    onPlaceBet: () => void;
}

export default function VirtualSportsBetslipButtonAndErrorsConfirm({ onPlaceBet }: Props) {
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(virtualSports.betslipPlacingState);
    const { isLoading } = betSlipPlacingState;

    return (
        <>
            <UiGroup expand>
                <UiButton onClick={() => setBetSlipPlacingState({ ...betSlipPlacingState, needsConfirm: false })}>
                    {translate('Back', 'ui.sportsbook')}
                </UiButton>
                <UiButton
                    color="primary"
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={() => {
                        setBetSlipPlacingState({ ...betSlipPlacingState, isConfirmed: true });
                        onPlaceBet();
                    }}
                >
                    {translate('Confirm', 'ui.sportsbook')}&nbsp;&rarr;
                </UiButton>
            </UiGroup>

            <UiAnimate animationIn="bounceIn">
                <UiAlert success>
                    {translate('Just double check your selection and click confirm', 'ui.sportsbook')}
                </UiAlert>
            </UiAnimate>
        </>
    );
}
