import styled from 'styled-components';

export default styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-blob {
        width: 80px;
        height: 80px;
        background-color: #333;

        border-radius: 50%;
        animation: loading-blob-scale 1s infinite ease-in-out;

        &.loading-blob-blue {
            background-color: #17bdfe;
        }

        &.loading-blob-orange {
            background-color: #fe8e04;
        }
    }

    .loading-blob-main-container {
        width: 80px;
        height: 80px;
        position: relative;
    }

    .loading-blob-main-1,
    .loading-blob-main-2 {
        width: 80px;
        height: 80px;
        background-color: #333;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;

        border-radius: 50%;
        animation: loading-blob-main-bounce 2s infinite ease-in-out;

        &.loading-blob-blue {
            background-color: #17bdfe;
        }

        &.loading-blob-orange {
            background-color: #fe8e04;
        }
    }

    .loading-blob-main-2 {
        animation-delay: -1s;
    }

    @keyframes loading-blob-main-bounce {
        0%,
        100% {
            transform: scale(0);
        }
        50% {
            transform: scale(1);
        }
    }

    @keyframes loading-blob-scale {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
    }
`;
