import styled from 'styled-components';

export default styled.span`
    display: inline-block;
    width: 60%;
    max-width: 60%;
    opacity: 0.2;
    margin: 0 !important;
    span {
        width: 90%;
        margin: 0 !important;
    }
`;
