import { useEffect } from 'react';
import { getRoute, useRouter } from '../../../services/router';

type NavigateEvent = {
    detail: {
        route?: string;
        path?: string;
    };
};

export default function SystemB2B() {
    const { navigateTo } = useRouter();

    useEffect(() => {
        if (window.coolb2b?.events) {
            window.coolb2b.events.addEventListener('navigateTo', ({ detail }: NavigateEvent) => {
                if (detail?.route) {
                    navigateTo(getRoute(detail.route));
                } else if (detail?.path) {
                    navigateTo(detail.path);
                } else {
                    console.warn('Unhandled navigate event');
                }
            });
        }
    }, []);

    return null;
}
