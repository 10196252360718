import styled from 'styled-components';

export default styled.h2`
    display: flex;
    align-items: center;

    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--font-color-secondary);
    font-weight: var(--font-weight-bold);

    margin: 0 0 var(--spacing-8) 0;

    svg {
        width: 40px;
        margin-right: 1rem;

        path {
            fill: var(--color-highlight);
        }
    }
`;
