import Cookies from 'browser-cookies';
import { v4 as uuidv4, validate } from 'uuid';
import { storageGet, storageSet } from '../../../services/storage';
import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';
import { useInterval } from '../../../services/hooks';
import { getDeviceUuid, setDeviceUuid, setDeviceUuidCookie } from '../../../services/fingerprint';

export default function SystemFingerprint() {
    useInterval(
        () => {
            ensureUuidCookieExists();
        },
        1000,
        [],
    );

    function ensureUuidCookieExists() {
        const deviceUuid = getDeviceUuid();

        if (!deviceUuid || !validate(deviceUuid)) {
            setDeviceUuid(uuidv4());
            return;
        }

        const hasDeviceUuidInStateStorage = Boolean(getStoreValue(stores.uuid));
        const hasDeviceUuidInLocalStorage = Boolean(storageGet('uuid'));
        const hasDeviceUuidInCookieStorage = Boolean(Cookies.get('uuid'));

        if (!hasDeviceUuidInStateStorage) {
            stores.uuid.set(deviceUuid);
        }

        if (!hasDeviceUuidInLocalStorage) {
            storageSet('uuid', deviceUuid);
        }

        if (!hasDeviceUuidInCookieStorage) {
            setDeviceUuidCookie(deviceUuid);
        }
    }

    return null;
}
