import QRCode from 'qrcode.react';
import React from 'react';
import { getRoute } from '../../../../../services/router';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import Wrapper from './styles';
import { getDocumentHost } from '../../../../../services/util';
import { environment } from '../../../../../stores/environment/environment';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    onBackToGuide: () => void;
}

export default function AccountVerificationDocumentCaptureQrCode({ onBackToGuide }: Props) {
    const [{ ENVIRONMENT }] = useStore(environment);

    function qrRedirect() {
        return ENVIRONMENT === 'prod'
            ? `https://www.${getDocumentHost()}${getRoute('account.verification')}?isMobileFlow=true`
            : `https://coolbet${ENVIRONMENT}.com${getRoute('account.verification')}?isMobileFlow=true`;
    }

    return (
        <Wrapper>
            <div className="qr-container">
                <p>
                    {translate(
                        'Once the verification process has been completed this pop-up can be closed',
                        'ui.account',
                    )}
                </p>

                <QRCode size={200} value={qrRedirect()} />

                <UiButton block onClick={onBackToGuide}>
                    {translate('Back to guide', 'ui.account')}
                </UiButton>
            </div>
        </Wrapper>
    );
}
