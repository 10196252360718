import styled from 'styled-components';

export default styled.div`
    text-align: center;

    &.compact {
        max-height: 2.4rem;
        line-height: 1.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }
` as React.ComponentType<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
