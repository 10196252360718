import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { placeBet } from '../../../../services/virtual-sports/betslip';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import VirtualSportsBetslipButtonAndErrorsConfirm from './confirm/VirtualSportsBetslipButtonAndErrorsConfirm';
import Wrapper from './styles';
import { getErrorMessage } from '../../../../services/sports/betslip';
import { getActiveCurrency } from '../../../../services/currency';
import UiButton from '../../../ui/button/UiButton';
import { getMinStake } from '../../../../services/sports/limits';
import { virtualSports } from '../../../../stores/virtual-sports/virtual-sports';
import { useStore } from '../../../../hooks/useStore';

export default function VirtualSportsBetslipButtonAndErrors() {
    const [betSlipPlacingState] = useStore(virtualSports.betslipPlacingState);
    const { betslipErrors, isLoading, receipt, needsConfirm, isPlaceBetButtonDisabled } = betSlipPlacingState;
    const [genericErrors, setGenericErrors] = useState<any[]>([]);

    useEffect(() => {
        const genericErrors: any[] = [];
        for (const error of Object.keys(betslipErrors as any[])) {
            if (Number.isNaN(Number(error))) {
                genericErrors.push(betslipErrors[error]);
            }
        }
        setGenericErrors(genericErrors);
    }, [betslipErrors, isPlaceBetButtonDisabled]);

    if (receipt && isEmpty(betslipErrors)) {
        return null;
    }

    if (needsConfirm) {
        return (
            <Wrapper>
                <VirtualSportsBetslipButtonAndErrorsConfirm onPlaceBet={placeBet} />
            </Wrapper>
        );
    }

    function getTranslatedGenericError(genericError) {
        if (genericError === 'MIN_STAKE') {
            const currency = getActiveCurrency();
            const minStake = getMinStake();
            return translate('MIN_STAKE', 'ui.sportsbook', [minStake, currency]);
        }
        return getErrorMessage(genericError) || translate(genericError, 'ui.sportsbook');
    }

    return (
        <Wrapper>
            {genericErrors.length > 0 &&
                genericErrors.map((genericError) => (
                    <UiAlert key={genericError} failure>
                        {getTranslatedGenericError(genericError)}
                    </UiAlert>
                ))}
            <>
                <UiButton
                    color="primary"
                    block
                    isLoading={isLoading}
                    onClick={placeBet}
                    disabled={isLoading || isPlaceBetButtonDisabled}
                    data-test="button-place-bet"
                >
                    {translate(`Place bet`, 'ui.sportsbook')}
                </UiButton>
            </>
        </Wrapper>
    );
}
