import uniqBy from 'lodash/uniqBy';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { DatabaseOdds } from '@staycool/odds-types';

const getUrl = (url) => getServiceUrl('sb-odds', url);

export function loadOddsByMarketIds(marketIds) {
    if (!marketIds || !marketIds.length) {
        return;
    }
    const where = { market_id: { in: marketIds } };
    return loadOddsBy(where, httpPost);
}

async function loadOddsBy(where, httpMethod = httpGet) {
    const url = getUrl('odds/current/fo');
    const shouldStringify = httpMethod === httpGet;
    const newOddsByOutcomeId = await httpMethod<any>(url, { where: shouldStringify ? JSON.stringify(where) : where });

    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    stores.sports.oddsByOutcomeId.set({ ...oddsByOutcomeId, ...newOddsByOutcomeId });
    return newOddsByOutcomeId;
}

export async function loadOddsForFoLine(marketIdsGroups: number[][]) {
    if (!marketIdsGroups || !marketIdsGroups.length || !marketIdsGroups.flat().length) {
        return;
    }

    const newOddsByOutcomeId = await getOddsForFoLine(marketIdsGroups);
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    stores.sports.oddsByOutcomeId.set({ ...oddsByOutcomeId, ...newOddsByOutcomeId });
    return newOddsByOutcomeId;
}

export async function getOddsForFoLine(marketIdsGroups: number[][]) {
    const url = getUrl('odds/current/fo-line/');
    const marketIds = uniqBy(marketIdsGroups, (marketIds) => marketIds[0]);
    return await httpPost<Pick<DatabaseOdds, 'match_id' | 'market_id' | 'outcome_id' | 'value' | 'status' | 'odds_id'>>(
        url,
        {
            marketIds,
        },
    );
}
