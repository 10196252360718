import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { usePrevious, useTimeout } from '../services/hooks';
import { logger } from '../services/logger';
import { useSharedStorage } from '../services/persisted-shared-storage';
import { storageGet, storageSet } from '../services/storage';
import random from 'lodash/random';

interface ExtraOptions {
    taskEndedInOtherTabCallback?: () => void;
    taskEndedInThisTabCallback?: () => void;
    taskEndedCallback?: () => void;
    reschedule?: boolean;
}

export function useScheduler(
    scheduledTaskName: string,
    task: () => Promise<void> | (() => void),
    extraOptions?: ExtraOptions,
) {
    const taskEndedInOtherTabCallback = extraOptions?.taskEndedInOtherTabCallback ?? (() => {});
    const taskEndedInThisTabCallback = extraOptions?.taskEndedInThisTabCallback ?? (() => {});
    const taskEndedCallback = extraOptions?.taskEndedCallback ?? (() => {});
    const reschedule = extraOptions?.reschedule ?? true;

    const { TASK_PENDING_KEY } = getScheduledTaskNames(scheduledTaskName);

    enum TASK_STATUS {
        IDLE = 'IDLE',
        BLOCKED = 'BLOCKED',
        TIMEOUT_RUNNING = 'TIMEOUT_RUNNING',
        TASK_RUNNING = 'TASK_RUNNING',
    }

    const [intervalMs, setIntervalMs] = useState<number | undefined>();
    const [randomizedMsUntilTimeoutExpiry, setRandomizedMsUntilTimeoutExpiry] = useState<number | undefined>();
    const [currentIsTaskPending] = useSharedStorage(TASK_PENDING_KEY, false);
    const previousIsTaskPending = usePrevious(currentIsTaskPending);
    const [contextId, setContextId] = useState<string | undefined>(getRandomId());
    const [status, setStatus] = useState(TASK_STATUS.IDLE);
    const taskRef = useRef(task);
    const taskEndedInOtherTabCallbackRef = useRef(taskEndedInOtherTabCallback);
    const taskEndedInThisTabCallbackRef = useRef(taskEndedInThisTabCallback);
    const taskEndedCallbackRef = useRef(taskEndedCallback);

    taskRef.current = task;
    taskEndedInOtherTabCallbackRef.current = taskEndedInOtherTabCallback;
    taskEndedInThisTabCallbackRef.current = taskEndedInThisTabCallback;
    taskEndedCallbackRef.current = taskEndedCallback;

    useEffect(() => {
        purgeExpiredScheduledTaskStorageKeys(scheduledTaskName);
    }, []);

    useEffect(() => {
        if (previousIsTaskPending && !currentIsTaskPending) {
            if (reschedule) {
                restartScheduler();
            } else {
                stopScheduler();
            }

            taskEndedInOtherTabCallbackRef.current();
            taskEndedCallbackRef.current();
        }
    }, [currentIsTaskPending]);

    useTimeout(
        () => {
            (async () => {
                purgeExpiredScheduledTaskStorageKeys(scheduledTaskName);

                if (getIsScheduledTaskPending(scheduledTaskName)) {
                    logger.info(
                        'useScheduler',
                        'useTimeout',
                        `Task "${scheduledTaskName}" is most likely already running in another tab. Not running it in this tab.`,
                    );

                    setStatus(TASK_STATUS.BLOCKED);
                    return;
                }

                try {
                    setScheduledTaskStatusExecuting(scheduledTaskName);
                    setStatus(TASK_STATUS.TASK_RUNNING);
                    await taskRef.current();
                } catch (error) {
                    logger.error('UseScheduledTaskMultiTabHandler', scheduledTaskName, error);
                } finally {
                    setScheduledTaskStatusIdle(scheduledTaskName);

                    if (reschedule) {
                        restartScheduler();
                    } else {
                        stopScheduler();
                    }

                    taskEndedInThisTabCallbackRef.current();
                    taskEndedCallbackRef.current();
                }
            })();
        },
        randomizedMsUntilTimeoutExpiry,
        [randomizedMsUntilTimeoutExpiry],
    );

    function isValidNumber(value: unknown): value is number {
        return (
            value !== null && value !== undefined && typeof value === 'number' && Number.isFinite(value) && value > 0
        );
    }

    function getMsUntilTimeoutExpiry(timeoutMs: number) {
        const lowerThreshold = 0.1;
        const upperThreshold = 0.1;

        const from = Math.round(-(timeoutMs * lowerThreshold));
        const to = Math.round(timeoutMs * upperThreshold);
        return timeoutMs + random(from, to);
    }

    function getRandomId() {
        return Math.random().toString(36).slice(2).slice(0, 2).toUpperCase();
    }

    function startScheduler(timeoutMs: unknown) {
        stopScheduler();

        try {
            const schedulerTimeoutMs = (() => {
                if (isValidNumber(timeoutMs)) {
                    setIntervalMs(timeoutMs);
                    return timeoutMs;
                }

                throw new Error(`timeoutMs: positive number expected! Received ${timeoutMs}`);
            })();

            setTimeout(() => {
                setStatus(TASK_STATUS.TIMEOUT_RUNNING);
                setRandomizedMsUntilTimeoutExpiry(getMsUntilTimeoutExpiry(schedulerTimeoutMs));
                setContextId(getRandomId());
            }, 0);
        } catch (error) {
            logger.error('useScheduledTaskMultiTabHandler', 'startScheduler', error);
        }
    }

    function stopScheduler() {
        setStatus(TASK_STATUS.IDLE);
        setRandomizedMsUntilTimeoutExpiry(undefined);
        setContextId(undefined);
    }

    function restartScheduler(timeoutMs?: unknown) {
        stopScheduler();

        try {
            const schedulerTimeoutMs = (() => {
                if (timeoutMs === null || timeoutMs === undefined) {
                    if (isValidNumber(intervalMs)) {
                        return intervalMs;
                    }

                    throw new Error(`Can't restart without specified timeout if scheduler hasn't been started before!`);
                }

                if (isValidNumber(timeoutMs)) {
                    setIntervalMs(timeoutMs);
                    return timeoutMs;
                }

                throw new Error(`timeoutMs: positive number expected! Received ${timeoutMs}`);
            })();

            setTimeout(() => {
                setStatus(TASK_STATUS.TIMEOUT_RUNNING);
                setRandomizedMsUntilTimeoutExpiry(getMsUntilTimeoutExpiry(schedulerTimeoutMs));
                setContextId(getRandomId());
            }, 0);
        } catch (error) {
            logger.error('useScheduledTaskMultiTabHandler', 'startScheduler', error);
        }
    }

    return { contextId, status, startScheduler, stopScheduler, restartScheduler };
}

function getScheduledTaskNames(scheduledTaskName: string) {
    return {
        TASK_PENDING_KEY: `${scheduledTaskName}_PENDING`,
        TASK_START_TIMESTAMP_KEY: `${scheduledTaskName}_START_TIMESTAMP`,
    };
}

export function setScheduledTaskStatusExecuting(scheduledTaskName: string) {
    const { TASK_PENDING_KEY, TASK_START_TIMESTAMP_KEY } = getScheduledTaskNames(scheduledTaskName);

    storageSet(TASK_PENDING_KEY, true);
    storageSet(TASK_START_TIMESTAMP_KEY, moment().toISOString());
}

export function setScheduledTaskStatusIdle(scheduledTaskName: string) {
    const { TASK_PENDING_KEY, TASK_START_TIMESTAMP_KEY } = getScheduledTaskNames(scheduledTaskName);

    storageSet(TASK_PENDING_KEY, false);
    storageSet(TASK_START_TIMESTAMP_KEY, null);
}

export function getIsScheduledTaskPending(scheduledTaskName: string) {
    return storageGet(getScheduledTaskNames(scheduledTaskName).TASK_PENDING_KEY);
}

export function purgeExpiredScheduledTaskStorageKeys(scheduledTaskName: string, taskTimeoutInSeconds = 120) {
    const { TASK_PENDING_KEY, TASK_START_TIMESTAMP_KEY } = getScheduledTaskNames(scheduledTaskName);

    const currentTime = moment();
    const isTaskPending = storageGet(TASK_PENDING_KEY);
    const taskStartTimestamp = storageGet<string>(TASK_START_TIMESTAMP_KEY);

    if (
        isTaskPending &&
        moment.duration(currentTime.diff(moment(taskStartTimestamp))).asSeconds() > taskTimeoutInSeconds
    ) {
        setScheduledTaskStatusIdle(scheduledTaskName);

        console.info(
            `Task "${scheduledTaskName}" has been running for more than ${taskTimeoutInSeconds} seconds. Allowing running the task from all tabs again.`,
        );
    }
}
