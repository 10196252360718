import React from 'react';
import Wrapper from './styles';
import UiBox from '../ui/box/UiBox';
import { translate } from '../../services/translate';
import { useTimeout } from '../../services/hooks';
import moment from 'moment';
import { getDocumentHost } from '../../services/util';
import { useSportsMaintenanceSocketUpdates } from '../../services/sports/maintenance';
import Logo from '../logo/Logo';
import { ClientName } from '../../services/utils/types';
import { getClient } from '../../services/environment';

interface Props {
    endDate?: Date;
}

export default function Maintenance({ endDate }: Props) {
    useTimeout(() => window.location.reload(), 300000, []);

    const client = getClient();
    const header = {
        [ClientName.COOLBET]: (
            <h1>
                <span className="green-text">Stay</span> <span className="blue-text">cool</span>.
            </h1>
        ),
    }[client] || (
        <div className={client}>
            <Logo size={50} />
        </div>
    );

    useSportsMaintenanceSocketUpdates();

    function getPlannedMaintenanceMessage() {
        return translate('planned maintenance {{ endDate }}', 'ui.maintenance-react', {
            endDate: moment(endDate).format('HH:mm'),
        });
    }

    return (
        <Wrapper>
            <div className="modal-container">
                <UiBox>
                    {header}

                    {!endDate && <h3>{translate('ui.maintenance-react.title')}</h3>}
                    {endDate && <h3>{getPlannedMaintenanceMessage()}</h3>}

                    <div className="gearbox">
                        <div className="overlay" />
                        <div className="gear one">
                            <div className="gear-inner">
                                <div className="bar" />
                                <div className="bar" />
                                <div className="bar" />
                            </div>
                        </div>
                        <div className="gear two">
                            <div className="gear-inner">
                                <div className="bar" />
                                <div className="bar" />
                                <div className="bar" />
                            </div>
                        </div>
                        <div className="gear three">
                            <div className="gear-inner">
                                <div className="bar" />
                                <div className="bar" />
                                <div className="bar" />
                            </div>
                        </div>
                        <div className="gear four large">
                            <div className="gear-inner">
                                <div className="bar" />
                                <div className="bar" />
                                <div className="bar" />
                                <div className="bar" />
                                <div className="bar" />
                                <div className="bar" />
                            </div>
                        </div>
                    </div>

                    <p>
                        {translate('contact information', 'ui.maintenance-react.desc', {
                            host: getDocumentHost(),
                        })}
                    </p>
                </UiBox>
            </div>
        </Wrapper>
    );
}
