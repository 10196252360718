import React from 'react';
import moment from 'moment';

export const withShowUntilDate = (Component) => (props) => {
    const dateFormat = 'DDMMYY';

    const showUntilDate = props['show-until-date'] && moment(props['show-until-date'], dateFormat);
    if (showUntilDate && !showUntilDate.add(1, 'day').isAfter()) {
        return null;
    }
    return <Component {...props}>{props.children}</Component>;
};
