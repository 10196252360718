import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import React, { useState } from 'react';
import Wrapper from './styles';
import UiBox from '../../../ui/box/UiBox';
import UiGroup from '../../../ui/group/UiGroup';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import Snippet from '../../../snippet/Snippet';
import AccountVerificationSourceOfFunds from '../../../account/verification/source-of-funds/AccountVerificationSourceOfFunds';
import { toast } from 'react-toastify';
import { useStore } from '../../../../hooks/useStore';

export default function AuthProfileValidatorSourceOfWealthFiles() {
    const [isInfoExpanded, setIsInfoExpanded] = useState(false);
    const [kycSettings] = useStore(stores.kyc.settings);

    if (!['declined', 'requested'].includes(kycSettings.sourceOfIncomeStatus)) {
        return null;
    }

    return (
        <Wrapper>
            <UiBox>
                <UiGroup vertical>
                    {isInfoExpanded ? (
                        <div>
                            <UiCloseButton
                                onClick={() => setIsInfoExpanded(!isInfoExpanded)}
                                className="close-button"
                            />
                            <Snippet snippetKey={'account.kyc.source-of-wealth-file'} />
                        </div>
                    ) : (
                        <>
                            <div className="truncated-info-text">
                                <Snippet snippetKey={'account.kyc.source-of-wealth-file'} />
                                <p>
                                    <button onClick={() => setIsInfoExpanded(!isInfoExpanded)}>
                                        {translate('Read more', 'ui.account')}
                                    </button>
                                </p>
                            </div>

                            <AccountVerificationSourceOfFunds
                                isCompactView
                                onDocumentUploadSuccess={() =>
                                    toast.success(
                                        translate(
                                            "We have received your document copies. We'll email you once they have been processed.",
                                            'ui.account',
                                        ),
                                    )
                                }
                            />
                        </>
                    )}
                </UiGroup>
            </UiBox>
        </Wrapper>
    );
}
