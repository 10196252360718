import React from 'react';
import { useProofOfIdVerificationContext } from '../../../../../../contexts/proof-of-id-verification/ProofOfIdVerificationContext';
import { translate } from '../../../../../../services/translate';
import UiButton from '../../../../../ui/button/UiButton';
import AccountVerificationProofOfIdHeader from '../../header/AccountVerificationProofOfIdHeader';

export default function AccountVerificationProofOfIdSteps0() {
    const { setActiveStep, documentUpload } = useProofOfIdVerificationContext();

    return (
        <>
            <AccountVerificationProofOfIdHeader>{documentUpload.statusAlert}</AccountVerificationProofOfIdHeader>
            {documentUpload.isEnabled && (
                <UiButton onClick={() => setActiveStep(1)} block>
                    {translate('Upload documents', 'ui.account.kyc')}
                </UiButton>
            )}
        </>
    );
}
