import styled from 'styled-components';

export default styled.div`
    .selection-row {
        display: flex;
        align-items: center;
        padding: var(--spacing-8) 0;

        .selection-button {
            cursor: pointer;
            min-width: 40px;
            min-height: 40px;
            border-radius: 50%;
            color: var(--odds-text-active);
            border: var(--odds-border-active);
            background-color: var(--odds-bg-active);
            font-weight: var(--font-weight-heavy);
            margin-right: var(--spacing-16);
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: var(--ui-shadow);
        }

        .market-info {
            display: flex;
            flex-direction: column;

            .market-type-name,
            .match-name {
                font-size: var(--font-14);
                color: var(--font-color-subtle);
            }

            .outcome-name {
                font-size: 0.875rem;
                color: var(--font-color-primary);
                font-weight: var(--font-weight-heavy);
                margin: var(--spacing-4) 0;
            }
            .market-type-name {
                margin-bottom: var(--spacing-8);
            }
        }

        .disabled {
            opacity: 0.7;
            .value,
            .market-type-name,
            .outcome-name,
            .match-name {
                opacity: 0.5;
            }
        }
    }
    .remove-icon {
        color: var(--font-color-subtle);
        margin-left: auto;
    }
`;
