import styled, { keyframes } from 'styled-components';

const counterClockwise = keyframes`
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(-360deg);
    }
`;

const clockwise = keyframes`
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
`;

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .modal-container {
        width: 264px;

        img {
            background: var(--color-body-bg);
            border-radius: var(--ui-radius);
            padding: var(--spacing-8);
        }

        .island {
            img {
                background: var(--sport-menu-bg);
                height: 100px;
            }
        }
    }

    h1 {
        margin: 0.5rem 0;
    }

    .green-text {
        color: ${({ theme }) => theme.colors.secondary};
    }

    .blue-text {
        color: #00bfff;
    }

    .gearbox {
        background: var(--color-body-bg);
        height: 150px;
        width: 200px;
        position: relative;
        border: none;
        overflow: hidden;
        border-radius: 6px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
        margin-bottom: 1rem;

        .overlay {
            border-radius: 6px;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            box-shadow: inset 0 0 20px black;
            transition: background 0.2s;
        }

        .gear {
            position: absolute;
            height: 60px;
            width: 60px;
            box-shadow: 0 -1px 0 0 #888888, 0 1px 0 0 black;
            border-radius: 30px;

            &:after {
                content: '';
                position: absolute;
                height: 36px;
                width: 36px;
                border-radius: 36px;
                background: #111;
                top: 50%;
                left: 50%;
                margin-left: -18px;
                margin-top: -18px;
                z-index: 3;
                box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1),
                    inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
            }

            &.large {
                height: 120px;
                width: 120px;
                border-radius: 60px;

                &:after {
                    height: 96px;
                    width: 96px;
                    border-radius: 48px;
                    margin-left: -48px;
                    margin-top: -48px;
                }

                .gear-inner {
                    border-radius: 60px;

                    .bar {
                        margin-left: -68px;
                        width: 136px;
                    }
                }
            }

            &.one {
                top: 12px;
                left: 10px;

                .gear-inner {
                    animation: ${counterClockwise} 3s infinite linear;
                }
            }

            &.two {
                top: 61px;
                left: 60px;

                .gear-inner {
                    animation: ${clockwise} 3s infinite linear;
                }
            }

            &.three {
                top: 110px;
                left: 10px;

                .gear-inner {
                    animation: ${counterClockwise} 3s infinite linear;
                }
            }

            &.four {
                top: 13px;
                left: 128px;

                .gear-inner {
                    animation: ${counterClockwise} 6s infinite linear;
                }
            }
        }

        .gear-inner {
            position: relative;
            height: 100%;
            width: 100%;
            background: #555;
            animation-iteration-count: infinite;
            border-radius: 30px;
            border: 1px solid rgba(255, 255, 255, 0.1);

            .bar {
                background: #555;
                height: 16px;
                width: 76px;
                position: absolute;
                left: 50%;
                margin-left: -38px;
                top: 50%;
                margin-top: -8px;
                border-radius: 2px;
                border-left: 1px solid rgba(255, 255, 255, 0.1);
                border-right: 1px solid rgba(255, 255, 255, 0.1);
            }

            .bar:nth-child(2) {
                transform: rotate(60deg);
            }

            .bar:nth-child(3) {
                transform: rotate(120deg);
            }

            .bar:nth-child(4) {
                transform: rotate(90deg);
            }

            .bar:nth-child(5) {
                transform: rotate(30deg);
            }

            .bar:nth-child(6) {
                transform: rotate(150deg);
            }
        }
    }
`;
