import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isLayoutB?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;
    border: var(--surface-level-2-border);
    border-radius: var(--ui-radius);
    background: var(--surface-level-2-bg);
    padding: 0.8rem;
    .bet-wrapper {
        display: flex;
        align-items: flex-start;
    }
    .remove-selection {
        cursor: pointer;
        color: var(--font-color-subtle);
        margin-left: var(--spacing-12);
        align-items: flex-start;
    }

    .outcome-info {
        display: flex;
        cursor: pointer;
        .outcome-selection {
            flex: 1;
            cursor: ${({ $isLayoutB }) => ($isLayoutB ? 'default' : '')};
        }
        .odds-button {
            cursor: ${({ $isLayoutB }) => ($isLayoutB ? 'default' : '')};
        }
    }
    .outcome-selection-name {
        display: flex;
        align-items: center;
        font-weight: var(--match-name-weight);
        color: var(--color-odds);
        font-size: 0.875rem;
        ${({ theme }) => theme.optimizeFontRendering};

        margin-bottom: var(--spacing-4);
        @media ${mediaQuery.isPhone} {
            font-size: var(--match-name-size-mobile);
        }
        &.bet-builder {
            color: var(--match-primary-color);
            margin-right: var(--spacing-8);
        }
    }
    .match-name {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 0.75rem;
        margin-top: var(--spacing-8);
        color: var(--match-primary-color);
        border-top: 1px dashed var(--surface-level-3-line-color);
        padding-top: var(--spacing-8);
        a {
            margin-left: auto;
        }
    }
    .outcome-odds {
        min-width: 56px;
        margin-left: auto;
    }
    .market-name {
        font-size: 0.75rem;
        color: var(--match-secondary-color);
        line-height: 130%;

        em {
            font-style: normal;
            color: var(--color-odds);
        }
    }
    .market-error {
        margin-top: 0.4rem;
        margin-bottom: 0;
    }
    .fake-market {
        min-height: 100px;
        position: relative;
        .loader {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .betbuilder-markets-list {
        margin-top: var(--spacing-16);
        .betbuilder-market {
            &:last-child {
                border-bottom: none;
                &:after {
                    display: none;
                }
            }
        }
    }
    .betbuilder-market {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: var(--spacing-8) 0;
        margin-left: var(--spacing-16);
        border-bottom: 1px dashed var(--surface-level-3-line-color);
        &:before {
            position: absolute;
            top: 50%;
            left: -19px;
            transform: translateY(-50%);
            content: ' ';
            border: 1px solid var(--surface-level-3-line-color);
            background: var(--surface-level-3-bg);
            border-radius: 7px;
            width: 7px;
            height: 7px;
        }
        &:after {
            position: absolute;
            top: 56%;
            left: -16px;
            height: 100%;
            width: 1px;
            background-color: var(--surface-level-3-line-color);
            content: ' ';
        }
        .outcome {
            color: var(--color-odds);
        }
    }
    .teaser-outcome {
        align-items: center;
        display: flex;
        width: 100%;
        font-size: 0.7rem;
        .teaser-outcome-name {
            color: var(--betslip-label-odds-color);
        }

        .teaser-outcome-line {
            margin-left: auto;
            min-width: 100px;
            text-align: right;
            color: var(--betslip-label-odds-color);
            font-size: 0.875rem;
            font-weight: var(--match-name-weight);
            ${({ theme }) => theme.optimizeFontRendering};
            .teaser-outcome-original-line {
                color: var(--match-primary-color);
            }
            .teaser-outcome-arrow {
                color: var(--match-secondary-color);
                font-size: 1.2rem;
                line-height: 0.8rem;
            }
            .teaser-outcome-teased-line {
                color: var(--odds-text-color);
            }
        }
    }
`;
