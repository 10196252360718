import React, { useState } from 'react';
import { useInterval } from '../../../services/hooks';

interface Props {
    intervalMs?: number;
    totalPoints?: number;
}

export default function UiPointsLoader({ intervalMs = 250, totalPoints = 6 }: Props) {
    const [currentPoints, setCurrentPoints] = useState(0);

    useInterval(() => {
        setCurrentPoints((currentPoints) => (currentPoints + 1) % totalPoints);
    }, intervalMs);

    return <>{Array.from({ length: currentPoints }, () => '.')}</>;
}
