import React from 'react';
import Wrapper from './styles';
import CoolbetFooterLinks from './links/CoolbetFooterLinks';
import CoolbetFooterLicense from './license/CoolbetFooterLicense';
import CoolbetFooterLegalLogos from './legal-logos/CoolbetFooterLegalLogos';
import CoolbetFooterPaymentPartners from './payment-partners/CoolbetFooterPaymentPartners';
import CoolbetFooterNavigation from './navigation/CoolbetFooterNavigation';
import { isB2B } from '../../../services/environment';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function CoolbetFooter() {
    const [{ isPhone }] = useStore(media);

    return (
        <Wrapper className="footer">
            {!isPhone && <CoolbetFooterNavigation />}
            {!isPhone && <CoolbetFooterPaymentPartners />}
            <CoolbetFooterLegalLogos />
            <CoolbetFooterLicense />
            {!isB2B() && <CoolbetFooterLinks />}
        </Wrapper>
    );
}
