import React from 'react';
import UiGroup from '../../../ui/group/UiGroup';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { translate } from '../../../../services/translate';
import { validators } from '../../../../services/validators';

interface Props {
    form: any;
}

export default function RegistrationFieldsAlias({ form }: Props) {
    return (
        <UiGroup expand>
            <UiFormInput
                {...form}
                placeholder={translate('User name / min 6 and max 18 characters.', 'ui.account')}
                validator={validators.alias}
                label={translate('User name (name that will be used in leaderboards)', 'ui.registration')}
                minLength={6}
                maxLength={18}
                data-test="alias"
            />
        </UiGroup>
    );
}
