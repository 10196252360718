import * as classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { doCashout } from '../../../../microservices/bets';
import { useTimeout } from '../../../../services/hooks';
import { CASHOUT_AMOUNT_CHANGE_ERROR, errorCodes } from '../../../../services/sports/cashout';
import { translate } from '../../../../services/translate';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { stores } from '../../../../stores';
import UiAlert from '../../../ui/alert/UiAlert';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import Wrapper from './styles';
import UiButton from '../../../ui/button/UiButton';
import { Ticket } from '../../../../services/sports/types';
import Svg from '../../../svg/Svg';
import ReactTooltip from 'react-tooltip';
import { dispatchEvent, EventType } from '../../../../services/utils/b2b';
import { filterStyleProps } from '../../../../styles/utils';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    ticket: Ticket;
    className?: string;
}

export default function SportCashoutButton({ ticket, ...rest }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [error, setError] = useState<{ code: number }>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isAcceptingAnyOddsChanges, setIsAcceptingAnyOddsChanges] = useState(false);
    const [isAcceptingLimitedOddsChanges, setIsAcceptingLimitedOddsChanges] = useState(false);
    const [cashoutsDoneTicketIds, setCashoutsDoneTicketIds] = useStore(stores.sports.cashoutsDoneTicketIds);
    const [changeDirection, setChangeDirection] = useState<string>();
    const [previousCashoutAmount, setPreviousCashoutAmount] = useState<number>();
    const [localCashoutAmount, setLocalCashoutAmount] = useState();
    const [actualCashoutAmount, setActualCashoutAmount] = useState<number>(0);
    const cashoutAmount = localCashoutAmount || ticket.cashoutAmount;

    useEffect(() => {
        setPreviousCashoutAmount(ticket.cashoutAmount);
        if (!previousCashoutAmount || previousCashoutAmount === ticket.cashoutAmount) {
            return;
        }
        setChangeDirection(previousCashoutAmount > ticket.cashoutAmount ? 'down' : 'up');
        setError(undefined);
    }, [ticket.cashoutAmount]);

    useTimeout(
        () => {
            setChangeDirection(undefined);
        },
        8000,
        [changeDirection],
    );

    async function cashOut() {
        setIsLoading(true);
        setError(undefined);
        try {
            const [{ amount_uc }] = await doCashout(
                ticket.id,
                cashoutAmount,
                isAcceptingAnyOddsChanges,
                isAcceptingLimitedOddsChanges,
            );
            setIsSuccess(true);
            dispatchEvent(EventType.Cashout);
            setCashoutsDoneTicketIds((state) => state.concat(ticket.id));
            setActualCashoutAmount(amount_uc);
        } catch (error: any) {
            if (error.code === CASHOUT_AMOUNT_CHANGE_ERROR) {
                setLocalCashoutAmount(error.actualAmount);
            }
            setError(error);
        }

        setIsLoading(false);
        setIsConfirming(false);
    }

    return (
        <Wrapper {...filterStyleProps({ ...rest })}>
            {isSuccess && (
                <UiAlert success className="tooltip-box">
                    {translate('CASHOUT_SUCCESS', 'ui.cashout.tab')} {formattedAmountWithCurrency(actualCashoutAmount)}
                    {ticket.cashoutAmount !== actualCashoutAmount && (
                        <>
                            <Svg icon="info" data-tip data-for="cashOutAmountInfo" />
                            <ReactTooltip
                                id="cashOutAmountInfo"
                                type="light"
                                className="tooltip"
                                place="bottom"
                                effect="solid"
                            >
                                <span>{translate('CASHOUT_AMOUNT_WRONG', 'ui.cashout.button')}</span>
                            </ReactTooltip>
                        </>
                    )}
                </UiAlert>
            )}
            {error && <UiAlert>{translate(errorCodes[error.code] || 'Technical Error', 'ui.cashout.button')}</UiAlert>}
            {!error && (
                <>
                    {isConfirming ? (
                        <>
                            <div className="cashout-confirm">
                                {!isLoading && (
                                    <UiButton onClick={() => setIsConfirming(false)} isLoading={isLoading} size="small">
                                        {translate('DECLINE', 'ui.sportsbook')}
                                    </UiButton>
                                )}
                                <UiButton
                                    onClick={cashOut}
                                    isLoading={isLoading}
                                    size="small"
                                    className="cashout-button"
                                >
                                    {translate('CONFIRM', 'ui.cashout')}{' '}
                                    {formattedAmountWithCurrency(ticket.cashoutAmount)}
                                </UiButton>
                            </div>

                            {ticket.is_match_live && !isLoading && (
                                <UiFormInput
                                    checkbox={true}
                                    onChange={() => setIsAcceptingAnyOddsChanges(!isAcceptingAnyOddsChanges)}
                                    label={translate('Accept any Odds changes', 'ui.sportsbook')}
                                    value={isAcceptingAnyOddsChanges}
                                />
                            )}
                            {ticket.is_match_live && !isLoading && (
                                <UiFormInput
                                    checkbox={true}
                                    onChange={() => setIsAcceptingLimitedOddsChanges(!isAcceptingLimitedOddsChanges)}
                                    label={translate(
                                        'Accept Odds changes impacting Cashout up to 10%',
                                        'ui.sportsbook',
                                    )}
                                    value={isAcceptingLimitedOddsChanges}
                                />
                            )}
                        </>
                    ) : (
                        !isSuccess &&
                        !cashoutsDoneTicketIds.includes(ticket.id) && (
                            <UiButton
                                size="small"
                                block
                                className={classnames(['cashout-button', changeDirection || ''])}
                                onClick={() => setIsConfirming(true)}
                            >
                                {translate('Cashout → ', 'ui.sportsbook')}{' '}
                                <span> {formattedAmountWithCurrency(cashoutAmount)}</span>
                            </UiButton>
                        )
                    )}
                </>
            )}
        </Wrapper>
    );
}
