import styled from 'styled-components';

export default styled.div`
    min-height: calc(100vh - ${({ theme }) => theme.header.height});
    display: flex;
    align-items: center;
    justify-content: center;
    .login-box {
        margin-top: 1rem;
    }
    .please-login {
        max-width: 300px;
    }
`;
