import styled from 'styled-components';

export default styled.div`
    --game-thumb-size: 120px;
    .products-section {
        display: flex;
        flex-direction: column;
        .section-title {
            margin: var(--spacing-12) 0;
        }
        .quick-links {
            display: flex;
            gap: var(--spacing-8);
            overflow: auto;
            button {
                height: 5rem;
            }
        }
    }
    .games-list {
        display: flex;
        gap: var(--spacing-8);
        overflow: auto;
        margin-top: var(--spacing-8);
    }

    .promotions-box {
        display: flex;
        gap: var(--spacing-8);
        overflow: auto;
        > * {
            min-width: 130px;
        }
    }
    .support-section {
        display: flex;
        align-items: center;
        padding: var(--spacing-4) var(--spacing-8);
        gap: var(--spacing-8);
    }
    .support-title {
        margin-bottom: var(--spacing-8);
    }
`;
