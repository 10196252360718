import io, { Socket } from 'socket.io-client';
import { fromEvent } from 'rxjs';
import { httpGet } from '../services/api';
import { isLocalDevelopment } from '../services/environment';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';

const getUrl = (url) => `http://localhost:3012/${url}`;

const socket =
    isLocalDevelopment() &&
    io('localhost:3012', {
        transports: ['websocket'],
    });

export const onMessage = fromEvent(socket as FromEventTarget<Socket>, 'message');

export async function getDevelopment() {
    return httpGet<any>(getUrl('development'));
}
