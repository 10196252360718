import { useEffect } from 'react';
import { stores } from '../../../stores';
import { useClock } from '../../../services/hooks';

export default function SystemClock() {
    const [secondsPassedSinceApplicationStart] = useClock();

    useEffect(() => {
        stores.secondsPassedSinceApplicationStart.set(secondsPassedSinceApplicationStart);
    }, [secondsPassedSinceApplicationStart]);

    return null;
}
