import React from 'react';
import Wrapper from './styles';

interface Props {
    className?: string;
}

export default function UiDotsLoader({ className }: Props) {
    return (
        <Wrapper className={className}>
            <div className="bounce1" data-test="loading" />
            <div className="bounce2" />
        </Wrapper>
    );
}
