import styled from 'styled-components';

export default styled.div`
    .status {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        & > span {
            text-align: center;
        }
    }

    .connecting {
        color: ${({ theme }) => theme.colors.warning};
    }

    .geoverifying {
        color: ${({ theme }) => theme.colors.info};
    }

    .geoverified {
        color: ${({ theme }) => theme.colors.success};
    }

    .title {
        color: var(--font-color-primary);
        font-size: 1.4rem;
        font-weight: var(--font-weight-bold);
        margin-bottom: 1rem;
    }

    .loader {
        margin-top: 1rem;
    }
`;
