import React from 'react';
import { useProofOfAddressVerificationContext } from '../../../../../../contexts/proof-of-address-verification/ProofOfAddressVerificationContext';
import { translate } from '../../../../../../services/translate';
import AccountVerificationDocumentSelectionOneSided from '../../../document/selection/one-sided/AccountVerificationDocumentSelectionOneSided';
import AccountVerificationProofOfAddressFooter from '../../footer/AccountVerificationProofOfAddressFooter';
import AccountVerificationProofOfAddressHeader from '../../header/AccountVerificationProofOfAddressHeader';

export default function AccountVerificationProofOfAddressSteps2() {
    const { documentUpload } = useProofOfAddressVerificationContext();

    return (
        <>
            <AccountVerificationProofOfAddressHeader>
                <span>{translate('Choose documents to upload', 'ui.account.kyc')}</span>
            </AccountVerificationProofOfAddressHeader>
            <AccountVerificationDocumentSelectionOneSided documentUpload={documentUpload} />
            <AccountVerificationProofOfAddressFooter />
        </>
    );
}
