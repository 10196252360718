import React from 'react';

import MainLayout from '../../layouts/main/MainLayout';

export const withLayout =
    (Component: (props: any) => JSX.Element, Layout = MainLayout) =>
    (props) => {
        return (
            <Layout>
                <Component {...props} />
            </Layout>
        );
    };
