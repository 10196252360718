import React from 'react';
import { translate } from '../../../../services/translate';
import Snippet from '../../../snippet/Snippet';
import UiButton from '../../../ui/button/UiButton';

interface Props {
    isButtonBlock?: boolean;
    onDownload: () => void;
}

export default function AccountDataWithToken({ isButtonBlock = false, onDownload }: Props) {
    return (
        <>
            <Snippet snippetKey="gdpr.download.header-verified" />

            <UiButton color="primary" block={isButtonBlock} onClick={() => onDownload()}>
                {translate('Download', 'ui.gdpr')}
            </UiButton>
        </>
    );
}
