import styled from 'styled-components';

export default styled.div`
    .match-result {
        display: flex;
        align-items: center;
        .score {
            font-size: 0.75rem;
            color: var(--match-score-text-color);
            line-height: 1;

            background-color: var(--match-score-bg);
            border: var(--match-score-border);
            border-radius: var(--match-score-radius);

            padding: 0.2rem 0.25rem;
            margin: 0 0.1rem;
        }
    }
`;
