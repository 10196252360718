import React from 'react';
import Wrapper from './styles';

interface Props {
    depositUrl: string;
}

export default function PaymentDepositPayAndPlayProvidersTrustly({ depositUrl }: Props) {
    return (
        <Wrapper>
            <iframe src={depositUrl} className="trustly-iframe" title="trustly-iframe" />
        </Wrapper>
    );
}
