import React from 'react';

import { TICKET_TYPE } from '../../../../../services/bet-history';
import { Ticket, UniqueSelection } from '../../../../../services/sports/types';
import SportBadge from '../../../badge/SportBadge';
import SportBadgeTicketPlatform from '../../../badge/ticket-platform/SportBadgeTicketPlatform';
import SportTicketMatchScore from '../../match-score/SportTicketMatchScore';
import SportBadgeTicketStatus from '../../../badge/ticket-status/SportBadgeTicketStatus';

interface Props {
    uniqueSelection: UniqueSelection;
    ticketDetails: Ticket;
    isCompactViewEnabled?: boolean;
    isPublic?: boolean;
}

export default function SportTicketDetailsMatchContainer({
    uniqueSelection,
    ticketDetails,
    isPublic = false,
    isCompactViewEnabled,
}: Props) {
    return (
        <div className="match-container">
            <div className="match-name">{uniqueSelection.match_name}</div>

            {uniqueSelection.product === TICKET_TYPE.LIVE && !isCompactViewEnabled && (
                <SportBadge type={TICKET_TYPE.LIVE} />
            )}

            {uniqueSelection.score_home !== null && !isCompactViewEnabled && (
                <SportTicketMatchScore matchInfo={uniqueSelection} />
            )}
            {!isCompactViewEnabled && !isPublic && (
                <>
                    <SportBadgeTicketPlatform ticket={ticketDetails.cashout_status ? ticketDetails : uniqueSelection} />
                    <div className="ticket-status">
                        <SportBadgeTicketStatus
                            ticket={ticketDetails.cashout_status ? ticketDetails : uniqueSelection}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
