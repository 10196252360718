import React from 'react';
import Wrapper from './styles';

import { filterStyleProps } from '../../styles/utils';
import { isRetail } from '../../services/environment';

interface Props {
    icon: string;
    alt?: string;
    medium?: boolean;
    small?: boolean;
    tiny?: boolean;
    large?: boolean;
    className?: string;
}

export default function Icon({ icon, alt, ...rest }: Props) {
    const iconPath = `/assets/images/sport-icons/${icon}.png`;
    return <Wrapper {...filterStyleProps({ ...rest })} src={iconPath} alt={isRetail() ? undefined : alt || ''} />;
}
