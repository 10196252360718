import styled from 'styled-components';

export default styled.div`
    .campaign-bet {
        display: flex;
        align-items: center;
        border: 2px solid #005a9c;
        background: linear-gradient(90deg, rgba(0, 90, 156, 1) 0%, rgba(0, 44, 76, 1) 100%);
        border-radius: var(--ui-radius);
        padding: var(--spacing-16) var(--spacing-8);
        margin-top: var(--spacing-4);

        &.selected {
            border: 2px solid #009eff;
        }
        &:hover {
            border-color: #009eff;
        }
        .campaign-icon {
            text-align: center;
            min-width: 64px;
            flex-direction: column;
            align-items: center;
            gap: 0.625rem;
            flex-shrink: 0;
            display: flex;
        }
        .campaign-quantity {
            display: flex;
            width: 1.875rem;
            height: 1.875rem;
            padding: 0.46875rem 0.76219rem 0.5rem 0.75rem;
            justify-content: center;
            align-items: center;
            border-radius: 6.25rem;
            background: #fff;

            color: var(--font-color-primary);
            text-align: center;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 0.71875rem;
            letter-spacing: 0.01875rem;
            text-transform: uppercase;
        }
        .campaing-description {
            font-size: 0.75rem;
            color: #fff;
            text-transform: uppercase;

            padding: 0 0.6rem;
            flex: 1;
        }
        .description-text {
            font-weight: var(--font-weight-heavy);
            ${({ theme }) => theme.optimizeFontRendering};
        }
        .description-remaining {
            font-size: 0.625rem;
            margin-top: var(--spacing-8);
            font-style: normal;
            font-weight: 500;
            line-height: 0.625rem;
            text-transform: uppercase;
        }
        .description-more {
            font-size: 0.625rem;
            margin-top: var(--spacing-8);
            text-decoration: underline;
            cursor: pointer;
        }
    }
`;
