import React from 'react';
import { useProofOfPaymentVerificationContext } from '../../../../../../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import AccountVerificationDocumentUpload from '../../../document/upload/AccountVerificationDocumentUpload';

export default function AccountVerificationProofOfPaymentSteps4() {
    const { documentUpload, isTwoSidedDocumentFlow, reset, selectedDocument, selectedVerificationOption } =
        useProofOfPaymentVerificationContext();
    if (!selectedDocument) {
        return null;
    }
    const extraData = {
        [selectedDocument.documentType === 'DEPOSIT' ? 'transactionId' : 'paymentMethodId']: selectedDocument.id,
        isUploadLimitIgnored: 'true',
        ...(selectedVerificationOption && { selectedOption: selectedVerificationOption }),
    };
    if (isTwoSidedDocumentFlow) {
        documentUpload.documents.forEach(({ name, side }) => (extraData[name] = JSON.stringify({ side })));
    }
    return (
        <AccountVerificationDocumentUpload
            documentUpload={documentUpload}
            extraData={extraData}
            onResetButtonClick={reset}
        />
    );
}
